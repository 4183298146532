import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ReactComponent as SuccessIcon } from "assets/icons/reserve/success.svg";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import GuestModal, {
  MODAL_POSITIONS,
} from "components/guest/guest-modal/GuestModal";

import "./ReservationSuccessModal.scss";

const ReservationSuccessModal = ({ openSlide, onClose }) => {
  const { t } = useTranslation();

  const ReservationSuccessModalHeader = (
    <div className="ReservationSuccessModalTitle">
      <SuccessIcon />
    </div>
  );

  const ReservationSuccessModalBody = (
    <div className="ReservationSuccessModalBodyTables">
      <h4 className="Medium">{t("reservation.sentSuccessfully")}</h4>
      <h6>{t("reservation.successMessage")}</h6>
    </div>
  );

  const ReservationSuccessModalFooter = (
    <div className="ReservationSuccessModalFooter">
      <PrimaryButton
        onClick={onClose}
        text={t("modal.close")}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_B}
      />
    </div>
  );
  return (
    <GuestModal
      containerClassname={"ReservationSuccessContainerClassname"}
      header={ReservationSuccessModalHeader}
      body={ReservationSuccessModalBody}
      footer={ReservationSuccessModalFooter}
      openSlide={openSlide}
      modalPosition={MODAL_POSITIONS.BOTTOM}
    />
  );
};
ReservationSuccessModal.propTypes = {
  onClose: PropTypes.func,
  openSlide: PropTypes.bool,
};
export default ReservationSuccessModal;
