export const HEADERS = {
  keys: {
    auth: "Authorization",
  },
  values: {
    authType: "Bearer",
  },
};

export const ENDPOINTS = {
  hello: "/hello",
  sales: "/sales",
  menu: "/menu",
  business: "/business",
  orders: "/orders",
  statistics: "/statistics",
  history: "/history",
  deleteHistory: "/delete-history",
  items: "/items",
  feedbacks: "/feedbacks",
  summary: "/summary",
};

export const API_PATH_PARAMS = {
  business: "business",
  settings: "settings",
  metadata: "metadata",
  admin: "admin",
  general: "general",
  menus: "menus",
  menuCategories: "menu-categories",
  inventoryCategories: "inventory-categories",
  inventory: "inventory",
  menuItems: "menu-items",
  orders: "orders",
  orderGuest: "order-guest",
  transferOrders: "transfer-orders",
  table: "table",
  assignee: "assignee",
  action: "action",
  qr: "qr",
  reservations: "reservations",
  pending: "pending",
  tables: "tables",
  map: "map",
  tags: "tags",
  auth: "auth",
  instagram: "instagram",
  facebook: "facebook",
  login: "login",
  verifyUser: "verify-user",
  signUp: "signup",
  resendCode: "resend-code",
  users: "users",
  guests: "guests",
  anonymous: "anonymous",
  zones: "zones",
  info: "info",
  infra: "infra",
  ai: "ai",
  translate: "translate",
  otp: "otp",
  verify: "verify",
  telegram: "telegram",
  sendOtp: "send-otp",
  verifyOtp: "verify-otp",
  resetPassword: "reset-password",
  forgotPassword: "forgot-password",
  statistics: "statistics",
  active: "active",
  payments: {
    root: "payments",
  },
  superSearch: "super-search",
};

export const API_PATH_HELPERS = {
  size: "size",
  type: "type",
  qrId: "qrId",
  method: "method",
  sendPinCode: "sendPinCode",
};

export const QUERY_PARAMS = {
  startDate: "startDate",
  endDate: "endDate",
  pagination: {
    size: "pageSize",
    number: "pageNumber",
  },
  sort: {
    dir: "sortDir",
    by: "sortBy",
  },
  zone: "zone",
  staff: "staff",
  zoneId: "zoneId",
  staffId: "staffId",
  orderId: "orderId",
  itemName: "itemName",
  categoryId: "categoryId",
  what: "what",
  sourceLang: "sourceLang",
  targetLangs: "targetLangs",
  code: "code",
  otpToken: "otpToken",
  all: "all",
  searchValue: "searchValue",
  hasSignedUp: "hasSignedUp",
};

export const QUERY_VALUES = {
  sortDirASC: "ASC",
  sortDirDESC: "DESC",
};
