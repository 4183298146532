import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Modal from "components/modal/Modal";
import FooterButtons from "components/admin/elements/footer-buttons/FooterButtons";
import { ReactComponent as ExclamationIcon } from "assets/icons/other/Exclamation.svg";
import { ENUMS as ENUMS_PRIMARY_BUTTON } from "components/admin/buttons/primary-button/PrimaryButton";
import ICON_WARNING from "assets/icons/other/WarningTriangle.svg";

import "./Confirm.scss";

export const ENUMS = {
  name: "Confirm",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
  },
};

const Confirm = ({
  type = ENUMS.types.TYPE_A,
  title,
  description,
  mainElementRefConfirm,
  openSlide,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();

  const getConfirmButtonType = (type) => {
    switch (type) {
      case ENUMS.types.TYPE_A:
        return ENUMS_PRIMARY_BUTTON.types.TYPE_C;
      case ENUMS.types.TYPE_C:
        return ENUMS_PRIMARY_BUTTON.types.TYPE_B;
      default:
        return ENUMS_PRIMARY_BUTTON.types.TYPE_B; // Or any other default type you want to set
    }
  };

  // Then, in your component:

  const confirmButtonType = getConfirmButtonType(type);

  const ConfirmBody = (
    <div className="ConfirmModalBody">
      <div className="ConfirmModalBodyIcon">
        <img
          className="ConfirmModalBodyIconTriangle"
          src={ICON_WARNING}
          alt="warning sign"
        />
        <ExclamationIcon />
      </div>
      <div className="ConfirmModalBodyContent">
        <h4 className="Medium">{title}</h4>
        <h6>{description}</h6>
      </div>
    </div>
  );

  const ConfirmFooter = (
    <FooterButtons
      onConfirm={onConfirm}
      onCancel={onCancel}
      cancelButtonText={t("modal.no")}
      confirmButtonText={t("modal.yes")}
      confirmButtonType={confirmButtonType}
    />
  );

  return (
    <div
      className={cx("Confirm", {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B || type === ENUMS.types.TYPE_C,
      })}
    >
      <Modal
        body={ConfirmBody}
        footer={ConfirmFooter}
        mainElementRef={mainElementRefConfirm}
        openSlide={openSlide}
      />
    </div>
  );
};

Confirm.propTypes = {
  /**
   * The type of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * The title to display in the confirmation dialog
   */
  title: PropTypes.string.isRequired,

  /**
   * The description to display in the confirmation dialog
   */
  description: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  /**
   * The ref for the modal
   */
  mainElementRefConfirm: PropTypes.object,

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool.isRequired,

  /**
   * The function called when the confirmation action is performed
   */
  onConfirm: PropTypes.func.isRequired,

  /**
   * The function called when the cancel action is performed
   */
  onCancel: PropTypes.func.isRequired,
};

export default Confirm;
