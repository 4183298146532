import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { STORE_NAMES } from "utils/constants/redux";
import useInterval from "utils/hooks/useInterval";

import "./AdminReservationSchedule.scss";

const roundToQuarterHour = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const minutes = date.getMinutes();
  const roundedMinutes = Math.floor(minutes / 15) * 15;
  date.setMinutes(roundedMinutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.toISOString();
};

const filterReservationsBySelectedDay = (data, targetDate) => {
  const selectedDay = new Date(targetDate);
  const filteredDataBySelectedDate = data.filter((reservation) => {
    const reservationStartDateTime = new Date(reservation.startDateTime);
    return (
      reservationStartDateTime.getUTCFullYear() ===
        selectedDay.getUTCFullYear() &&
      reservationStartDateTime.getUTCMonth() === selectedDay.getUTCMonth() &&
      reservationStartDateTime.getUTCDate() === selectedDay.getDate()
    );
  });

  return filteredDataBySelectedDate;
};

const slots = Array.from({ length: 96 }, (_, i) => {
  const hour =
    Math.floor(i / 4) > 9 ? Math.floor(i / 4) : "0" + Math.floor(i / 4);
  const minute = (i % 4) * 15;
  const minuteFormatted = minute === 0 ? "00" : minute;
  return {
    id: `slot-${i}`,
    title: `${hour}:${minuteFormatted}`,
  };
});

const calculateSlotDifference = (startDateTime, endDateTime) => {
  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);

  const differenceInMilliseconds = endDate - startDate;
  const millisecondsIn15Minutes = 1000 * 60 * 15; // 15 minutes in milliseconds
  const differenceInSlots = differenceInMilliseconds / millisecondsIn15Minutes;

  return differenceInSlots;
};

const getHours = (date) => {
  const newDate = new Date(date);
  let hours = newDate.getUTCHours();
  let minutes = newDate.getUTCMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  return `${hours}:${minutes}`;
};

const updateTime = (dateTime, newTime, day) => {
  const date = new Date(dateTime);
  const [newHours, newMinutes] = newTime.split(":").map(Number);
  date.setUTCDate(day);
  date.setUTCHours(newHours);
  date.setUTCMinutes(newMinutes);
  date.setUTCSeconds(0);
  return date.toISOString();
};
const groupByStartTime = (reservations) => {
  return reservations.reduce((acc, reservation) => {
    const startTime = reservation.startDateTime;

    if (!acc[startTime]) {
      acc[startTime] = [];
    }

    acc[startTime].push(reservation);

    return acc;
  }, {});
};

const groupOverlaps = (reservations) => {
  const overlapGroups = [];
  const nonOverlapGroups = [];

  reservations.forEach((currentRes, index) => {
    let isOverlapping = false;

    for (let i = 0; i < reservations.length; i++) {
      if (i !== index) {
        const compareRes = reservations[i];

        const currentStart = new Date(currentRes.startDateTime).getTime();
        const currentEnd = new Date(currentRes.endDateTime).getTime();
        const compareStart = new Date(compareRes.startDateTime).getTime();
        const compareEnd = new Date(compareRes.endDateTime).getTime();

        // Check if there is an overlap
        if (currentStart < compareEnd && currentEnd > compareStart) {
          isOverlapping = true;
          break;
        }
      }
    }

    if (isOverlapping) {
      overlapGroups.push(currentRes);
    } else {
      nonOverlapGroups.push({ ...currentRes, overlapIndex: 0 });
    }
  });

  const groupedOverlaps = groupByStartTime(overlapGroups);

  let overlapIndex = 1;
  let sss = 1;
  Object.keys(groupedOverlaps).forEach((startTime) => {
    groupedOverlaps[startTime] = groupedOverlaps[startTime].map(
      (reservation, index) => {
        if (index === 0) {
          return { ...reservation, overlapIndex: overlapIndex };
        } else {
          return { ...reservation, overlapIndex: 1 };
        }
      }
    );
    sss += groupedOverlaps[startTime].length;
    overlapIndex = sss;
  });

  return { groupedOverlaps, nonOverlapGroups };
};
const aggregateReservations = (data) => {
  const result = [];

  // Process overlapping reservations
  Object.values(data).forEach((table) => {
    Object.values(table.overlappingGroupedByStartTime).forEach(
      (reservations) => {
        result.push(...reservations);
      }
    );
  });

  // Process non-overlapping reservations
  Object.values(data).forEach((table) => {
    result.push(...table.nonOverlapping);
  });

  return result;
};
const editData = (lastData) => {
  const groupedByTable = lastData.reduce((acc, reservation) => {
    const tableId = reservation.tableId || reservation.table.id;

    if (!acc[tableId]) {
      acc[tableId] = [];
    }

    acc[tableId].push(reservation);

    return acc;
  }, {});

  const groupedResults = Object.keys(groupedByTable).reduce((acc, tableId) => {
    const { groupedOverlaps, nonOverlapGroups } = groupOverlaps(
      groupedByTable[tableId]
    );

    acc[tableId] = {
      overlappingGroupedByStartTime: groupedOverlaps,
      nonOverlapping: nonOverlapGroups,
    };

    return acc;
  }, {});

  return aggregateReservations(groupedResults);
};
const calculateDayProgress = () => {
  const now = new Date();

  const millisecondsPassed =
    now.getHours() * 60 * 60 * 1000 +
    now.getMinutes() * 60 * 1000 +
    now.getSeconds() * 1000 +
    now.getMilliseconds();

  const millisecondsInDay = 24 * 60 * 60 * 1000;

  const progress = (millisecondsPassed / millisecondsInDay) * 100;

  return progress.toFixed(2);
};

const AdminReservationSchedule = ({
  reservations,
  selectedDate,
  selectedZones,
  isToday,
  editedReservations,
  setEditedReservations,
  setSelectedReservations,
  setOpenSlide,
}) => {
  const [data, setData] = useState(reservations);
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const reservationTableRef = useRef(null);
  const [leftDistance, setLeftDistance] = useState(0);
  const { t } = useTranslation();

  const onDragEnd = useCallback(
    (result) => {
      const { destination, draggableId } = result;
      if (!destination) return;

      const tableId = destination.droppableId.split("&")[0];
      const slotId = destination.droppableId.split("&")[1];

      if (tableId && slotId) {
        const draggedReservation = data.find(
          (res) => String(res.id) === String(draggableId)
        );
        const slotDifference = calculateSlotDifference(
          draggedReservation.startDateTime,
          draggedReservation.endDateTime
        );

        const startSlot = slots.findIndex((slot) => slot.id === slotId);
        const newStartTime = slots[startSlot].title;
        const newEndTime = slots[startSlot + slotDifference].title;

        const updatedReservations = data.map((res) => {
          if (String(res.id) === String(draggableId)) {
            const currentDay = selectedDate.getDate();
            const editedReservation = {
              ...res,
              tableId: parseInt(tableId),
              startDateTime: updateTime(
                res.startDateTime,
                newStartTime,
                currentDay
              ),
              endDateTime: updateTime(res.endDateTime, newEndTime, currentDay),
            };

            setEditedReservations((prevState) => {
              const existingReservation = prevState.find(
                (prevRes) => prevRes.id === editedReservation.id
              );
              return existingReservation
                ? prevState.map((prevRes) =>
                    prevRes.id === editedReservation.id
                      ? editedReservation
                      : prevRes
                  )
                : [...prevState, editedReservation];
            });

            return editedReservation;
          }
          return res;
        });
        setData(updatedReservations);
      }
    },
    [data, setEditedReservations]
  );

  useEffect(() => {
    if (reservations !== data) {
      setData(reservations);
    }
  }, [reservations, data]);

  useInterval({
    callback: () => {
      setLeftDistance(
        ((reservationTableRef.current.clientWidth - 83) / 100) *
          calculateDayProgress()
      );
    },
    delay: 60000,
    immediate: true,
  });

  const displayedReservations = useMemo(() => {
    const roundedData = data.map((reservation) => {
      return {
        ...reservation,
        startDateTime: roundToQuarterHour(reservation.startDateTime),
        endDateTime: roundToQuarterHour(reservation.endDateTime),
      };
    });
    return editData(filterReservationsBySelectedDay(roundedData, selectedDate));
  }, [data, selectedDate]);

  const timeLineHeight =
    selectedZones.flatMap((zone) => zone.tables).length * 72 + 7;
  return (
    <div className="AdminReservationScheduleContainer">
      <div className="AdminReservationSchedule" ref={reservationTableRef}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="AdminReservationScheduleSlots">
            {Array.from({ length: 24 }, (_, hour) => (
              <div key={hour} className="AdminReservationScheduleHourBoxHeader">
                <div className="AdminReservationScheduleHourHeader">
                  <h6 className="Medium">{hour > 9 ? hour : "0" + hour}:00</h6>
                </div>
                <div className="AdminReservationScheduleHourBoxParts">
                  {Array.from({ length: 5 }, (_, hour) => (
                    <div
                      key={hour}
                      className="AdminReservationScheduleHourBoxPart"
                    ></div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {zones.length === 0 ||
          selectedZones.every(
            (zone) => !zone.tables || zone.tables.length === 0
          ) ? (
            <div className="AdminReservationScheduleEmptyState">
              <h4 className="Medium">{t("emptyTable.emptyZoneDescription")}</h4>
            </div>
          ) : (
            selectedZones.map((zone) => (
              <div className="AdminReservationScheduleZoneRow" key={zone.id}>
                {zone.tables.map((table) => (
                  <div
                    key={table.id}
                    className="AdminReservationScheduleTableRow"
                  >
                    <div className="AdminReservationScheduleTableResource">
                      <h6 className="SemiBold h7">{zone.name}</h6>
                      <h6 className="SemiBold h7 AdminReservationScheduleTableName">
                        {table.name}
                      </h6>
                    </div>
                    {Array.from({ length: 24 }, (_, hour) => (
                      <div
                        key={hour}
                        className="AdminReservationScheduleHourBox"
                      >
                        {slots
                          .filter(
                            (slot) =>
                              Math.floor(slot.id.split("-")[1] / 4) === hour
                          )
                          .map((slot) => (
                            <Droppable
                              key={slot.id}
                              droppableId={`${table.id}&${slot.id}&1`}
                              direction="horizontal"
                              isDropDisabled={false}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className={cx(
                                    "AdminReservationScheduleSlotColumn",
                                    {
                                      isDraggingOver: snapshot.isDraggingOver,
                                    }
                                  )}
                                >
                                  {displayedReservations
                                    .filter((res) => {
                                      const tableId =
                                        res.tableId || res.table.id;
                                      return (
                                        tableId === table.id &&
                                        slots.find(
                                          (s) =>
                                            s.title ===
                                            getHours(res.startDateTime)
                                        ).id === slot.id
                                      );
                                    })
                                    .map((reservation, index) => {
                                      const startTime = getHours(
                                        reservation.startDateTime
                                      );
                                      const endTime = getHours(
                                        reservation.endDateTime
                                      );
                                      const slotDifference =
                                        calculateSlotDifference(
                                          reservation.startDateTime,
                                          reservation.endDateTime
                                        );
                                      const width = slotDifference * 15;
                                      const { overlapIndex } = reservation;
                                      const marginTop =
                                        overlapIndex > 0
                                          ? overlapIndex * 6 +
                                            (overlapIndex - 1) * 60
                                          : 6;
                                      return (
                                        <Draggable
                                          key={reservation.id}
                                          draggableId={String(reservation.id)}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <>
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onClick={() => {
                                                  if (
                                                    editedReservations.length ===
                                                    0
                                                  ) {
                                                    setSelectedReservations(
                                                      data.find(
                                                        (r) =>
                                                          r.id ===
                                                          reservation.id
                                                      )
                                                    );
                                                    setOpenSlide(true);
                                                  }
                                                }}
                                                className="AdminReservationScheduleReservation"
                                                style={{
                                                  ...provided.draggableProps
                                                    .style,
                                                  marginTop,
                                                }}
                                              >
                                                <div
                                                  className="AdminReservationScheduleReservationContent"
                                                  style={{
                                                    width: `${width}px`,
                                                  }}
                                                ></div>
                                                <h6
                                                  className="Medium"
                                                  style={{
                                                    width: `${width}px`,
                                                  }}
                                                >
                                                  {reservation.guestName}
                                                </h6>
                                                <h6 className="h8">{`${startTime}-${endTime}`}</h6>
                                              </div>
                                            </>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))
          )}
          {isToday && (
            <div
              className="AdminReservationSchedulePresentTimeLine"
              style={{
                transform: ` translateX(${82 + leftDistance}px)`,
                height: timeLineHeight + "px",
              }}
            ></div>
          )}
        </DragDropContext>
      </div>
    </div>
  );
};
AdminReservationSchedule.propTypes = {
  reservations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      tableId: PropTypes.number,
      startDateTime: PropTypes.string.isRequired,
      endDateTime: PropTypes.string.isRequired,
      guestName: PropTypes.string,
      table: PropTypes.shape({
        id: PropTypes.number,
        zone: PropTypes.number,
        name: PropTypes.string,
      }),
    })
  ).isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  selectedZones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      tables: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
  isToday: PropTypes.bool.isRequired,
  editedReservations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      startDateTime: PropTypes.string.isRequired,
      endDateTime: PropTypes.string.isRequired,
      tableId: PropTypes.number,
    })
  ).isRequired,
  setEditedReservations: PropTypes.func.isRequired,
  setSelectedReservations: PropTypes.func.isRequired,
  setOpenSlide: PropTypes.func.isRequired,
};

export default AdminReservationSchedule;
