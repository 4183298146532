import React from "react";
import PropTypes from "prop-types";
import { CHART_DATA_ORDER_HISTORY_KEYS } from "pages/admin/admin-pages/admin-dashboard/admin-dash-order-stats/AdminDashOrderStats";
import { CHART_DATA_KEYS } from "pages/admin/admin-pages/admin-dashboard/admin-dash-feedbacks/AdminDashFeedbacks";

import "./CustomTooltip.scss";

const CustomTooltip = ({
  active,
  payload,
  title,
  className,
  currencySymbol,
  t,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className={`CustomTooltip ${className}`}>
        <h6 className="Medium CustomTooltipLegendInfo">
          {payload[0]?.payload[title]}
        </h6>
        <div className="CustomTooltipLegends">
          {payload?.map((legend, index) => {
            return (
              <div key={`legend-${index}`} className="LegendInfo">
                <h6 className="h7 Medium CustomTooltipLegendInfo">
                  {t(`dashboard.legends.${legend.dataKey}`)}:
                </h6>
                <h6 className="h7 Medium CustomTooltipLegendInfo">
                  {legend.dataKey ===
                    CHART_DATA_ORDER_HISTORY_KEYS.ordersDataKey ||
                  legend.dataKey ===
                    CHART_DATA_ORDER_HISTORY_KEYS.orderItemsDataKey ||
                  legend.dataKey === CHART_DATA_KEYS.starCount
                    ? legend.value
                    : parseFloat(legend.value).toFixed(2)}
                  {currencySymbol}
                </h6>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  /**
   * Indicates whether the tooltip is active (visible).
   */
  active: PropTypes.bool,

  /**
   * An array of payload objects.
   */
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.object.isRequired,
    })
  ),

  title: PropTypes.string,
  className: PropTypes.string,
  currencySymbol: PropTypes.string,
  t: PropTypes.func,
};

export default CustomTooltip;
