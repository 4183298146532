import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { createDOBucketName } from "utils/DO-Spaces";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import AddItem from "./add-item/AddItem";
import { ReactComponent as CheckIcon } from "assets/icons/check/Select.svg";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import useLanguage from "utils/hooks/useLanguage";
import DeletedIcon from "assets/icons/client-dashboard/trash.svg";
import DeniedIcon from "assets/icons/client-dashboard/close.svg";
import DeleteIcon from "assets/icons/trash/Trash.svg";
import useOutsideClick from "utils/hooks/useOutsideClick";
import DeleteMenuItemsModal from "components/admin/modal/delete-menu-items-modal/DeleteMenuItemsModal";
import { guestUpdateOrderAsync } from "redux/actions/orderActions";

import "./DashboardOrders.scss";

export const ENUMS = {
  name: "DashboardOrders",
};

const DashboardOrders = ({
  allParticipants,
  setSelectedGuests,
  selectedOrders,
  setSelectedOrders,
  selectedGuests,
}) => {
  const allOrderItems = allParticipants
    ?.flatMap((guest) => guest.orderItems)
    .sort((a, b) => {
      // Sort by isWaiting (true first)
      if (a.isWaiting && !b.isWaiting) return -1;
      if (!a.isWaiting && b.isWaiting) return 1;

      // Sort by isConfirmed true and isPaid false
      if (
        a.isConfirmed === true &&
        a.isPaid === false &&
        !(b.isConfirmed === true && b.isPaid === false)
      )
        return -1;
      if (
        !(a.isConfirmed === true && a.isPaid === false) &&
        b.isConfirmed === true &&
        b.isPaid === false
      )
        return 1;

      // Sort by isPaid (true first)
      if (a.isPaid && !b.isPaid) return -1;
      if (!a.isPaid && b.isPaid) return 1;

      // Sort by isDenied (true first)
      if (a.isDenied && !b.isDenied) return -1;
      if (!a.isDenied && b.isDenied) return 1;

      // Sort by isDeleted (true first)
      if (a.isDeleted && !b.isDeleted) return -1;
      if (!a.isDeleted && b.isDeleted) return 1;

      // If all conditions are equal, keep original order
      return 0;
    });

  const { displayDataByLanguage } = useLanguage();
  const dispatch = useDispatch();
  const [
    openSlideDeleteItem,
    setOpenSlideDeleteItem,
    mainElementRefDeleteItem,
  ] = useOutsideClick();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: businessId } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const tableId = useSelector((state) => state[STORE_NAMES.qrScan].table.id);
  const ordersForGuest = useSelector(
    (state) => state[STORE_NAMES.orders].orders
  );
  const orderId = ordersForGuest?.find(
    (order) => order.table.id === tableId
  ).id;

  const currency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );

  useEffect(() => {
    for (const participiant of allParticipants) {
      const participiantId = participiant.id;
      const selectableOrderItems = participiant.orderItems
        .filter((orderItem) => {
          return (
            !orderItem.isDeleted && !orderItem.isDenied && !orderItem.isPaid
          );
        })
        .flatMap((orderItem) => orderItem.id);

      const participiantAllItemsIsSelected =
        selectableOrderItems.length > 0 &&
        selectableOrderItems.every((orderItemId) =>
          selectedOrders.includes(orderItemId)
        );

      if (
        participiantAllItemsIsSelected &&
        !selectedGuests.includes(participiantId)
      ) {
        setSelectedGuests((prev) => [...prev, participiantId]);
      } else if (
        !participiantAllItemsIsSelected &&
        selectedGuests.includes(participiantId)
      ) {
        setSelectedGuests((prev) =>
          prev.filter((selectedGuestId) => selectedGuestId !== participiantId)
        );
      }
    }
  }, [selectedOrders]);

  const handleAddItemClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  const handleUnSelectItems = () => {
    setSelectedOrders([]);
    setSelectedGuests([]);
  };

  const handleSelectOrders = (order) => {
    setSelectedOrders((prev) => {
      if (selectedOrders.includes(order.id)) {
        return prev.filter((el) => el !== order.id);
      } else {
        return [...prev, order.id];
      }
    });
  };

  const handleDeleteMenuItem = () => {
    setOpenSlideDeleteItem(true);
  };

  const handleCancelModal = () => {
    setOpenSlideDeleteItem(false);
  };

  const selectedOrderObjects = allOrderItems.filter((orderItem) =>
    selectedOrders.includes(orderItem.id)
  );

  const handleConfirmModal = () => {
    const deletedBody = allParticipants
      .filter((participant) => participant.isGuest)
      .map((participant) => {
        return {
          id: participant.ordererId,
          person: { id: participant.originalId },
          orderItems: participant.orderItems
            .filter((orderItem) => {
              return (
                selectedOrders.includes(orderItem.id) &&
                orderItem.isConfirmed === null
              );
            })
            .map((orderItem) => ({
              id: orderItem.id,
              isArchived: true,
              isConfirmed: false,
            })),
        };
      })
      .filter((participant) => participant.orderItems.length > 0);

    const anyPendingItems = allParticipants
      .flatMap(({ orderItems }) => orderItems)
      .some(
        (orderItem) =>
          orderItem.isConfirmed === null &&
          !selectedOrders.includes(orderItem.id)
      );

    dispatch(
      guestUpdateOrderAsync({
        businessId,
        order: { hasNewOrder: anyPendingItems, guests: deletedBody },
        id: orderId,
      })
    );

    setSelectedOrders([]);
    setOpenSlideDeleteItem(false);
  };

  const showDeleteButton = selectedOrderObjects.some(
    (item) => item.isConfirmed !== false
  );

  const isSelectedOrderItem = (orderItem) => {
    return selectedOrders.includes(orderItem.id);
  };

  const isSelectableOrderItem = (orderItem) => {
    return orderItem.isConfirmed !== false && !orderItem.isPaid;
  };

  return (
    <div className="DashboardOrdersContainer">
      {allOrderItems.length > 0 && (
        <div className="DashboardOrdersHeaders">
          <h6 className="Medium">{t("dashboard.selectToPay")}</h6>

          {showDeleteButton && (
            <div className="DashboardOrdersActionButtons">
              <div
                className="DashboardOrdersDeleteIcon"
                onClick={handleDeleteMenuItem}
              >
                <img src={DeleteIcon} alt="delete" />
              </div>

              <button className="UnSelectBtn" onClick={handleUnSelectItems}>
                <h6 className="Medium">{t("dashboard.unSelect")}</h6>
              </button>
            </div>
          )}
        </div>
      )}

      <div className="DashboardOrders">
        <AddItem handleAddItemClick={handleAddItemClick} />
        {allOrderItems.map((orderItem, index) => {
          const {
            isConfirmed,
            isWaiting,
            isDenied,
            isDeleted,
            isPaid,
            isInProgress,
            item,
            count,
          } = orderItem;
          const isSelected = isSelectedOrderItem(orderItem);
          const isSelectable = isSelectableOrderItem(orderItem);
          return (
            <div
              key={index}
              className={cx("OrderItemCard", "OrderCard", {
                isSelected,
                isDenied,
                isDeleted,
                isPaid,
                isInProgress,
              })}
              onClick={
                isSelectable ? () => handleSelectOrders(orderItem) : undefined
              }
            >
              <ImageWithPlaceholder
                imageSource={createDOBucketName(item.coverImageSrc)}
                placeholder={IMAGE_ITEM_PLACEHOLDER}
                alt={item?.[0]?.name}
              />
              <span className={cx("ConfirmInfo", { other: isDenied })}>
                {t("dashboard.denied")}
              </span>
              {isDenied && (
                <img src={DeniedIcon} alt="denied" className="DeniedIcon" />
              )}
              <span className={cx("ConfirmInfo", { other: isDeleted })}>
                {t("dashboard.deleted")}
              </span>
              {isDeleted && (
                <img src={DeletedIcon} alt="deleted" className="DeniedIcon" />
              )}
              {isPaid && (
                <div className={cx("GuestPaidInfo", { other: isPaid })}>
                  <div className="GuestPaidInfoWrapper">
                    <div className="GuestPaidInfoText">
                      <div className="PaidIcon">{currency.symbol}</div>
                      <h6 className="Medium">{t("dashboard.paid")}</h6>
                    </div>
                  </div>
                </div>
              )}
              {isInProgress && !isPaid && (
                <div
                  className={cx("GuestInProgressInfo", { other: isInProgress })}
                >
                  <div className="GuestInProgressInfoWrapper">
                    <div className="GuestInProgressInfoText">
                      <div className="InProgressIcon">{currency.symbol}</div>
                      <h6 className="Medium">
                        {t("dashboard.paymentInProgress")}
                      </h6>
                    </div>
                  </div>
                </div>
              )}

              {isConfirmed !== false && !isPaid && !isInProgress && (
                <div
                  className={cx("OrderItemStatus", {
                    isWaiting,
                    isConfirmed,
                  })}
                ></div>
              )}

              <div className="OrderItemName">
                <h5 className="h8">
                  <span>{count}</span>x
                </h5>
                <h6 className="SemiBold h7">
                  {displayDataByLanguage(item.name)}
                </h6>
              </div>
              {isSelected && (
                <div className={cx("OrderItemSelect")}>
                  <CheckIcon />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <DeleteMenuItemsModal
        openSlide={openSlideDeleteItem}
        orderItems={selectedOrderObjects}
        guestId={guestId}
        onCancel={handleCancelModal}
        onConfirm={handleConfirmModal}
        mainElementRef={mainElementRefDeleteItem}
      />
    </div>
  );
};

DashboardOrders.propTypes = {
  allParticipants: PropTypes.array,
  guests: PropTypes.array,
  setSelectedGuests: PropTypes.func,
  setSelectedOrders: PropTypes.func,
  selectedGuests: PropTypes.array,
  selectedOrders: PropTypes.array,
};

export default DashboardOrders;
