import React, { useEffect } from "react";

import useLogoutGuest from "utils/hooks/useGuestLogout";
import ForgotPasswordStepper from "pages/client/auth/forget-password/forget-password-stepper/ForgotPasswordStepper";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";

import "./ForgotPassword.scss";

const ForgotPassword = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.forgotPassword });
  const { logoutGuest } = useLogoutGuest();

  useEffect(() => {
    logoutGuest();
  }, []);

  return (
    <div className="GuestAuthForgetPassword">
      <ForgotPasswordStepper />
    </div>
  );
};

export default ForgotPassword;
