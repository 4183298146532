import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import BACK_ICON from "assets/icons/arrows/ArrowLeftWhite.svg";
import AddTable from "assets/icons/map/AddTable.svg";
import UnMappedTables from "assets/icons/map/UnMapped.svg";
// import Window from "assets/icons/map/Window.svg";
// import DesignElements from "assets/icons/map/Design.svg";
// import Decoration from "assets/icons/map/Decoration.svg";
import MapSidebarButton from "components/buttons/map-sidebar-button/MapSidebarButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import AddToMapModal from "pages/admin/admin-pages/admin-map/add-to-map-modal/AddToMapModal";
import { STORE_NAMES } from "utils/constants/redux";
import AdminTableModal from "pages/admin/admin-pages/admin-qr-table-map/admin-map/admin-table-modal/AdminTableModal";
import useAsync from "utils/hooks/useAsync";
import {
  createTable,
  deleteTable,
  updateTable,
} from "utils/api/services/table";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { getZonesAsync } from "redux/actions/zoneAction";
import { getAllQrAsync } from "redux/actions/qrAction";
import { createQRCode } from "utils/api/services/QR";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { generateShapeForMap } from "pages/admin/admin-pages/admin-map/helper";

import "./AdminMapSidebar.scss";

const AdminMapSidebar = ({ selectedZone, setMapItems, mapItems, onGoBack }) => {
  const { t } = useTranslation();
  const [activeZone, setActiveZone] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formDataInitial, setFormDataInitial] = useState(null);
  const [openSlide, setOpenSlide, mainElementRef, , , setOutsideClickAction] =
    useOutsideClick();
  const [
    openSlideAddTableMap,
    setOpenSlideAddTableMap,
    mainElementRefAddTableMap,
  ] = useOutsideClick();

  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const dispatch = useDispatch();

  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );

  const { execute: executeUpdateTable, loading: isLoadingUpdateTable } =
    useAsync(updateTable, {
      onError: () => handleOnAsyncErrorForZone(),
      onSuccess: () =>
        handleOnAsyncSuccessForTable(t("toastMessages.success.updateTable")),
    });

  const handleOnAsyncErrorForZone = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };
  const handleOnAsyncSuccessForTable = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlide(false);
      //TODO remove reFetch after web sockets
      dispatch(getZonesAsync({ businessId }));
    });
  };

  const handleOnAsyncSuccessForQr = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlide(false);
      dispatch(getAllQrAsync(businessId));
    });
  };

  const { execute: executeDeleteTable } = useAsync(deleteTable, {
    onError: (data) => {
      handleOnAsyncErrorForZone(data.response.data.message);
    },
    onSuccess: () =>
      handleOnAsyncSuccessForTable(t("toastMessages.success.deleteTable")),
  });

  const { execute: executeCreateQRCode, loading: isLoadingCreateQr } = useAsync(
    createQRCode,
    {
      onError: () => handleOnAsyncErrorForZone(),
      onSuccess: () => {
        handleOnAsyncSuccessForQr(t("toastMessages.success.createQR"));
      },
    }
  );
  const { execute: executeCreateTable, loading: isLoadingCreateTable } =
    useAsync(createTable, {
      onError: handleOnAsyncErrorForZone,
      onSuccess: async ({ data: table }) => {
        const { name: tableName, id: tableId, qrLinks } = table;
        if (qrLinks?.length === 0) {
          const qrData = new FormData();
          qrData.append(
            "name",
            `${t("navbarRoutes.table")}-${tableName}-${tableId}`
          );
          qrData.append("tableId", tableId.toString());
          qrData.append(
            "qrData[url]",
            `${window.location.origin}${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}?${QUERY_PARAMS.qrId}=`
          );
          await executeCreateQRCode(businessId, qrData, "URL");
        }
        handleOnAsyncSuccessForTable(t("toastMessages.success.createTable"));
      },
    });

  useEffect(() => {
    if (zones.length === 0) {
      setActiveZone(null);
    }
    if (!activeZone) {
      setActiveZone(zones?.[0]);
    }
  }, [zones]);

  const handleOnDeleteTable = async (id) => {
    await executeDeleteTable(businessId, id);
  };

  const handleOnSaveTable = async (data, id) => {
    const table = {
      name: data.name,
      zone: data.zone.id,
      assigneeId: data.assignee.id,
      qrLinks: data.QR.map((qr) => qr.id),
      minDeposit: data.minDeposit,
      maxSeat: parseInt(data.maxSeat),
      coordinates: {
        x: 0,
        y: 0,
        rotation: 0,
        width: 2,
        height: 2,
      },
    };
    if (id) {
      await executeUpdateTable(businessId, table, id);
    } else {
      await executeCreateTable(businessId, table);
    }
  };

  const handleAddTableToMap = (tables) => {
    const addedTables = generateShapeForMap(tables);
    setMapItems((prev) => {
      return [...prev, ...addedTables];
    });
  };
  const qrCodes = zones?.flatMap((zone) =>
    zone.tables.flatMap((table) =>
      table.qrLinks.map((qrCode) => ({
        ...qrCode,
        name: `${qrCode.name} (${t("qr.connected")} ${table.name}, ${
          qrCode.type
        })`,
      }))
    )
  );

  const handleOnAddTable = () => {
    setOpenSlide(true);
    setSelectedTable(null);
  };
  const handleOpenAddTableToModal = () => {
    setOpenSlideAddTableMap(true);
  };
  const unAttachedTables =
    (selectedZone &&
      zones
        .find((zone) => zone.id === selectedZone.id)
        ?.tables?.filter((table) => {
          const isExistOnMap = mapItems
            .flatMap((item) => parseInt(item.id))
            .includes(table.id);
          const { x, y, width, height } = table.coordinates;
          return !x && !y && !width && !height && !isExistOnMap;
        })) ||
    [];

  const sidebarButtons = [
    {
      title: t("map.createTable"),
      disabled: zones.length === 0,
      icon: AddTable,
      onclick: handleOnAddTable,
    },
    {
      title: t("map.addToMap"),
      icon: UnMappedTables,
      disabled: unAttachedTables.length === 0,
      onclick: handleOpenAddTableToModal,
    },
    // { title: t("map.windowAndDoor"), icon: Window },
    // { title: t("map.designElements"), icon: DesignElements },
    // { title: t("map.decoration"), icon: Decoration },
  ];
  return (
    <div className="AdminMapSidebar">
      <MapSidebarButton
        title={t("buttons.back")}
        icon={BACK_ICON}
        onClick={onGoBack}
      />
      <div className="AdminMapSidebarElements">
        {sidebarButtons.map(({ title, icon, onclick, disabled }, index) => (
          <MapSidebarButton
            key={index}
            title={title}
            icon={icon}
            onClick={disabled ? () => {} : onclick}
            disabled={disabled}
          />
        ))}
      </div>
      <div className="AdminMapSidebarActionButtons"></div>
      <AdminTableModal
        mainElementRef={mainElementRef}
        setOpenSlide={setOpenSlide}
        formData={formData}
        setFormData={setFormData}
        formDataInitial={formDataInitial}
        setFormDataInitial={setFormDataInitial}
        selectedTable={selectedTable}
        connectedQRCodes={qrCodes}
        zones={zones}
        activeZone={selectedZone}
        openSlide={openSlide}
        title={t("table.table")}
        onSave={handleOnSaveTable}
        onDelete={handleOnDeleteTable}
        setOutsideClickAction={setOutsideClickAction}
        isLoading={
          isLoadingCreateTable || isLoadingUpdateTable || isLoadingCreateQr
        }
      />
      <AddToMapModal
        tables={unAttachedTables}
        openSlide={openSlideAddTableMap}
        mainElementRef={mainElementRefAddTableMap}
        setOpenSlide={setOpenSlideAddTableMap}
        onSave={handleAddTableToMap}
        mapItems={mapItems}
        setOutsideClickAction={setOutsideClickAction}
      />
    </div>
  );
};

AdminMapSidebar.propTypes = {
  selectedZone: PropTypes.object,
  mapItems: PropTypes.array,
  onGoBack: PropTypes.func,
  setMapItems: PropTypes.func,
};
export default AdminMapSidebar;
