import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import GuestProfileWithIcon from "components/elements/guest-profile-with-icon/GuestProfileWithIcon";
import { updateGuestInfoAsync } from "redux/actions/guestAction";
import AddTagButton, {
  ENUMS as ENUMS_ADD_TAG_BUTTON,
} from "components/admin/buttons/add-tag-button/AddTagButton";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { DO_FILE_TYPES, IMAGE_FILE } from "utils/constants/DOSpaces";
import { uploadImageToDO } from "utils/DO-Spaces";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import Spinner from "components/elements/spinner/Spinner";
import EditButton, {
  ENUMS as ENUMS_EDIT_BUTTON,
} from "components/admin/buttons/edit-button/EditButton";
import { MP_EVENTS } from "utils/constants/mixpanel";
import { useMixpanel } from "utils/context-api/MixpanelContext";

import "./SignUpDetailForm.scss";

const SignUpDetailForm = ({ setFormData, formData }) => {
  const { trackMixpanel } = useMixpanel();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const genderTypes =
    useSelector((state) => state[STORE_NAMES.app].enums.genderTypes) || {};

  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);

  const dispatch = useDispatch();

  const methods = useForm({
    criteriaMode: "all",
  });

  const { handleSubmit, register } = methods;

  const [profileImageLoading, setProfileImageLoading] = useState(false);

  const handleDate = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      dateOfBirth: value,
    });
  };
  const handleGenderChange = (option) => {
    setFormData({
      ...formData,
      gender: option,
    });
  };

  const fileInputRef = useRef(null);

  const handleOnSelectSingleImage = async (selectedImage) => {
    const imageType = DO_FILE_TYPES.GUEST_PROFILE_PHOTO;
    setProfileImageLoading(true);
    try {
      await uploadImageToDO({
        image: selectedImage,
        options: IMAGE_FILE.guestAvatar,
        fileType: imageType,
        businessId,
        onSuccess: (location) => {
          setFormData((prev) => {
            return {
              ...prev,
              profilePic: location,
            };
          });
          setProfileImageLoading(false);
        },
      });
    } catch (error) {
      throw new Error("Something went wrong...", error);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = async (e) => {
    const selectedFiles = e.target.files[0];
    if (selectedFiles) {
      try {
        await handleOnSelectSingleImage(selectedFiles);
      } catch (error) {
        toast.error(t("errorMessages.image"));
      }
      e.target.value = "";
    }
  };
  const handleOnSubmit = async () => {
    const { profilePic, dateOfBirth, gender } = formData;
    if (!profilePic && !dateOfBirth && !gender) {
      trackMixpanel(`${MP_EVENTS.auth.signUpCompleted}`);
      return navigate(
        businessId
          ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
          : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`
      );
    }
    try {
      const guestBody = {
        ...(profilePic ? { profilePic } : {}),
        ...(dateOfBirth ? { dateOfBirth } : {}),
        ...(gender && gender.id ? { gender: gender.id } : {}),
      };
      setIsUpdatingProfile(true);
      await dispatch(
        updateGuestInfoAsync({
          guestId,
          guestBody,
        })
      );
      setIsUpdatingProfile(false);
      navigate(
        businessId
          ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
          : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`
      );
      trackMixpanel(`${MP_EVENTS.auth.signUpCompleted}`);
    } catch (error) {
      toast.error(t("errorMessages.updateFailed"));
    }
  };

  return (
    <div className="SignUpDetailForm">
      <div className="SignUpDetailFormHeader">
        <h1 className="SemiBold title">{t("auth.letKnow")}</h1>
        {profileImageLoading ? (
          <Spinner className={"SignUpGuestImageContainerLoading"} />
        ) : (
          <GuestProfileWithIcon
            image={formData.profilePic}
            hasImage={!!formData.profilePic}
            className="SignUpGuestImageContainer"
            handleOnClick={handleButtonClick}
            actionButton={
              formData.profilePic ? (
                <EditButton
                  className="SignUpGuestImageContainerEditButton"
                  type={ENUMS_EDIT_BUTTON.types.TYPE_B}
                  onClick={() => {}}
                />
              ) : (
                <AddTagButton
                  type={ENUMS_ADD_TAG_BUTTON.types.TYPE_B}
                  className="SignUpGuestProfileButton"
                  onClick={() => {}}
                />
              )
            }
          />
        )}
        <input
          type="file"
          accept="image/*"
          className="AddPhotoButtonFileInput"
          ref={fileInputRef}
          onChange={handleFileSelect}
        />
      </div>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="SignUpGuestDetailForm"
      >
        <Dropdown
          className={"SignUpGuestGenderDropdown"}
          onChange={handleGenderChange}
          placeholder={t("auth.gender")}
          options={Object.values(genderTypes).map((type) => {
            return {
              name: t(`auth.genders.${type}`),
              id: type,
            };
          })}
          value={formData?.gender}
          name="guestGender"
        />
        <InputControl
          className={"SignUpGuestGenderDateOfBirth"}
          type="date"
          placeholder={t("inputs.dateOfBirth")}
          name="dateOfBirth"
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          func={{
            ...register("dateOfBirth", {
              onChange: handleDate,
            }),
          }}
          value={formData.dateOfBirth}
        />
        <PrimaryButton
          onClick={handleSubmit(handleOnSubmit)}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
          text={t("auth.saveAndFinish")}
          isDisabled={profileImageLoading}
          isLoading={isUpdatingProfile}
          className="SignUpFooterButton"
        />
      </form>
    </div>
  );
};
SignUpDetailForm.propTypes = {
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    profilePic: PropTypes.string,
    gender: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    dateOfBirth: PropTypes.string,
  }).isRequired,
};

export default SignUpDetailForm;
