import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import RadioSelection, {
  ENUMS as RADIO_SELECTION_ENUMS,
} from "components/admin/forms/radio-selection/RadioSelection";

import "./RadioSelectOption.scss";

export const ENUMS = {
  name: "RadioSelectOption",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_COLOR: "TYPE_COLOR",
  },
};

const RadioSelectOption = ({
  id,
  name,
  imgSrc,
  onClick,
  className,
  isSelected,
  type = ENUMS.types.TYPE_A,
  color,
  isDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={() => onClick(id)}
      className={cx("AdminRadioSelectOption", {
        typeA: !type || type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
        typeColor: type === ENUMS.types.TYPE_COLOR,
        isDisabled,
        isSelected,
      })}
    >
      <div className="AdminRadioSelectOptionHeader">
        <RadioSelection
          onChange={() => onClick(id)}
          name={name}
          value={name}
          isChecked={isSelected}
          type={RADIO_SELECTION_ENUMS.types.TYPE_YELLOW}
        />
        {(!type || type === ENUMS.types.TYPE_A) && (
          <h6 className={cx("SemiBold", className)}>{name}</h6>
        )}
      </div>
      {(!type || type === ENUMS.types.TYPE_A) && (
        <img alt="optionImg" src={imgSrc} draggable={"false"} />
      )}
      <div className="AdminRadioSelectOptionLabel">
        {type === ENUMS.types.TYPE_B && (
          <img alt="optionImg" src={imgSrc} draggable={"false"} />
        )}
        {type === ENUMS.types.TYPE_COLOR && (
          <div
            className="RadioSelectOptionColorContainer"
            style={{ background: color }}
          ></div>
        )}
        {(type === ENUMS.types.TYPE_B || type === ENUMS.types.TYPE_COLOR) && (
          <h6 className={cx("Medium", className)}>{t(name)}</h6>
        )}
      </div>
    </div>
  );
};

RadioSelectOption.propTypes = {
  /**
   * The unique ID of the component
   */
  id: PropTypes.number.isRequired,

  /**
   * The name of the component
   */
  name: PropTypes.string,

  /**
   * The source for the component's image
   */
  imgSrc: PropTypes.string,

  /**
   * The function to be called when clicked
   */
  onClick: PropTypes.func.isRequired,

  /**
   * Additional class name for styling
   */
  className: PropTypes.string,

  /**
   * Indicates if the component is selected
   */
  isSelected: PropTypes.bool.isRequired,

  /**
   * The type of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * The color of the color option
   */
  color: PropTypes.string,

  /**
   * Disable state for the button
   */
  isDisabled: PropTypes.bool,
};

export default RadioSelectOption;
