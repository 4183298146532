import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { selectMenuView } from "redux/slices/guestStore";
import BasketButton from "components/buttons/basket-button/BasketButton";
import Footer from "components/elements/footer/Footer";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import { MENU_VIEW_ENUMS } from "utils/constants/data/base";
import useWindowSize from "utils/hooks/useWindowSize";
import { filterMenuByOptions, findMostExpensivePrice } from "utils/helpers";
import MenuHeroSection from "pages/client/menu/menu-hero-section/MenuHeroSection";
import MenuDisplay, {
  ENUMS as MENU_DISPLAY_ENUMS,
} from "pages/client/menu/menu-display/MenuDisplay";
import { ReactComponent as IconCart } from "assets/icons/basket/Cart.svg";
import WelcomeClient from "components/welcome-client/WelcomeClient";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";
import { findItemByIdForBasket } from "utils/general";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";

import "./Menu.scss";

const Menu = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.menu });
  const { size } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { businessId } = useParams();
  const { data: menu, isLoading } = useSelector(
    (state) => state[STORE_NAMES.menu]
  );
  const selectedMenuViewId = useSelector(
    (state) => state[STORE_NAMES.guest].selectedMenuView
  );
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const guests = basketOrders ? basketOrders.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);
  const orderItems = guest?.orderItems || [];

  const filteredOrderItems = orderItems.filter((orderItem) => {
    const menuItem =
      orderItem.item && findItemByIdForBasket(orderItem.item.id, menu);
    return menuItem?.isPublished && !menuItem?.isArchived;
  });

  const orderItemsCount = filteredOrderItems?.reduce(
    (sum, orderItem) => sum + orderItem.count,
    0
  );
  const { priceRange, selectedTags } = useSelector(
    (state) => state[STORE_NAMES.guest].menuFilters
  );

  const filteredMenu = filterMenuByOptions({
    menu,
    priceRange,
    selectedTags,
  });

  const handleBasketClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.basket}`
    );
  };
  const handleMenuViewSelection = (id) => {
    dispatch(selectMenuView(id));
  };

  if (isLoading) {
    return <WelcomeClient />;
  }
  return (
    <div className="Menu">
      <MenuHeroSection
        onClick={handleMenuViewSelection}
        currentOptionId={
          selectedMenuViewId ||
          menu.defaultMenuView ||
          MENU_VIEW_ENUMS[Object.keys(MENU_VIEW_ENUMS)[0]].id
        }
      />

      <MenuDisplay
        menuViewType={
          selectedMenuViewId ||
          menu.defaultMenuView ||
          MENU_VIEW_ENUMS[Object.keys(MENU_VIEW_ENUMS)[0]].id
        }
        mostExpensivePrice={findMostExpensivePrice(menu)}
        menu={filteredMenu}
        distanceLeft={16}
        type={MENU_DISPLAY_ENUMS.types.GUEST}
        scrollElement={
          size.width >= 1000 ? document.querySelector(".screen") : window
        }
      />
      <BasketButton
        onClick={handleBasketClick}
        svgComponent={<IconCart />}
        count={orderItemsCount}
      />
      <Footer
        scrollElement={
          size.width >= 1000 ? document.querySelector(".screen") : window
        }
      />
    </div>
  );
};
export default Menu;
