import React, { useState, useRef, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ICON_SEND from "assets/icons/arrows/arrowUp.svg";
import { ReactComponent as Microphone } from "assets/icons/record/microphone.svg";
import useOutsideClick from "utils/hooks/useOutsideClick";
import MicrophoneHintModal from "pages/client/magic-order/microphone-hint-modal/MicrophoneHintModal";

import "./SuperSearchInput.scss";

const SuperSearchInput = ({
  handleSaveSpeech,
  setTypedText,
  resultText,
  loadingAiSuperSearchMenu,
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  const [
    openSlideMicrophoneHintModal,
    setOpenSlideMicrophoneHintModal,
    mainElementRefMicrophoneHintModal,
  ] = useOutsideClick();

  useOutsideClick(containerRef, () => {
    if (isFocused) {
      setIsFocused(false);
    }
  });

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      const newHeight = Math.min(textarea.scrollHeight, 164);
      textarea.style.height = `${newHeight}px`;
    }
  };

  const handleInputChange = (e) => {
    setTypedText(e.target.value);
  };

  const isDisabledSendButton = resultText.length === 0;

  // const handleKeyDown = ({ key }) => {
  //   if (key === "Enter") {
  //     handleSaveSpeech({ prompt: resultText });
  //   }
  // };

  useEffect(() => {
    adjustHeight();
    setOpenSlideMicrophoneHintModal(false);
  }, [resultText.length]);

  const handleCloseModal = () => {
    setOpenSlideMicrophoneHintModal(false);
  };
  const handleOpenHintModal = () => {
    setIsFocused(true);
    setOpenSlideMicrophoneHintModal(true);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  return (
    <>
      <div className="AdvanceInputBox" ref={containerRef}>
        {isFocused && (
          <textarea
            name="talkToMe"
            ref={textareaRef}
            className="AdvanceTextArea"
            value={resultText}
            onChange={handleInputChange}
            placeholder={t("funZone.wheelOfFortune.typeHere")}
            autoFocus
            onBlur={() => resultText.length === 0 && setIsFocused(false)}
          />
        )}
        <div className="AdvanceInputToolsContainer">
          <div className="AdvanceInputTools">
            <h5
              className="AdvanceInputToolsPlaceholder Medium"
              onClick={() => {
                setIsFocused(true);
              }}
            >
              {isFocused || t("funZone.wheelOfFortune.typeHere")}
            </h5>
            <button
              className="AdvanceInputToolsRecordButton"
              onClick={handleOpenHintModal}
            >
              <Microphone />
            </button>
          </div>
          <button
            className="AdvanceInputToolsSubmitButton"
            type="button"
            onClick={() => {
              handleSaveSpeech({ prompt: resultText });
              setIsFocused(false);
            }}
            disabled={isDisabledSendButton || loadingAiSuperSearchMenu}
          >
            {!loadingAiSuperSearchMenu && <img src={ICON_SEND} alt="send" />}

            {loadingAiSuperSearchMenu && (
              <RotatingLines
                strokeColor="#000"
                height="20"
                width="20"
                strokeWidth="4"
              />
            )}
          </button>
        </div>
      </div>
      <MicrophoneHintModal
        openSlide={openSlideMicrophoneHintModal}
        handleCloseModal={handleCloseModal}
        mainElementRef={mainElementRefMicrophoneHintModal}
      />
    </>
  );
};
SuperSearchInput.propTypes = {
  handleSaveSpeech: PropTypes.func,
  setTypedText: PropTypes.func,
  resultText: PropTypes.string,
  loadingAiSuperSearchMenu: PropTypes.bool,
};
export default SuperSearchInput;
