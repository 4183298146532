import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { ELECTRON_CHANNEL, ElectronContext } from "electron/ElectronProvider";
import Modal from "components/modal/Modal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { updateBusinessAsync } from "redux/actions/businessAction";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { useTranslation } from "react-i18next";
import PrimaryButton, {
  ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";

import "./AdminPrinters.scss";

const printerStatus = {
  0: "Unknown",
  1: "Paused",
  2: "Error",
  3: "Ready",
  4: "Printing",
  5: "Warming Up",
  6: "Offline",
  7: "Paper Jam",
  8: "Out of Paper",
  9: "Out of Toner",
  10: "Cover Open",
};

const AdminPrinters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    business,
    thunkAPIStates: { updateBusiness },
  } = useSelector((state) => state[STORE_NAMES.business]);
  const { electronAPI } = useContext(ElectronContext);
  const [openSlide, setOpenSlide, mainElementRef] = useOutsideClick();
  const [
    openSlideDeletePrinter,
    setOpenSlideDeletePrinter,
    mainElementRefDeletePrinter,
  ] = useOutsideClick();

  const [availablePrinters, setAvailablePrinters] = useState([]);

  const businessPrinters = useMemo(() => {
    return business?.printers?.length ? business.printers : [];
  }, [business?.printers]);

  const [selectedPrinter, setSelectedPrinter] = useState({});

  useEffect(() => {
    electronAPI?.ipcRenderer.send(ELECTRON_CHANNEL.outbound.getPrinters);

    electronAPI?.ipcRenderer.on(
      ELECTRON_CHANNEL.inbound.printersList,
      (event, printers) => {
        setAvailablePrinters(printers);
      }
    );

    return () => {
      electronAPI?.ipcRenderer.removeAllListeners(
        ELECTRON_CHANNEL.outbound.printersList
      );
    };
  }, []);

  const handleSelectPrinter = (printData) => {
    setOpenSlide(true);
    setSelectedPrinter(printData);
  };

  const handleClickConnectedPrinter = (printData) => {
    setOpenSlideDeletePrinter(true);
    setSelectedPrinter(printData);
  };

  const handleAddPrinter = async () => {
    const response = await dispatch(
      updateBusinessAsync({
        business: { printers: [...businessPrinters, selectedPrinter] },
        id: business.id,
      })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    } else {
      handleOnAsyncSuccess(t("toastMessages.success.updateBusinessProfile"));
    }
  };

  const handleDeletePrinter = async () => {
    const response = await dispatch(
      updateBusinessAsync({
        business: {
          printers: businessPrinters.filter(
            (printer) => printer !== selectedPrinter
          ),
        },
        id: business.id,
      })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    } else {
      setOpenSlideDeletePrinter(false);
      handleOnAsyncSuccess(t("toastMessages.success.updateBusinessProfile"));
    }
  };

  const handleTestPrinter = () => {
    electronAPI.ipcRenderer.send(ELECTRON_CHANNEL.outbound.testPrintRequest, {
      name: selectedPrinter,
    });
  };

  const AddPrinterModalHeader = (
    <div className="AddPrinterHeader">
      <h3>{selectedPrinter}</h3>
    </div>
  );

  const AddPrinterModalBody = (
    <div className="AddPrinterModalBody">
      <PrimaryButton
        onClick={handleAddPrinter}
        text={t("devices.addPrinter")}
        isLoading={updateBusiness}
        isDisabled={
          updateBusiness ||
          businessPrinters.some((printer) => printer === selectedPrinter)
        }
      />
      <PrimaryButton
        text={t("devices.testPrinter")}
        onClick={handleTestPrinter}
      />
    </div>
  );

  const DeletePrinterModalBody = (
    <div className="AddPrinterModalBody">
      <PrimaryButton
        onClick={handleDeletePrinter}
        text={t("devices.deletePrinter")}
        isLoading={updateBusiness}
        isDisabled={updateBusiness}
        type={ENUMS.types.TYPE_C}
      />
    </div>
  );

  return (
    <div className="AdminPrinters">
      <div className="AdminConnectedPrinters">
        <h3>{t("devices.connectedDevices")}:</h3>
        <ul>
          {businessPrinters.length > 0 ? (
            businessPrinters?.map((printerName, index) => (
              <li
                key={index}
                onClick={() => handleClickConnectedPrinter(printerName)}
              >
                {printerName}
              </li>
            ))
          ) : (
            <p className="h7">{t("devices.notFound")}</p>
          )}
        </ul>
      </div>
      <div className="AdminAvailablePrinters">
        <h3>{t("devices.availableDevices")}:</h3>
        <ul>
          {availablePrinters?.length > 0 ? (
            availablePrinters?.map((printer, index) => (
              <li key={index} onClick={() => handleSelectPrinter(printer.name)}>
                <p>{printer.name}</p>
                <p>Status: {printerStatus[printer.status]}</p>
              </li>
            ))
          ) : (
            <p className="h7">{t("devices.notFound")}</p>
          )}
        </ul>
      </div>
      <Modal
        header={AddPrinterModalHeader}
        body={AddPrinterModalBody}
        mainElementRef={mainElementRef}
        openSlide={openSlide}
      />
      <Modal
        header={AddPrinterModalHeader}
        body={DeletePrinterModalBody}
        mainElementRef={mainElementRefDeletePrinter}
        openSlide={openSlideDeletePrinter}
      />
    </div>
  );
};

export default AdminPrinters;
