import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { createDOBucketName } from "utils/DO-Spaces";
import BackgroundImg from "assets/images/game/memory-card-game/cardBackground.svg";

import "./MemoryCard.scss";

const MemoryCard = ({
  card,
  handleChoice,
  isFlipped,
  interactionEnabled,
  gameIsStarted,
  index,
}) => {
  const handleClick = () => {
    if (interactionEnabled) {
      handleChoice(card);
    }
  };

  return (
    <div
      id={card.id}
      className={cx("MemoryCard", { isFlipped })}
      style={{
        animationDelay: gameIsStarted ? "0s" : `${(index * 0.1).toFixed(1)}s`,
      }}
    >
      <img
        src={createDOBucketName(card.src)}
        alt="front"
        className="FrontFace"
      />
      <img
        src={BackgroundImg}
        alt="background"
        onClick={handleClick}
        className="BackFace"
      />
    </div>
  );
};
MemoryCard.propTypes = {
  card: PropTypes.object,
  gameIsStarted: PropTypes.bool,
  handleChoice: PropTypes.func,
  index: PropTypes.number,
  interactionEnabled: PropTypes.bool,
  isFlipped: PropTypes.bool,
};
export default MemoryCard;
