import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { ROLES } from "../constants/global";
import { STORE_NAMES } from "../constants/redux";
import PropTypes from "prop-types";

const AdminPermissionContext = createContext();

/**
 * Defines intent types for various operations.
 * @type {{orderDelete: string, declineOrderItems: string, deleteMenuItems: string, editMenuEnabled: string}}
 */
export const INTENT = {
  orderDelete: "ORDER_DELETE",
  declineOrderItems: "DECLINE_ORDER_ITEMS",
  deleteMenuItems: "DELETE_MENU_ITEMS",
  editMenuEnabled: "EDIT_MENU_ENABLED",
};

/**
 * Maps prohibited intents to specific roles.
 * @type {{[key: string]: string[]}}
 * @see {@link INTENT} for available intents.
 */
export const INTENT_PROHIBITED = {
  [INTENT.orderDelete]: [ROLES.staff.name],
  [INTENT.editMenuEnabled]: [ROLES.staff.name],
  [INTENT.declineOrderItems]: [ROLES.staff.name],
  [INTENT.deleteMenuItems]: [ROLES.staff.name],
};

const AdminPermissionContextProvider = ({ children }) => {
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const { user, userWithPin } = useSelector((state) => state[STORE_NAMES.user]);
  const currentUser = userWithPin || user;

  const isPermitted = (intent) => {
    if (!currentUser || !currentUser.roles) return false;

    switch (intent) {
      case INTENT.orderDelete:
        if (business.deleteOrderEnabled) {
          return true;
        }
        return !INTENT_PROHIBITED[intent]?.includes(currentUser.roles[0].name);
      case INTENT.declineOrderItems:
        if (business.declineOrderItemsEnabled) {
          return true;
        }
        return !INTENT_PROHIBITED[intent]?.includes(currentUser.roles[0].name);
      case INTENT.editMenuEnabled:
        if (business.editMenuEnabled) {
          return true;
        }
        return !INTENT_PROHIBITED[intent]?.includes(currentUser.roles[0].name);
      case INTENT.deleteMenuItems:
        if (business.deleteMenuItemsEnabled) {
          return true;
        }
        return !INTENT_PROHIBITED[intent]?.includes(currentUser.roles[0].name);

      default:
        return true;
    }
  };

  return (
    <AdminPermissionContext.Provider value={{ isPermitted }}>
      {children}
    </AdminPermissionContext.Provider>
  );
};
AdminPermissionContextProvider.propTypes = {
  children: PropTypes.node,
};

export const usePermission = () => {
  return useContext(AdminPermissionContext);
};

export default AdminPermissionContextProvider;
