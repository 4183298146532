import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Draggable } from "react-beautiful-dnd";

import "./DraggableCard.scss";

export const ENUMS = {
  name: "DraggableCard",
  types: {
    SIZE_S: "S",
    SIZE_L: "L",
  },
};

const DraggableCard = ({
  sizeVariant,
  index,
  bodyComponent,
  headerComponent,
  draggableId,
  canDragWholeCard,
  isLoadingUpdateMenu,
}) => {
  const [isHeaderHovered, setIsHeaderHovered] = useState(false);
  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      isDragDisabled={isLoadingUpdateMenu}
    >
      {(provided, snapshot) => (
        <div
          className={cx("DraggableCardContainer", {
            isDragging: snapshot.isDragging,
            isHeaderHovered,
          })}
          {...provided.draggableProps}
          {...(canDragWholeCard && !isLoadingUpdateMenu
            ? provided.dragHandleProps
            : {})}
          ref={provided.innerRef}
        >
          <div
            className={cx("DraggableCardHeader", {
              sizeS: sizeVariant === ENUMS.types.SIZE_S,
              sizeL: sizeVariant === ENUMS.types.SIZE_L,
            })}
            {...(isLoadingUpdateMenu ? {} : provided.dragHandleProps)}
            onMouseEnter={() => setIsHeaderHovered(true)}
            onMouseLeave={() => setIsHeaderHovered(false)}
            onTouchStart={() => setIsHeaderHovered(true)}
            onTouchEnd={() => setIsHeaderHovered(false)}
          >
            {headerComponent}
          </div>

          {bodyComponent && (
            <div className="DraggableCardContent">{bodyComponent}</div>
          )}
        </div>
      )}
    </Draggable>
  );
};

DraggableCard.propTypes = {
  /**
   * The body component of the card
   * */
  bodyComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),

  /**
   * The header component of the card
   * */
  headerComponent: PropTypes.element,

  /**
   * The index of the card
   * */
  index: PropTypes.number.isRequired,

  /**
   * The draggable id of the card
   * */
  draggableId: PropTypes.string.isRequired,

  /**
   * The size variant of the DraggableCard
   * */
  sizeVariant: PropTypes.oneOf(Object.values(ENUMS.types)).isRequired,

  /**
   * Whether the whole card should be draggable
   * */
  canDragWholeCard: PropTypes.bool,

  /**
   * Whether the card is loading
   * */
  isLoadingUpdateMenu: PropTypes.bool,
};

export default DraggableCard;
