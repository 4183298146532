import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { SIGN_IN_METHOD } from "../sign-in/SignIn";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import Stepper from "components/stepper/Stepper";
import Steps from "components/stepper/Steps/Steps";
import StepContent from "components/stepper/step-content/StepContent";
import SignUpForm from "./sign-up-form/SignUpForm";
import SetUserPin from "./set-user-pin/SetUserPin";
import { resetAllReduxStores } from "utils/general";
import { ROUTE_NAME } from "utils/constants/routes";
import Login from "components/admin/elements/login/Login";
import {
  createBusinessAndAssignToOwner,
  signupOwnerAndCreateBusiness,
} from "redux/actions/userAction";
import { handleOnAsyncError } from "utils/helpers";
import SignUpMethod from "./sign-up-method/SignUpMethod";
import useCountdown from "utils/hooks/useCountdown";
import ConfirmOtpForSignup from "./confirm-otp/ConfirmOtpForSignup";
import { setBusiness } from "redux/slices/businessStore";

import "./SignUp.scss";

const SignUp = () => {
  const { t } = useTranslation();
  const schemas = useValidationSchema(t);

  const [signInMethod, setSignInMethod] = useState(SIGN_IN_METHOD.phoneNumber);
  const [isUserExist, setIsUserExist] = useState(false);
  const [counter, setCounter] = useCountdown(0);
  const [otpData, setOtpData] = useState({
    otpCode: "",
    otpToken: "",
    otpSendCount: 3,
    email: "",
    phoneNumber: "",
    userId: null,
  });
  const signUpSchema = schemas.signUpSchema(isUserExist);
  const methods = useForm({
    resolver: zodResolver(signUpSchema),
  });

  const {
    getValues,
    setValue,
    formState: { errors },
    trigger,
  } = methods;

  useEffect(() => {
    Object.keys(errors).length > 0 && trigger();
  }, [t]);

  const dispatch = useDispatch();

  const STEP_TYPES = {
    signupMethod: "signupMethod",
    otpVerification: "otpVerification",
    userDetail: "userDetails",
    setPinCode: "pinCode",
  };

  const steps = [
    {
      title: "stepper.signupMethod",
      subTitle: t("login.signUp.alreadyHaveAccount"),
      type: STEP_TYPES.signupMethod,
    },
    {
      title: "stepper.otpVerification",
      subTitle: t("login.signUp.passwordSentTo"),
      type: STEP_TYPES.otpVerification,
    },
    {
      title: "stepper.userDetail",
      subTitle: t("login.signUp.setPinSubTitle"),
      type: STEP_TYPES.userDetail,
    },
    {
      title: "stepper.setPinCode",
      subTitle: t("login.signUp.setPinSubTitle"),
      type: STEP_TYPES.setPinCode,
    },
  ];
  const [activeStep, setActiveStep] = useState(steps[0]);

  const handleNext = () => {
    const currentIndex = steps.findIndex(
      (step) => step.title === activeStep.title
    );
    setActiveStep(steps[currentIndex + 1]);
  };

  const handlePrev = () => {
    const currentIndex = steps.findIndex(
      (step) => step.title === activeStep.title
    );
    setActiveStep(steps[currentIndex - 1]);
  };

  useEffect(() => {
    resetAllReduxStores(dispatch);
  }, []);

  const handleSignupFormSubmit = async () => {
    const formData = getValues();
    delete formData.agreeTerms;
    delete formData.rememberMe;
    try {
      let response;
      if (isUserExist) {
        response = await dispatch(
          createBusinessAndAssignToOwner({
            userData: {
              planId: formData.plan.id,
              businessName: formData.businessName,
            },
            userId: otpData.userId,
            otpToken: otpData.otpToken,
          })
        );
      } else {
        response = await dispatch(
          signupOwnerAndCreateBusiness({
            userData: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              planId: formData.plan.id,
              businessName: formData.businessName,
              password: formData.password,
            },
            otpToken: otpData.otpToken,
          })
        );
      }
      if (response.error) {
        handleOnAsyncError();
      } else {
        setIsUserExist(false);
        dispatch(setBusiness(response.payload.user.business));
        handleNext();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderStepContent = () => {
    switch (activeStep.type) {
      case STEP_TYPES.signupMethod:
        return (
          <SignUpMethod
            setCounter={setCounter}
            handleNext={handleNext}
            setOtpData={setOtpData}
            setSignInMethod={setSignInMethod}
            signInMethod={signInMethod}
          />
        );
      case STEP_TYPES.otpVerification:
        return (
          <ConfirmOtpForSignup
            handleNext={handleNext}
            signInMethod={signInMethod}
            otpData={otpData}
            setOtpData={setOtpData}
            setCounter={setCounter}
            counter={counter}
            setIsUserExist={setIsUserExist}
            setUserDetailFormValue={setValue}
          />
        );
      case STEP_TYPES.userDetail:
        return (
          <FormProvider {...methods}>
            <SignUpForm
              handleSignupFormSubmit={handleSignupFormSubmit}
              isUserExist={isUserExist}
              formData={getValues()}
            />
          </FormProvider>
        );
      default:
        return <SetUserPin />;
    }
  };

  return (
    <div className="SignUp">
      <Login
        title={t("login.signUp.title")}
        subTitle={
          activeStep.type === STEP_TYPES.signupMethod
            ? t("login.signUp.alreadyHaveAccount")
            : null
        }
        currentStep={activeStep.title}
        link={
          activeStep.type === STEP_TYPES.signupMethod
            ? t("login.signIn.title")
            : null
        }
        linkHref={ROUTE_NAME.signIn}
        hasSSO
        handlePrev={handlePrev}
        isPrevButtonVisible={activeStep.type === STEP_TYPES.otpVerification}
      >
        <Stepper>
          <Steps steps={steps} activeStep={activeStep} />
          <StepContent activeStep={activeStep}>
            {renderStepContent()}
          </StepContent>
        </Stepper>
      </Login>
    </div>
  );
};

export default SignUp;
