import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useNavigate, useParams } from "react-router-dom";

import BackButton from "assets/icons/arrows/ArrowLeftWhite.svg";
import { ROUTE_NAME } from "utils/constants/routes";
import Refresh from "assets/icons/memory-card-game/refresh.svg";
import useOutsideClick from "utils/hooks/useOutsideClick";
import TicTacToeModal from "pages/client/fun-zone/games/tic-tac-toe/tic-tac-toe-modal/TicTacToeModal";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";

import "./TicTacToeGame.scss";

const TicTacToeGame = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.ticTacToe });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessId } = useParams();
  const WIN_CONDITIONS = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  const [board, setBoard] = useState(Array(9).fill(null));
  const [xPlaying, setXPlaying] = useState(true);
  const [scores, setScores] = useState({ xScore: 0, oScore: 0 });
  const [winningBoxes, setWinningBoxes] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [openSlideTicTacToeGame, setOpenSlideTicTacToeGame] = useOutsideClick();

  const handleGoBack = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}`
    );
  };
  const checkWinner = (board) => {
    for (let i = 0; i < WIN_CONDITIONS.length; i++) {
      const [x, y, z] = WIN_CONDITIONS[i];
      if (board[x] && board[x] === board[y] && board[y] === board[z]) {
        setWinningBoxes([x, y, z]);
        setGameOver(true);
        return board[x];
      }
    }
    if (board.every((value) => value !== null)) {
      setGameOver(true);
      return "Draw";
    }
  };

  const handleBoxClick = (boxIndex) => {
    const updateBoard = board.map((value, index) => {
      if (boxIndex === index) {
        return xPlaying === true ? "X" : "O";
      } else {
        return value;
      }
    });
    const winner = checkWinner(updateBoard);
    if (winner && winner !== "Draw") {
      if (winner === "O") {
        let { oScore } = scores;
        oScore += 1;
        setScores({ ...scores, oScore });
      } else if (winner === "X") {
        let { xScore } = scores;
        xScore += 1;
        setScores({ ...scores, xScore });
      }
    }

    setBoard(updateBoard);
    setXPlaying(!xPlaying);
  };
  const resetBoard = () => {
    setGameOver(false);
    setBoard(Array(9).fill(null));
    setWinningBoxes([]);
  };

  useEffect(() => {
    if (gameOver) {
      if (winningBoxes.length > 0) {
        setOpenSlideTicTacToeGame(true);
      } else {
        setOpenSlideTicTacToeGame(true);
      }
    }
  }, [gameOver, winningBoxes.length]);

  const handleCloseModal = () => {
    setOpenSlideTicTacToeGame(false);
    resetBoard();
  };
  const { xScore, oScore } = scores;

  const TicTacToeGameHeader = (
    <div className="TicTacToeGameHeader">
      <div
        className="TicTacToeGameHeaderBackRefreshButton"
        onClick={handleGoBack}
      >
        <img src={BackButton} alt="BackButton" />
      </div>
      <h3 className="Bold">{t("funZone.ticTackToe.title")}</h3>
      <button
        className="TicTacToeGameHeaderBackRefreshButton"
        onClick={resetBoard}
      >
        <img src={Refresh} alt="Refresh" />
      </button>
    </div>
  );

  const TicTacToeGameScoreBoard = (
    <div className="TicTacToeGameScoreBoards">
      <div
        className={cx("TicTacToeGameScoreBoard", {
          isActive: xPlaying && !gameOver,
        })}
      >
        <div>
          <h2 className="Bold">X</h2>
          <h5 className="Bold">{t("funZone.ticTackToe.player")}</h5>
        </div>
        <div>
          <h2 className="SemiBold">{xScore}</h2>
          <h5 className="SemiBold">{t("funZone.ticTackToe.points")}</h5>
        </div>
      </div>
      <div
        className={cx("TicTacToeGameScoreBoard", {
          isActive: !xPlaying && !gameOver,
        })}
      >
        <div>
          <h2 className="Bold">O</h2>
          <h5 className="Bold">{t("funZone.ticTackToe.player")}</h5>
        </div>
        <div>
          <h2 className="SemiBold">{oScore}</h2>
          <h5 className="SemiBold">{t("funZone.ticTackToe.points")}</h5>
        </div>
      </div>
    </div>
  );

  return (
    <div className="TicTacToeGame">
      {TicTacToeGameHeader}
      {TicTacToeGameScoreBoard}
      <div className="TicTacToeGameBody">
        {board.map((value, index) => {
          return (
            <div
              key={index}
              className={cx("TicTacToeGameBodyBox", {
                isWinnerBox: winningBoxes.includes(index),
              })}
              onClick={() => {
                value === null && !gameOver && handleBoxClick(index);
              }}
            >
              {value}
            </div>
          );
        })}
      </div>
      <TicTacToeModal
        onCancel={handleCloseModal}
        openSlide={openSlideTicTacToeGame}
        xPlaying={xPlaying}
        gameOver={gameOver}
        scores={scores}
        winningBoxes={winningBoxes}
      />
    </div>
  );
};

export default TicTacToeGame;
