import React, { useEffect } from "react";

import SignUpStepper from "pages/client/auth/sign-up/sign-up-stepper/SignUpStepper";
import useLogoutGuest from "utils/hooks/useGuestLogout";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";

import "./SignUp.scss";

const SignUp = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.signUp });
  const { logoutGuest } = useLogoutGuest();

  useEffect(() => {
    logoutGuest();
  }, []);

  return (
    <div className="GuestAuthSignUp">
      <SignUpStepper />
    </div>
  );
};

export default SignUp;
