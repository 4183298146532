import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Modal from "components/modal/Modal";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import HintForApple from "assets/images/hint/HintForApple.png";
import HintForSamsung from "assets/images/hint/HintForSamsung.png";

import "./MicrophoneHintModal.scss";

const checkIfIPhone = () => {
  const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;
  return /iPhone/i.test(userAgent);
};

const MicrophoneHintModal = ({
  openSlide,
  mainElementRef,
  handleCloseModal,
}) => {
  const { t } = useTranslation();
  const [isIPhone, setIsIPhone] = useState(false);

  useEffect(() => {
    setIsIPhone(checkIfIPhone());
  }, []);

  const MicrophoneHintModalHeader = (
    <div className="MicrophoneHintModalHeader">
      <h5 className="MicrophoneHintModalTitle">{t("auth.info")}</h5>
      <CloseButton
        onClick={handleCloseModal}
        type={ENUMS_CLOSE_BUTTON.types.TYPE_M}
      />
    </div>
  );

  const MicrophoneHintModalBody = (
    <div className="MicrophoneHintModalBody">
      <img src={isIPhone ? HintForApple : HintForSamsung} alt="HintForApple" />
    </div>
  );
  const MicrophoneHintModalFooter = (
    <div className="MicrophoneHintModalFooter">
      <h5 className="Medium">{t("record.youCanUseMicrophone")}</h5>
    </div>
  );
  return (
    <Modal
      header={MicrophoneHintModalHeader}
      body={MicrophoneHintModalBody}
      footer={MicrophoneHintModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};
MicrophoneHintModal.propTypes = {
  openSlide: PropTypes.bool,
  mainElementRef: PropTypes.object,
  handleCloseModal: PropTypes.func,
};
export default MicrophoneHintModal;
