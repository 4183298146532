import React, { useContext } from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { AdminWebsocketContextProvider } from "utils/context-api/AdminWebsocketContext";
import "react-device-frameset/styles/marvel-devices.min.css";
import "react-device-frameset/styles/device-emulator.min.css";

import AdminController from "pages/admin/AdminController";
import Info from "pages/common/info/Info";
import useGeoLocation from "utils/hooks/useGeoLocation";
import useCssRootVars from "utils/hooks/useCssRootVars";
import useProtection from "utils/hooks/useProtection";
import useGeneralMetaDataUpdater from "utils/hooks/useGeneralMetaDataUpdater";
import { ROUTE_NAME } from "utils/constants/routes";
import useReduxVersioning from "utils/hooks/useReduxVersioning";
import AppRouterGuest from "pages/AppRouterGuest";
import SignIn from "pages/common/login/sign-in/SignIn";
import SignUp from "pages/common/login/sign-up/SignUp";
import LockScreen from "pages/admin/admin-pages/lock-screen/LockScreen";
import ForgotPassword from "pages/common/login/forgot-password/ForgotPassword";
import ResetPassword from "pages/common/login/reset-password/ResetPassword";
import RouteTopScroller from "components/route-top-scroller/RouteTopScroller";
import useLanguageI18n from "../utils/hooks/useLanguageI18n";
import useRouterManager from "utils/hooks/useRouteManager";
import PrivacyPolicy from "pages/common/legal/privacy-policy/PrivacyPolicy";
import TermsAndCondition from "pages/common/legal/terms-and-condition/TermsAndCondition";
import LandingPage from "pages/common/main-page/LandingPage";
import MixpanelProvider from "utils/context-api/MixpanelContext";
import { ElectronContext } from "electron/ElectronProvider";
import AdminPermissionContextProvider from "utils/context-api/AdminPermissionContext";

import "./AppRouter.scss";

const AppRouterAdmin = () => {
  useRouterManager();
  return (
    <AdminWebsocketContextProvider>
      <AdminPermissionContextProvider>
        <Routes>
          <Route path={ROUTE_NAME.any} element={<AdminController />} />
          <Route path={ROUTE_NAME.lockScreen} element={<LockScreen />} />
        </Routes>
      </AdminPermissionContextProvider>
    </AdminWebsocketContextProvider>
  );
};

const AppRouter = () => {
  useGeoLocation();
  useCssRootVars();
  useProtection();
  useReduxVersioning();
  useGeneralMetaDataUpdater();
  useLanguageI18n();
  const { isElectron } = useContext(ElectronContext);

  const history = (children) =>
    isElectron ? (
      <HashRouter>{children}</HashRouter>
    ) : (
      <BrowserRouter>{children}</BrowserRouter>
    );

  return (
    <div className="AppRouter">
      <MixpanelProvider>
        {history(
          <RouteTopScroller>
            <Routes>
              <Route
                path={`${ROUTE_NAME.admin}/*`}
                element={<AppRouterAdmin />}
              />
              <Route
                path={`${ROUTE_NAME.client}/*`}
                element={<AppRouterGuest />}
              />
              <Route path={ROUTE_NAME.signIn} element={<SignIn />} />
              <Route path={ROUTE_NAME.signUp} element={<SignUp />} />
              <Route
                path={ROUTE_NAME.forgotPassword}
                element={<ForgotPassword />}
              />
              <Route
                path={ROUTE_NAME.resetPassword}
                element={<ResetPassword />}
              />
              <Route path={ROUTE_NAME.info} element={<Info />} />
              {/*<Route*/}
              {/*  path={ROUTE_NAME.underConstruction}*/}
              {/*  element={<UnderConstruction />}*/}
              {/*/>*/}
              {/*<Route path="*" element={<UnderConstruction />} />*/}
              <Route
                path={ROUTE_NAME.underConstruction}
                element={<LandingPage />}
              />
              <Route
                path={`${ROUTE_NAME.legal}${ROUTE_NAME.privacyPolicy}`}
                element={<PrivacyPolicy />}
              />
              <Route
                path={`${ROUTE_NAME.legal}${ROUTE_NAME.termsAndCondition}`}
                element={<TermsAndCondition />}
              />
              <Route path="*" element={<LandingPage />} />
            </Routes>
          </RouteTopScroller>
        )}
      </MixpanelProvider>
    </div>
  );
};

export default AppRouter;
