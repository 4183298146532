import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateBasket } from "redux/slices/basketStore";
import { STORE_NAMES } from "utils/constants/redux";

const useCheckAndDeleteItemsFromBasket = () => {
  const dispatch = useDispatch();
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const guests = basketOrders ? basketOrders.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);
  const orderItems = guest?.orderItems || [];

  useEffect(() => {
    const menuItems = menu?.categories
      ?.filter((category) => category.isPublished && !category.isArchived)
      .map((category) => category.menuItems.flat())
      .flat();

    orderItems.map(({ item }) => {
      const findItem = menuItems.find(
        (menuItem) =>
          menuItem.id === item.id &&
          menuItem.isPublished &&
          !menuItem.isArchived
      );

      if (!findItem) {
        dispatch(
          updateBasket({
            menuItem: {
              ...item,
            },
            count: null,
            userId: guestId,
          })
        );
      }
    });
  }, [menu]);
};

export default useCheckAndDeleteItemsFromBasket;
