import React, { memo } from "react";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import TypingEffect from "components/typing-effect/TypingEffect";
import { QUERY_PARAMS } from "utils/constants/routes";
import { ReactComponent as SpinWheel } from "assets/images/dashboard-order-status/SpinnerWheel.svg";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import {
  MP_EVENTS,
  MP_GAME_NAMES,
  MP_PROP_NAMES,
} from "utils/constants/mixpanel";


import "./WheelOfFortuneBanner.scss";

const wheelClickHandler = ({ setSearchParams, searchParams }) => {
  setSearchParams({
    ...Object.fromEntries(searchParams),
    [QUERY_PARAMS.showWheelOfFortune]: true,
  });
  document.body.classList.add("no-scroll");
};

const WheelOfFortuneBanner = ({ texts }) => {
  const { trackMixpanel } = useMixpanel();
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  return (
    <div
      className="WheelOfFortuneBanner"
      onClick={() => {
        wheelClickHandler({ searchParams, setSearchParams });
        trackMixpanel(`${MP_EVENTS.gameSelected}`, {
          [MP_PROP_NAMES.gameName]: MP_GAME_NAMES.who,
        });
      }}
    >
      <div className="WheelOfFortuneBannerContent">
        <p className="WheelOfFortuneBannerContentTitle">
          {t("funZone.wheelOfFortune.who")}
        </p>
        <TypingEffect
          texts={texts}
          textContainer={(displayText) => {
            return <p className="TypingText">{displayText}</p>;
          }}
        />
      </div>
      <div className="WheelOfFortuneBannerSpinner">
        <div className="WheelOfFortuneBannerSpinnerImage">
          <SpinWheel />
        </div>
      </div>
    </div>
  );
};

WheelOfFortuneBanner.propTypes = {
  title: PropTypes.string,
  texts: PropTypes.arrayOf(PropTypes.string),
};

export default memo(WheelOfFortuneBanner);
