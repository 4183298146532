import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import useWindowSize, { BREAKPOINT_NAMES } from "utils/hooks/useWindowSize";

import "./Modal.scss";

export const TYPES = {
  DARK: "DARK",
  BLUR: "BLUR",
};

const Modal = ({
  type = TYPES.DARK,
  header,
  body,
  footer,
  mainElementRef,
  openSlide,
  extraComponentContent,
  className,
}) => {
  const { isScreenSize } = useWindowSize();
  const isMobile = isScreenSize(BREAKPOINT_NAMES.mobile);
  const isTablet = isScreenSize(BREAKPOINT_NAMES.tablet);
  const shouldConsiderOpenSlide = openSlide !== undefined;
  const [isVisible, setIsVisible] = useState(
    shouldConsiderOpenSlide ? openSlide : true
  );

  useEffect(() => {
    if (shouldConsiderOpenSlide) {
      if (openSlide) {
        setIsVisible(true);
        if (isTablet) document.body.classList.add("OverflowHidden");
      } else {
        if (isMobile) {
          setTimeout(() => {
            setIsVisible(false);
          }, 500);
        } else {
          setIsVisible(false);
        }
      }
    }
    return () => document.body.classList.remove("OverflowHidden");
  }, [openSlide]);

  return (
    isVisible && (
      <div
        className={cx("ModalBackground", className, {
          isDark: type === TYPES.DARK,
          isBlur: type === TYPES.BLUR,
        })}
      >
        <div
          ref={mainElementRef}
          className={cx("ModalContainer", {
            hasExtraComponentContent: extraComponentContent,
          })}
        >
          {extraComponentContent && extraComponentContent}
          <div
            className={cx("Modal", {
              hasOnlyHeader: header && !footer,
              hasOnlyFooter: !header && footer,
              hasNoHeaderAndFooter: !header && !footer,
              isHidden: shouldConsiderOpenSlide ? !openSlide : false,
            })}
          >
            {header && <div className="ModalHeader">{header}</div>}
            <div className="ModalBody">
              {body}
              {footer && <div className="ModalFooter">{footer}</div>}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

Modal.propTypes = {
  /**
   * The type of the modal (DARK or BLUR)
   */
  type: PropTypes.oneOf([TYPES.BLUR, TYPES.DARK]),

  /**
   * The header content of the modal
   */
  header: PropTypes.node,

  /**
   * The body content of the modal
   */
  body: PropTypes.node,

  /**
   * The footer content of the modal
   */
  footer: PropTypes.node,

  /**
   * The ref for the main element of the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The extra component content to be rendered in the modal
   */
  extraComponentContent: PropTypes.node,
  /**
   * Adding classname for modal component
   */
  className: PropTypes.string,
};

export default Modal;
