import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { SHAPE_ENUMS } from "utils/constants/data/base";

import "./ReservationMapZoneTable.scss";

const ReservationMapZoneTable = ({
  tableData,
  onClickTable,
  selectedTable,
}) => {
  const { id, x, y, width, height, name, shape, zoneId } = tableData;
  return (
    <div
      className={cx("ReservationMapZoneTable", {
        isRectangle: shape === SHAPE_ENUMS.RECTANGLE,
        isCircle: shape === SHAPE_ENUMS.CIRCLE,
        isSelected: parseInt(id) === selectedTable?.id,
      })}
      onClick={() =>
        onClickTable({
          id,
          zoneId,
        })
      }
      style={{
        position: "absolute",
        left: `${x}px`,
        top: `${y}px`,
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <h6 className="ReservationMapZoneTableName h8">{name}</h6>
    </div>
  );
};

ReservationMapZoneTable.propTypes = {
  tableData: PropTypes.object,
  onClickTable: PropTypes.func,
  selectedTable: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default ReservationMapZoneTable;
