import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ICON_CROWN from "assets/icons/mascot/CrownSmall.svg";
import useLanguage from "utils/hooks/useLanguage";

import "./Category.scss";

export const ENUMS = {
  name: "Category",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const Category = ({
  type = ENUMS.types.TYPE_A,
  activeCategoryId,
  items,
  onClick,
  isMultiLanguage,
  distanceLeft,
  isOrderSection,
}) => {
  const categoryHeaderRef = useRef(null);
  const activeCategoryRef = useRef(null);

  const { displayDataByLanguage } = useLanguage();
  const updatedItems = isMultiLanguage
    ? items.map((item) => ({
        ...item,
        name: displayDataByLanguage(item.name),
      }))
    : items;

  useEffect(() => {
    const categoryHeaderRefWidth = categoryHeaderRef.current.clientWidth;
    const distanceActiveCategoryFromLeft =
      activeCategoryRef.current?.offsetLeft;
    const activeCategoryWidth = activeCategoryRef.current?.clientWidth;
    categoryHeaderRef.current.scroll({
      left:
        distanceActiveCategoryFromLeft -
        (categoryHeaderRefWidth - (activeCategoryWidth - 16)) / 2,
      behavior: "smooth", // You can add smooth scrolling if needed
    });
  }, [activeCategoryId]);

  return (
    <div
      className={cx("Category", {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
      })}
      style={!isOrderSection ? { paddingLeft: `${distanceLeft}px` } : null}
      ref={categoryHeaderRef}
    >
      {updatedItems.map((item) => (
        <h6
          key={item.id}
          // id={item.id}
          data-id={item.id}
          onClick={() => onClick(item)}
          ref={activeCategoryId === item.id ? activeCategoryRef : null}
          className={cx("Medium CategoryItem", {
            isActive: activeCategoryId === item.id,
            isMascotSelection: item?.isMascotSelection,
          })}
        >
          {item?.isMascotSelection && (
            <img className="CategoryCrownImg" src={ICON_CROWN} alt="crown" />
          )}
          {item.name}
        </h6>
      ))}
    </div>
  );
};

Category.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * The active item of the component
   */
  activeCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * The items of the component
   */
  items: PropTypes.array.isRequired,

  /**
   * Click event when click selection
   */
  onClick: PropTypes.func.isRequired,

  /**
   * The distance left for padding
   */
  distanceLeft: PropTypes.number,

  /**
   * Flag to determine if the category supports multiple languages
   */
  isMultiLanguage: PropTypes.bool,

  isOrderSection: PropTypes.bool,
};

export default Category;
