import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { STORE_NAMES } from "utils/constants/redux";
import EditIcon from "assets/icons/edit/Edit.svg";
import { getZonesAsync } from "redux/actions/zoneAction";
import useOutsideClick from "utils/hooks/useOutsideClick";
import EditZoneModal from "../edit-zone-modal/EditZoneModal";

import "./AdminMapHeader.scss";

const AdminMapHeader = ({
  handleSave,
  selectedZone,
  setSelectedZone,
  hasUnsavedTableChanges,
}) => {
  const { t } = useTranslation();
  const handleZoneChange = (zone) => {
    zone && setSelectedZone(zone);
  };
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const dispatch = useDispatch();

  const zones = useSelector((state) => state[STORE_NAMES.zones].zones);

  useEffect(() => {
    dispatch(getZonesAsync({ businessId }));
  }, []);

  const [, setOpenSlide] = useOutsideClick();
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  const [
    openSlideZone,
    setOpenSlideZone,
    mainElementRefZone,
    ,
    ,
    setOutsideClickAction,
  ] = useOutsideClick(() => {
    if (isDropdownDisabled) {
      setOpenSlide(true);
    }
  });

  const handleOnEditZone = () => {
    setOpenSlideZone(true);
    setIsDropdownDisabled(false);
  };
  return (
    <div className="AdminMapHeader">
      <div className="AdminMapHeaderZone">
        <Dropdown
          onChange={handleZoneChange}
          name="zones"
          value={selectedZone}
          options={zones}
          placeholder={t("zone.zones")}
        />
        {selectedZone && (
          <PrimaryButton
            onClick={handleOnEditZone}
            type={PRIMARY_BUTTON_ENUMS.types.TYPE_D}
            icon={EditIcon}
            text={t("buttons.editZone")}
          />
        )}
      </div>
      {hasUnsavedTableChanges && (
        <PrimaryButton
          className="AdminMapContentSaveButton"
          onClick={handleSave}
        />
      )}

      <EditZoneModal
        mainElementRef={mainElementRefZone}
        setOpenSlide={setOpenSlideZone}
        title={t("zone.zone")}
        selectedZone={selectedZone}
        setSelectedZone={setSelectedZone}
        openSlide={openSlideZone}
        setOutsideClickAction={setOutsideClickAction}
      />
    </div>
  );
};

AdminMapHeader.propTypes = {
  handleSave: PropTypes.func,
  hasUnsavedTableChanges: PropTypes.bool,
  selectedZone: PropTypes.object,
  setSelectedZone: PropTypes.func,
};

export default AdminMapHeader;
