import { useEffect } from "react";
import { useSelector } from "react-redux";
import i18n, { changeLanguage } from "i18next";

import { STORE_NAMES } from "utils/constants/redux";

const useLanguageI18n = () => {
  const userLanguage = useSelector(
    (state) => state[STORE_NAMES.user]?.userLanguage
  );
  const currentLanguage = i18n.language;
  useEffect(() => {
    if (userLanguage?.code && userLanguage?.code !== currentLanguage) {
      changeLanguage(userLanguage.code);
    }
  }, [userLanguage?.code]);
};

export default useLanguageI18n;
