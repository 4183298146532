import React, { createContext, useContext, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "../constants/redux";
import { ROUTE_NAME } from "../constants/routes";
import { generateGuestIdSuffix } from "../helpers";

const MixpanelContext = createContext();

const MixpanelProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [eventQueue, setEventQueue] = useState([]);
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const businessUser = useSelector((state) => state[STORE_NAMES.user].user);

  useEffect(() => {
    try {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
        debug: false,
        track_pageview: "url-with-path",
        persistence: "localStorage",
      });
      setIsInitialized(true);
    } catch (error) {
      console.error("Mixpanel initialization failed:", error);
      setIsInitialized(false);
    }
  }, []);

  useEffect(() => {
    if (isInitialized && eventQueue.length > 0) {
      const queueClone = [...eventQueue];
      queueClone.forEach((item) => {
        mixpanel.track(item.eventName, item.properties);
      });
      setEventQueue([]);
    }
  }, [eventQueue.length, isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      if (window.location.pathname.startsWith(ROUTE_NAME.client) && guest.id) {
        mixpanel.identify(generateGuestIdSuffix(guest.id));
        if (guest.firstName) {
          mixpanel.people.set({
            $name: guest.firstName,
          });
        }
      } else if (
        window.location.pathname.startsWith(ROUTE_NAME.admin) &&
        businessUser.id
      ) {
        mixpanel.identify(businessUser.id);
        if (businessUser.name) {
          mixpanel.people.set({
            $name: businessUser.name,
          });
        }
      } else {
        mixpanel.reset();
      }
    }
  }, [isInitialized, guest, businessUser]);

  const addEventToQueue = (eventName, properties) => {
    if (isInitialized) {
      mixpanel.track(eventName, properties);
      return;
    }
    setEventQueue((prev) => {
      return [...prev, { eventName, properties }];
    });
  };

  return (
    <MixpanelContext.Provider value={{ trackMixpanel: addEventToQueue }}>
      {children}
    </MixpanelContext.Provider>
  );
};

MixpanelProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useMixpanel = () => {
  return useContext(MixpanelContext);
};

export default MixpanelProvider;

//
// import React, { createContext, useContext, useEffect, useState } from "react";
// import mixpanel from "mixpanel-browser";
// import PropTypes from "prop-types";
// import { useSelector } from "react-redux";
// import { STORE_NAMES } from "../constants/redux";
// import { ROUTE_NAME } from "../constants/routes";
// import { generateGuestIdSuffix } from "../helpers";
// import { mix } from "three/src/nodes/math/MathNode";
//
// const MixpanelContext = createContext();
//
// const MixpanelProvider = ({ children }) => {
//   const [isInitialized, setIsInitialized] = useState(false);
//   const [eventQueue, setEventQueue] = useState([]);
//   const [superProps, setSuperProps] = useState({
//     businessId: null,
//     businessName: null
//
//   }
// };
// )
// ;
// const guest = useSelector((state) => state[STORE_NAMES.guest]);
// const businessUser = useSelector((state) => state[STORE_NAMES.user].user);
//
// const changeSuperProps = (newProps) => {
//   setSuperProps((prev) => {
//     return { ...prev, ...newProps };
//   });
// };
//
// useEffect(() => {
//   try {
//     mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
//       debug: false,
//       track_pageview: "url-with-path",
//       persistence: "localStorage"
//     });
//     setIsInitialized(true);
//   } catch (error) {
//     console.error("Mixpanel initialization failed:", error);
//     setIsInitialized(false);
//   }
// }, []);
//
// useEffect(() => {
//   if (isInitialized && eventQueue.length > 0) {
//     const queueClone = [...eventQueue];
//     queueClone.forEach((item) => {
//       mixpanel.track(item.eventName, item.properties);
//     });
//     setEventQueue([]);
//   }
// }, [eventQueue.length, isInitialized]);
//
// useEffect(() => {
//   if (!isInitialized) {
//     return;
//   }
//
//   if (window.location.pathname.startsWith(ROUTE_NAME.client) && guest.id) {
//     mixpanel.identify(generateGuestIdSuffix(guest.id));
//     if (guest.firstName) {
//       mixpanel.people.set({
//         $name: guest.firstName
//       });
//     }
//   } else if (
//     window.location.pathname.startsWith(ROUTE_NAME.admin) &&
//     businessUser.id
//   ) {
//     mixpanel.identify(businessUser.id);
//     if (businessUser.name) {
//       mixpanel.people.set({
//         $name: businessUser.name
//       });
//     }
//   } else {
//     mixpanel.reset();
//   }
//
// }, [isInitialized, guest, businessUser]);
//
// const addEventToQueue = (eventName, properties) => {
//   setEventQueue((prev) => {
//     return [...prev, { eventName { ...superProps, ...properties }];
//   })
//   };
//
//   return (
//     <MixpanelContext.Provider value={{ trackMixpanel: addEventToQueue }}>
//       {children}
//     </MixpanelContext.Provider>
//   );
// };
//
// MixpanelProvider.propTypes = {
//   children: PropTypes.node.isRequired
// };
//
// export const useMixpanel = () => {
//   return useContext(MixpanelContext);
// };
//
// export default MixpanelProvider;
