import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as IconArrow } from "assets/icons/arrows/ArrowLeft.svg";
import { ReactComponent as BasketIcon } from "assets/icons/basket/Cart.svg";
import { STORE_NAMES } from "utils/constants/redux";
import BestChoiceModal from "pages/client/magic-order/best-choice-modal/BestChoiceModal";
import SuperSearchInput from "./super-search-input/SuperSearchInput";
import { getBrandMainLogoLight } from "utils/branding-helper";
import useAsync from "utils/hooks/useAsync";
import { aiSuperSearchMenu } from "utils/api/services/open-ai";
import { formatMenuForAI, formatResult } from "./helper";
import WaitLoading from "components/wait-loading/WaitLoading";
import { ReactComponent as TrashIcon } from "assets/icons/trash/trashLight.svg";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import {
  MP_EVENTS,
  MP_PAGE_NAMES,
  MP_PROP_NAMES,
} from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";

import "./MagicOrder.scss";

const Logo = getBrandMainLogoLight();

const SpeechToText = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.magicOrder });
  const { trackMixpanel } = useMixpanel();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { businessId } = useParams();

  const lastPromptRef = useRef(null);
  const lastResultRef = useRef(null);

  const [promptResultsHistory, setPromptResultsHistory] = useState(
    JSON.parse(sessionStorage.getItem("promptResultsHistory"))?.[businessId] ||
      []
  );
  const [typedText, setTypedText] = useState("");
  const speechToTextBodyRef = useRef(null);
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guests = basketOrders ? basketOrders.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);
  const orderItems = guest?.orderItems || [];
  const orderItemsCount = orderItems?.reduce(
    (sum, orderItem) => sum + orderItem.count,
    0
  );

  const texts = [
    t("recommend.fitMyBudget"),
    t("recommend.tastiestDishes"),
    t("recommend.delicious"),
    t("recommend.smtQuick"),
  ];

  useEffect(() => {
    const parsedHistory =
      JSON.parse(sessionStorage.getItem("promptResultsHistory")) || {};
    sessionStorage.setItem(
      "promptResultsHistory",
      JSON.stringify({ ...parsedHistory, [businessId]: promptResultsHistory })
    );
  }, [promptResultsHistory]);
  const {
    execute: executeAiSuperSearchMenu,
    loading: loadingAiSuperSearchMenu,
  } = useAsync(aiSuperSearchMenu, {
    onError: () => {
      toast.error(t("toastMessages.error.common"));
      setPromptResultsHistory((prev) =>
        prev.map((item, index) =>
          index === prev.length - 1
            ? {
                ...item,
                response: {
                  success: false,
                  result: t("toastMessages.error.common"),
                },
              }
            : item
        )
      );
    },
    onSuccess: ({ data }) => {
      if (!data.result || data.result.length === 0) {
        setPromptResultsHistory((prev) =>
          prev.map((item, index) =>
            index === prev.length - 1
              ? {
                  ...item,
                  response: {
                    success: false,
                    result: t("record.magicOrderError"),
                  },
                }
              : item
          )
        );
      } else {
        setPromptResultsHistory((prev) =>
          prev.map((item, index) =>
            index === prev.length - 1
              ? {
                  ...item,
                  response: {
                    success: true,
                    result: data?.result,
                  },
                }
              : item
          )
        );
      }
    },
  });

  const handleSaveSpeech = ({ prompt }) => {
    const promptBody = {
      businessId: businessId,
      data: {
        userPrompt: prompt,
        menu: formatMenuForAI(menu),
      },
    };
    executeAiSuperSearchMenu(promptBody);
    setTypedText("");
    setPromptResultsHistory((prev) => [
      ...prev,
      { prompt: prompt, response: null },
    ]);
    trackMixpanel(`${MP_EVENTS.magicOrderPrompt}`, {
      [MP_PROP_NAMES.businessId]: businessId,
      [MP_PROP_NAMES.prompt]: prompt,
    });
  };

  const handleGoMenu = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  const handleGoBasket = () => {
    navigate({
      pathname: `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.basket}`,
      search: `?${QUERY_PARAMS.from}=${pathname}`,
    });
  };

  const handleClearAllChat = () => {
    setPromptResultsHistory([]);
    sessionStorage.removeItem(promptResultsHistory);
  };

  useEffect(() => {
    if (promptResultsHistory[promptResultsHistory.length - 1]?.response) {
      const scrollDistance =
        speechToTextBodyRef.current.scrollHeight -
        lastPromptRef.current.clientHeight -
        lastResultRef.current.clientHeight -
        25;
      speechToTextBodyRef?.current?.scrollTo({
        top: scrollDistance,
        behavior: "smooth",
      });
    } else {
      speechToTextBodyRef?.current?.scrollTo({
        top: speechToTextBodyRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [promptResultsHistory]);

  return (
    <div className="SpeechToTextContainer">
      <div className="SpeechToTextContainerHeader">
        <div className="SpeechToTextContainerLeft">
          <button className="BackButton" onClick={handleGoMenu}>
            <IconArrow />
          </button>
          <h1 className="SpeechToTextContainerHeaderTitle">
            {t("record.magicOrder")}
          </h1>
        </div>
        <div className="SpeechToTextContainerHeaderActions">
          {promptResultsHistory.length > 0 && (
            <div className="TrashContainer" onClick={handleClearAllChat}>
              <TrashIcon />
            </div>
          )}
          <div className="BasketContainer">
            <BasketIcon onClick={handleGoBasket} />
            {orderItemsCount > 0 && (
              <div className="BasketButtonCount">
                <h6 className="SemiBold">{orderItemsCount}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
      {promptResultsHistory.length === 0 ? (
        <div className="SpeechToTextContainerContentEmptyState">
          <Logo />
        </div>
      ) : (
        <div className="SpeechToTextContainerContent" ref={speechToTextBodyRef}>
          {promptResultsHistory.map((result, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className="SpeechToTextContainerMessage"
                  ref={
                    index === promptResultsHistory.length - 1
                      ? lastPromptRef
                      : null
                  }
                >
                  <h5 className="Medium">{result.prompt}</h5>
                </div>

                <div
                  className={cx("SpeechToTextContainerResponseMessage", {
                    isLoading: !result?.response,
                  })}
                  ref={
                    index === promptResultsHistory.length - 1
                      ? lastResultRef
                      : null
                  }
                >
                  {!result?.response &&
                    (loadingAiSuperSearchMenu ? (
                      <WaitLoading />
                    ) : (
                      <h5>{t("buttons.tryAgain")}</h5>
                    ))}

                  {result?.response?.success ? (
                    <BestChoiceModal
                      results={formatResult(result?.response?.result, menu)}
                    />
                  ) : (
                    <h5>{result?.response?.result}</h5>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )}
      {promptResultsHistory.length === 0 && (
        <div className="Recommends">
          {texts.map((text, index) => {
            return (
              <div
                key={index}
                className="Recommend"
                onClick={() => handleSaveSpeech({ prompt: text })}
              >
                <h5 className="Medium">{text}</h5>
              </div>
            );
          })}
        </div>
      )}
      <div className="SpeechToTextContainerFooter">
        <SuperSearchInput
          handleSaveSpeech={handleSaveSpeech}
          resultText={typedText}
          setTypedText={setTypedText}
          loadingAiSuperSearchMenu={loadingAiSuperSearchMenu}
        />
      </div>
    </div>
  );
};

export default SpeechToText;
