import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

let electronAPI = null;

const ELECTRON_CHANNEL = {
  inbound: { printersList: "printers-list" },
  outbound: {
    printRequest: "print-request",
    getPrinters: "get-printers",
    testPrintRequest: "test-print-request",
  },
};

const ELECTRON_CONSTANTS = {
  userAgent: "electron/",
};

const checkIsElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(ELECTRON_CONSTANTS.userAgent) !== -1;
};

const getElectronAPI = () => {
  if (checkIsElectron() && !electronAPI) {
    electronAPI = window.require("electron");
  }
  return electronAPI;
};

//TODO: use electron provider via hook
const ElectronContext = createContext({});

const ElectronProvider = ({ children }) => {
  const [isElectron, setIsElectron] = useState(false);
  const [electronAPIInstance, setElectronAPIInstance] = useState(null);

  useEffect(() => {
    if (checkIsElectron()) {
      setIsElectron(true);
      setElectronAPIInstance(getElectronAPI());
    }
  }, []);

  return (
    <ElectronContext.Provider
      value={{
        isElectron,
        electronAPI: electronAPIInstance,
      }}
    >
      {children}
    </ElectronContext.Provider>
  );
};

ElectronProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ElectronContext, ElectronProvider, ELECTRON_CHANNEL };
