import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import Spinner from "components/elements/spinner/Spinner";
import useAsync from "utils/hooks/useAsync";
import Pagination from "components/pagination/Pagination";
import { TableV2 } from "components/table/TableV2";
import { getBusinessPaymentStatistic } from "utils/api/services/dashboard";
import { STORE_NAMES } from "utils/constants/redux";
import EmptyTableIcon from "assets/icons/admin-dashboard/EmptyTable.svg";

import "./AdminDashPayment.scss";

const meta = {
  breakpoints: [
    {
      BreakPointsRange: {
        max: -1,
        min: 1800,
      },
      cellDisplayAndProportions: [25, 50 / 3, 50 / 3, 25, 50 / 3],
    },
    {
      BreakPointsRange: {
        max: 1799,
        min: 1100,
      },
      cellDisplayAndProportions: [25, 50 / 3, 50 / 3, 25, 50 / 3],
    },
    {
      BreakPointsRange: {
        max: 1099,
        min: 700,
      },
      cellDisplayAndProportions: [40, 20, 20, null, 20],
    },
    {
      BreakPointsRange: {
        max: 699,
        min: 300,
      },
      cellDisplayAndProportions: [50, 50, null, null, null],
    },
  ],
};

const TABLE_KEYS = [
  {
    value: "orderId",
    valueForTranslation: "payment.businessPaymentsTitle.orderId",
    isSortable: false,
  },
  {
    value: "paidPrice",
    valueForTranslation: "payment.businessPaymentsTitle.paidPrice",
    isSortable: false,
  },
  {
    value: "paymentStatus",
    valueForTranslation: "payment.businessPaymentsTitle.paymentStatus",
    isSortable: true,
  },
  {
    value: "cardClassification",
    valueForTranslation: "payment.businessPaymentsTitle.cardClassification",
    isSortable: true,
  },
  {
    value: "cardHolderName",
    valueForTranslation: "payment.businessPaymentsTitle.cardHolderName",
    isSortable: true,
  },
];

const AdminDashPayments = () => {
  const { setTitle, fromDateToDate } = useOutletContext();
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [currentPage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const pageSize = 50;

  useEffect(() => {
    setTitle(t("navbarRoutes.pageTitles.payments"));
  }, [t]);

  useEffect(() => {
    if (rows?.length === 0 && currentPage > 1) {
      setActivePage(currentPage - 1);
    }
  }, [rows]);

  const { loading, result: fetchedBusinessPayments } = useAsync(
    getBusinessPaymentStatistic,
    {
      immediate: true,
      params: [
        {
          businessId,
          startDate: fromDateToDate.from,
          endDate: fromDateToDate.to,
          pageNumber: currentPage,
          pageSize,
        },
      ],
    }
  );

  const headers = TABLE_KEYS.map((header, index) => {
    return {
      element: (
        <div key={index} className="headerCell">
          <p>{t(header.valueForTranslation)}</p>
        </div>
      ),
    };
  });

  useEffect(() => {
    if (fetchedBusinessPayments && fetchedBusinessPayments.items) {
      setRows(createRows(fetchedBusinessPayments));
      setPageCount(
        Math.ceil(
          fetchedBusinessPayments.totalSize / fetchedBusinessPayments.size
        )
      );
    } else {
      setRows([]);
    }
  }, [fetchedBusinessPayments]);

  const createRows = (paymentData) => {
    return paymentData.items.map((row) => [
      {
        element: <h6 className="Medium">{row?.orderId}</h6>,
      },
      {
        element: (
          <h6 className="Medium">
            {row?.paidPrice} {row?.currency}
          </h6>
        ),
      },
      {
        element: <h6 className="Medium">{row?.paymentStatus}</h6>,
      },
      {
        element: (
          <h6 className="Medium">
            {row.paymentCard.cardBrand ? row.paymentCard.cardBrand : "-"} (
            {row.paymentCard?.cardAssociation
              ? row.paymentCard?.cardAssociation?.replace(/_CARD$/, "")
              : "-"}{" "}
            /{" "}
            {row.paymentCard?.cardType
              ? row.paymentCard?.cardType?.replace(/_CARD$/, "")
              : "-"}
            )
          </h6>
        ),
      },
      {
        element: (
          <h6 className="Medium">
            {row?.paymentCard?.cardHolderName
              ? row?.paymentCard?.cardHolderName
              : "-"}
          </h6>
        ),
      },
    ]);
  };

  const EmptyTable = () => {
    return (
      <div className={`AdminDashHistoryFormAndTableEmpty`}>
        <img src={EmptyTableIcon} alt="Empty Table" />
        <h4 className="Medium AdminDashHistoryFormAndTableEmptyInfo">
          {t("emptyStates.noTableData")}
        </h4>
      </div>
    );
  };

  return (
    <div className="AdminDashPaymentTable">
      <TableV2
        meta={meta}
        headers={headers}
        loading={loading}
        rows={rows}
        LoadingComponent={<Spinner />}
        EmptyComponent={<EmptyTable />}
        Footer={
          !loading &&
          pageCount > 1 && (
            <Pagination
              pageCount={pageCount}
              pageRange={1}
              setActivePage={setActivePage}
              currentPage={currentPage}
            />
          )
        }
      />
    </div>
  );
};

export default AdminDashPayments;
