import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CloseButton from "components/buttons/close-button/CloseButton";
import PropTypes from "prop-types";
import Review from "components/elements/review/Review";
import { useDispatch, useSelector } from "react-redux";
import useAsync from "utils/hooks/useAsync";
import { createGuestRating } from "utils/api/services/order";
import { STORE_NAMES } from "utils/constants/redux";
import GuestModal, {
  MODAL_POSITIONS,
} from "components/guest/guest-modal/GuestModal";
import { toast } from "react-toastify";
import { setOrReplaceOrder } from "redux/slices/ordersStore";

import "./FeedBackModal.scss";

const FeedBackModal = ({
  onClose,
  closeButtonType,
  mainElementRef,
  openSlide,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table.id;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);
  const currentGuest =
    order?.guests.find((guest) => guest.person.id === guestId) || {};

  const businessId = qrScanStore.businessId;
  const [isReviewRequestSuccessful, setIsReviewRequestSuccessful] =
    useState(null);

  const { execute: executeGuestRating, loading: isGuestRatingLoading } =
    useAsync(createGuestRating, {
      onError: (error) => {
        setIsReviewRequestSuccessful(false);
        toast.error(error.response.data.message);
      },
      onSuccess: (response) => {
        setIsReviewRequestSuccessful(true);
        dispatch(setOrReplaceOrder([response.data]));
      },
    });

  const handleOnSubmitReview = async (data) => {
    await executeGuestRating({
      guestRatingData: { ...data, tableId },
      businessId,
      orderId: order.id,
      orderGuestId: currentGuest.id,
    });
  };

  useEffect(() => {
    if (!openSlide) {
      setIsReviewRequestSuccessful(null);
    }
  }, [openSlide]);

  const FeedBackModalHeader = (
    <div className="FeedBackHeader">
      <h2 className="Bold">{t("dashboard.guest.review.feedBack")}</h2>
      <CloseButton onClick={onClose} type={closeButtonType || undefined} />
    </div>
  );
  const FeedBackModalBody = (
    <div>
      <Review
        loading={isGuestRatingLoading}
        guest={currentGuest}
        onSubmit={handleOnSubmitReview}
        isReviewRequestSuccessful={isReviewRequestSuccessful}
        setIsReviewRequestSuccessful={setIsReviewRequestSuccessful}
      />
    </div>
  );
  return (
    <GuestModal
      containerClassname={"FeedbackModalContainer"}
      header={FeedBackModalHeader}
      body={FeedBackModalBody}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
      modalPosition={MODAL_POSITIONS.BOTTOM}
    />
  );
};
FeedBackModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  mainElementRef: PropTypes.object,
  onClose: PropTypes.func,
  closeButtonType: PropTypes.string,
};
export default FeedBackModal;
