import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateUser } from "redux/actions/userAction";
import { getUserRoutesByRole, handleOnAsyncError } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { ErrorMessage } from "@hookform/error-message";
import PrimaryButton, {
  ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ROLES } from "utils/constants/global";
import { ROUTE_NAME } from "utils/constants/routes";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import { useKeyPress } from "utils/hooks/useKeyPress";
import { EVENT_CODES } from "utils/constants/events";

import "./SetUserPin.scss";

const SetUserPin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setUserPinCodeSchema = useValidationSchema(t).setUserPinCodeSchema;

  const dispatch = useDispatch();

  const activeUser = useSelector((state) => state[STORE_NAMES.user]).user;
  const isLoadingUpdateUser = useSelector((state) => state[STORE_NAMES.user])
    .thunkAPIStates?.updateUser;

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    trigger,
  } = useForm({
    resolver: zodResolver(setUserPinCodeSchema),
    criteriaMode: "all",
  });

  useEffect(() => {
    Object.keys(errors).length > 0 && trigger();
  }, [t]);

  const handleSubmitPinCode = async () => {
    try {
      const response = await dispatch(
        updateUser({
          businessId: activeUser.business.id,
          userData: {
            businessUser: {
              id: activeUser.pinCode.id,
              userPinCode: getValues("pinCode"),
            },
          },
          userId: activeUser.id,
          sendPinCode: false,
        })
      );
      if (response.error) {
        handleOnAsyncError();
      } else {
        const userRoutesInfo = getUserRoutesByRole(
          response.payload?.roles[0]?.name
        );

        const isAdminRole = userRoutesInfo.role === ROLES.admin.name;

        isAdminRole
          ? navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.super}`, {
              replace: true,
            })
          : navigate(
              `${ROUTE_NAME.admin}${userRoutesInfo.firstRouteAfterSignIn}`,
              {
                replace: true,
              }
            );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const enterPressed = useKeyPress(EVENT_CODES.enter);

  useEffect(() => {
    if (enterPressed) {
      handleSubmit(handleSubmitPinCode)();
    }
  }, [enterPressed]);

  return (
    <div className="SetUserPin">
      <p className="Subtitle">{t("login.signUp.setPinSubtitle")}</p>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="SetUserPinInputGroups">
          <InputControl
            type="password"
            placeholder={t("inputs.pinCode")}
            name="pinCode"
            {...register("pinCode")}
            hasError={errors.pinCode}
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            error={
              <ErrorMessage
                errors={errors}
                name="pinCode"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
          <InputControl
            type="password"
            placeholder={t("inputs.pinCodeConfirm")}
            name="confirmPinCode"
            {...register("confirmPinCode")}
            hasError={errors.confirmPinCode}
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            error={
              <ErrorMessage
                errors={errors}
                name="confirmPinCode"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
        </div>
        <div className="SetUserPinButtons">
          <PrimaryButton
            text={t("buttons.skip")}
            onClick={() =>
              navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.adminOrderDashboard}`, {
                replace: true,
              })
            }
            type={ENUMS.types.TYPE_D}
          />
          <PrimaryButton
            text={t("buttons.continue")}
            onClick={handleSubmit(handleSubmitPinCode)}
            type={ENUMS.types.TYPE_A}
            isLoading={isLoadingUpdateUser}
          />
        </div>
      </form>
    </div>
  );
};

export default SetUserPin;
