import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import MenuItemCard from "components/cards/menu-item-card/MenuItemCard";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import { updateBasket } from "redux/slices/basketStore";
import { STORE_NAMES } from "utils/constants/redux";
import {
  calculateDiscountPrice,
  calculateItemPriceWithDefaultModificationPrice,
  findItemById,
} from "utils/general";
import useTimeout from "utils/hooks/useTimeout";
import { ReactComponent as CheckIcon } from "assets/icons/check/selected.svg";
import { getBasketModificationsWithDefaultValues } from "utils/helpers";

import "./BestChoiceModal.scss";

const ResultBody = ({ result, handleAddToBasket }) => {
  const filteredResultItems = result.items.filter(
    (item) => item.isPublished && !item.isArchived
  );
  const [addBasketAnimation, setAddBasketAnimation] = useState(false);
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState(
    filteredResultItems.flatMap((item) => item.id)
  );
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const handleSelectCard = (id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((value) => value !== id) : [...prev, id]
    );
  };

  const calculateCategorySubtotal = (selectedItems) => {
    const addedMenuItems = selectedItems.map((itemId) =>
      findItemById(itemId, menu)
    );
    return addedMenuItems.reduce((groupSum, item) => {
      const menuItemPrice =
        calculateItemPriceWithDefaultModificationPrice(item) +
        calculateDiscountPrice(item);

      return groupSum + menuItemPrice;
    }, 0);
  };

  const calculateTotalPrice = () => {
    return calculateCategorySubtotal(selectedItems);
  };

  const handleAddBasketCustom = () => {
    setAddBasketAnimation(true);
    handleAddToBasket(selectedItems);
    setSelectedItems([]);
  };

  useTimeout({
    callback: () => {
      setAddBasketAnimation(false);
    },
    delay: addBasketAnimation ? 1000 : null,
  });

  return (
    filteredResultItems.length > 0 && (
      <div className="ResultItems">
        <div className="ResultItemTitle">
          <h3 className="SemiBold">{result.title}</h3>
        </div>
        <h5>{result.reason}</h5>
        <div className="ResultItemDetails">
          {filteredResultItems.map((item, index) => (
            <MenuItemCard
              menuItem={item}
              key={index}
              isSelected={selectedItems.includes(item.id)}
              onSelect={() => handleSelectCard(item.id)}
            />
          ))}
        </div>
        <CTAButton
          disabled={!addBasketAnimation && selectedItems.length === 0}
          className="AddToBasketButton"
          onClick={handleAddBasketCustom}
          name={
            addBasketAnimation
              ? t("record.addedToBasket")
              : `${t("buttons.addBasket")} (${selectedItems.length})`
          }
          type={CTA_ENUMS.types.TYPE_N}
          price={!addBasketAnimation ? calculateTotalPrice() : undefined}
          success={addBasketAnimation}
          icon={addBasketAnimation && <CheckIcon />}
        />
      </div>
    )
  );
};

ResultBody.propTypes = {
  handleAddToBasket: PropTypes.func,

  result: PropTypes.object,
};

const BestChoiceModal = ({ results }) => {
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const dispatch = useDispatch();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);

  const handleAddToBasket = (selectedItems) => {
    const addedMenuItems = selectedItems.map((itemId) =>
      findItemById(itemId, menu)
    );

    addedMenuItems.forEach((menuItem) => {
      const modifications = getBasketModificationsWithDefaultValues(
        menuItem.modifications
      );
      const filteredModifications = modifications.filter((modification) => {
        return modification.options.length !== 0;
      });
      dispatch(
        updateBasket({
          menuItem: {
            id: menuItem.id,
            modifications: filteredModifications.map((modification) => {
              return {
                id: modification.id,
                options: modification.options.map((option) => {
                  return {
                    id: option.id,
                    count: option.count ?? 1,
                  };
                }),
              };
            }),
          },
          count: 1,
          userId: guestId,
        })
      );
    });
  };

  return (
    <div className="BestChoiceModalBody">
      {results.map((result, index) => {
        return (
          <ResultBody
            key={index}
            result={result}
            handleAddToBasket={handleAddToBasket}
          />
        );
      })}
    </div>
  );
};
BestChoiceModal.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
};
export default BestChoiceModal;
