import { useEffect } from "react";
import { useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import { detectColorBrightness } from "utils/helpers";
import {
  MENU_COLOR_OPTIONS,
  RADIUS_OPTIONS,
} from "pages/admin/admin-pages/admin-menu/admin-menu-settings/data";

const useSetDynamicVariables = () => {
  const preferredColor = useSelector(
    (state) => state[STORE_NAMES.menu].data?.primaryColor
  );
  const preferredRadius = useSelector(
    (state) => state[STORE_NAMES.menu].data?.borderRadius
  );
  useEffect(() => {
    if (preferredColor) {
      let selectedOption;
      if (preferredColor?.length === 2) {
        selectedOption = MENU_COLOR_OPTIONS.find(
          (el) =>
            el?.[0]?.name == preferredColor?.[0] &&
            el?.[1]?.name == preferredColor?.[1]
        );
        document.documentElement.style.setProperty(
          "--color-preferred-primary-bg",
          selectedOption?.[0]?.code
        );
        document.documentElement.style.setProperty(
          "--color-preferred-secondary-bg",
          selectedOption?.[1]?.code
        );
      } else {
        selectedOption = MENU_COLOR_OPTIONS.find(
          (el) => el?.[0]?.name == preferredColor?.[0] && el?.length === 1
        );
        document.documentElement.style.setProperty(
          "--color-preferred-primary-bg",
          selectedOption?.[0]?.code
        );
        document.documentElement.style.setProperty(
          "--color-preferred-secondary-bg",
          selectedOption?.[0]?.code
        );
      }
      if (preferredColor?.length === 2) {
        const primaryTextColor =
          detectColorBrightness(selectedOption?.[0]?.code) >= 50
            ? "#1f1f1f"
            : "#fff";
        const secondaryTextColor =
          detectColorBrightness(selectedOption?.[1]?.code) >= 50
            ? "#1f1f1f"
            : "#fff";

        document.documentElement.style.setProperty(
          "--color-preferred-primary-text",
          primaryTextColor
        );
        document.documentElement.style.setProperty(
          "--color-preferred-secondary-text",
          secondaryTextColor
        );
      }
      if (preferredColor?.length === 1) {
        const textColor =
          detectColorBrightness(selectedOption?.[0]?.code) >= 50
            ? "#1f1f1f"
            : "#fff";
        document.documentElement.style.setProperty(
          "--color-preferred-primary-text",
          textColor
        );
        document.documentElement.style.setProperty(
          "--color-preferred-secondary-text",
          textColor
        );
      }
    }
  }, [preferredColor]);

  useEffect(() => {
    if (preferredRadius) {
      const selectedOption = RADIUS_OPTIONS.find(
        (option) => option.type === preferredRadius
      );
      for (let key in selectedOption.size) {
        if (key === "MR") {
          document.documentElement.style.setProperty(
            `--margin-preferred-margin${key}`,
            selectedOption.size[key] + "px"
          );
        } else {
          document.documentElement.style.setProperty(
            `--radius-preferred-border-radius${key}`,
            selectedOption.size[key] + "px"
          );
        }
      }
    }
  }, [preferredRadius]);
};

export default useSetDynamicVariables;
