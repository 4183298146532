import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import useAsync from "utils/hooks/useAsync";
import useDebounce from "utils/hooks/useDebounce";
import { getAllGuests } from "utils/api/services/guest";
import FormattedPhoneNumber from "components/elements/formatted-phone-number/FormattedPhoneNumber";
import AdminDashTable from "components/admin/tables/admin-dash-table/AdminDashTable";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";

import "./SuperAdminGuests.scss";

const SuperAdminGuests = () => {
  const { t } = useTranslation();
  const [searchGuests, setSearchGuests] = useState("");
  const [searchGuests2, setSearchGuests2] = useState("");
  const [activePageGuests, setActivePageGuests] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { result: allGuests, loading } = useAsync(getAllGuests, {
    immediate: true,
    params: [activePageGuests, searchGuests2],
  });
  const tableColumns = [
    {
      key: "firstName",
      name: t("inputs.firstName"),
      isFilterable: false,
    },
    {
      key: "lastName",
      name: t("inputs.lastName"),
      isFilterable: false,
    },
    {
      key: "contact",
      name: t("inputs.contact"),
      isFilterable: false,
    },
    {
      key: "gender",
      name: t("auth.gender"),
      isFilterable: false,
    },
    {
      key: "dateOfBirth",
      name: t("inputs.dateOfBirth"),
      isFilterable: false,
    },
    {
      key: "createDate",
      name: t("common.date.createdAt"),
      isFilterable: false,
    },
  ];

  useEffect(() => {
    if (allGuests?.data) {
      setTotalPages(allGuests.totalPages);
      setActivePageGuests(allGuests.pageNumber);
    }
  }, [searchGuests, allGuests]);

  const debouncedRequest = useDebounce(() => {
    setSearchGuests2(searchGuests);
    setActivePageGuests(1);
  }, 1000);

  const handleSearchChange = (value) => {
    setSearchGuests(value.trimStart());
    debouncedRequest();
  };

  const handlePageChange = (pageNumber) => {
    setActivePageGuests(pageNumber);
  };

  const formmatedDateAndTime = (createDate) => {
    const date = new Date(createDate);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  };

  const tableItems = useMemo(() => {
    return (
      allGuests?.data.map((guest) => ({
        id: guest.id,
        firstName: (
          <div className="GuestInfo">
            <ImageWithPlaceholder
              imageSource={createDOBucketName(guest.profilePic)}
              placeholder={IMG_GUEST}
              alt={guest.firstName || "Guest"}
              className="GuestProfile"
            />
            {guest.firstName || "-"}
          </div>
        ),
        lastName: guest.lastName,
        contact:
          (guest.phoneNumber && (
            <FormattedPhoneNumber phoneNumber={guest.phoneNumber} />
          )) ||
          guest.email ||
          "-",
        gender: guest.gender || "-",
        dateOfBirth: guest.dateOfBirth || "-",
        createDate: <span>{formmatedDateAndTime(guest.createDate)}</span>,
      })) || []
    );
  }, [allGuests]);

  return (
    <div className="SuperAdminGuests">
      <div className="SuperAdminGuestsTitle">
        <div className="SuperAdminGuestsInfo">
          <h4 className="SemiBold SuperAdminGuestsInfoTitle">
            {t("navbarRoutes.pageTitles.guests")}
          </h4>
          <h6 className="Medium">
            {t("guests.guest")} ({allGuests?.totalElems})
          </h6>
        </div>
        <div className="SuperAdminGuestsFilters">
          <Search
            onChange={handleSearchChange}
            value={searchGuests}
            placeholder={t("inputs.search")}
            type={SEARCH_ENUMS.types.TYPE_B}
            className="SuperAdminFiltersSearch"
          />
        </div>
      </div>
      <AdminDashTable
        tableColumns={tableColumns}
        hasEditColumnTitle={false}
        hasEditRow={false}
        hasEditColumn={false}
        tableItems={tableItems}
        currentPage={allGuests?.pageNumber || 1}
        totalPages={totalPages}
        setActivePage={handlePageChange}
        isDataLoading={loading}
      />
    </div>
  );
};

export default SuperAdminGuests;
