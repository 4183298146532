import { ELECTRON_CHANNEL } from "electron/ElectronProvider";

export const groupOrderItemsByOrder = (orders, businessName) => {
  const result = [];

  orders.forEach((order) => {
    const tableName = order.table?.name || null;
    const zoneName = order.table?.zoneName || null;
    const assigneeName = order.assignee?.name || null;

    const groupedOrderItems = { orderItems: [] };

    order?.guests?.forEach((guest) => {
      guest.orderItems.forEach((orderItem) => {
        if (!orderItem.isArchived && orderItem.isConfirmed === null) {
          groupedOrderItems.orderItems.push({
            ...orderItem,
          });
        }
      });
    });
    if (groupedOrderItems.orderItems.length === 0) {
      return result;
    }

    (groupedOrderItems.orderId = order.id),
      (groupedOrderItems.table = tableName),
      (groupedOrderItems.zone = zoneName),
      (groupedOrderItems.assignee = assigneeName),
      (groupedOrderItems.businessName = businessName),
      result.push(groupedOrderItems);
  });

  return result;
};

export const printNewOrderItems = (electronAPI, order) => {
  electronAPI.ipcRenderer.send(ELECTRON_CHANNEL.outbound.printRequest, order);
};
