import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { ReactComponent as ExclamationIcon } from "assets/icons/other/Exclamation.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close/AdminClose.svg";

import "./WarningIcon.scss";

export const ENUMS = {
  name: "WarningIcon",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const WarningIcon = ({ type = ENUMS.types.TYPE_A }) => {
  return (
    <div
      className={cx("WarningIcon", {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
      })}
    >
      {type === ENUMS.types.TYPE_A && <ExclamationIcon />}
      {type === ENUMS.types.TYPE_B && <CloseIcon />}
    </div>
  );
};

WarningIcon.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),
};

export default WarningIcon;
