export const sortActiveGuest = (activeGuest) => {
  return activeGuest.sort((a, b) => {
    const profilePicA = a.profilePic ? 1 : 0;
    const profilePicB = b.profilePic ? 1 : 0;
    if (profilePicA !== profilePicB) {
      return profilePicB - profilePicA;
    }

    const hasNameA = a.firstName || a.lastName ? 1 : 0;
    const hasNameB = b.firstName || b.lastName ? 1 : 0;
    if (hasNameA !== hasNameB) {
      return hasNameB - hasNameA;
    }

    return a.person.id - b.person.id;
  });
};
