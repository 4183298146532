export const ADMIN_PIN_CODE_MAX_LENGTH = 6;

export const CURRENCY = {
  azn: {
    name: "manat",
    symbol: "₼",
  },
  dollar: {
    name: "dollar",
    symbol: "$",
  },
};

export const ROLES = {
  superAdmin: {
    name: "SUPER_ADMIN",
  },
  admin: {
    name: "ADMIN",
  },
  admin2: {
    name: "ADMIN_2",
  },
  admin3: {
    name: "ADMIN_3",
  },
  admin4: {
    name: "ADMIN_4",
  },
  owner: {
    name: "OWNER",
  },
  headManager: {
    name: "HEAD_MANAGER",
  },
  branchManager: {
    name: "BRANCH_MANAGER",
  },
  manager: {
    name: "MANAGER",
  },
  cashier: {
    name: "CASHIER",
  },
  staff: {
    name: "STAFF",
  },
  guest: {
    name: "GUEST",
  },
};

export const adminRoles = [
  ROLES.superAdmin.name,
  ROLES.admin.name,
  ROLES.admin2.name,
  ROLES.admin3.name,
  ROLES.admin4.name,
];

export const APPLICATION_BRANDING = {
  iloyal: "iloyal",
  imenu: "imenu",
};

export const WEEKDAYS = {
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday",
  sunday: "sunday",
};

export const TIME = {
  second: "second",
  minute: "minute",
  hour: "hour",
  day: "day",
};

export const FILE_TYPE = {
  csv: "csv",
  xlsx: "xlsx",
};

export const landingPageImageUrl =
  "https://iloyal-dev.fra1.cdn.digitaloceanspaces.com/general/landing-page/iLoyal.png";

export const BUSINESS_SETTINGS = {
  reservation: "reservation",
  chat: "chat",
};

export const LOCAL_STORAGE_VARIABLES = {
  accessTokenForGuest: "guest_access_token",
};
