import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";

import "./PrimaryButton.scss";

export const ENUMS = {
  name: "PrimaryButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
    TYPE_E: "TYPE_E",
    TYPE_F: "TYPE_F",
    TYPE_H: "TYPE_H",
    TYPE_I: "TYPE_I",
    TYPE_S: "TYPE_S",
    TYPE_P: "TYPE_P",
    TYPE_M: "TYPE_M",
    TYPE_R: "TYPE_R",
  },
};
const PrimaryButton = ({
  type = ENUMS.types.TYPE_A,
  text,
  onClick,
  icon,
  isDisabled = false,
  className,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={onClick}
      className={cx("PrimaryButton", className, {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
        typeC: type === ENUMS.types.TYPE_C,
        typeD: type === ENUMS.types.TYPE_D,
        typeE: type === ENUMS.types.TYPE_E,
        typeF: type === ENUMS.types.TYPE_F,
        typeH: type === ENUMS.types.TYPE_H,
        typeI: type === ENUMS.types.TYPE_I,
        typeS: type === ENUMS.types.TYPE_S,
        typeP: type === ENUMS.types.TYPE_P,
        typeM: type === ENUMS.types.TYPE_M,
        typeR: type === ENUMS.types.TYPE_R,
        isLoading: isLoading,
        isDisabled: isDisabled,
      })}
      disabled={isDisabled}
    >
      {isLoading ? (
        <RotatingLines
          strokeColor="#000"
          height={type === ENUMS.types.TYPE_E ? 20 : 30}
          width={
            type === ENUMS.types.TYPE_E || type === ENUMS.types.TYPE_D ? 20 : 30
          }
          strokeWidth="4"
        />
      ) : (
        <>
          {icon && !isLoading && (
            <img src={icon} alt={text} className="PrimaryButtonIcon" />
          )}
          {type !== ENUMS.types.TYPE_E &&
            type !== ENUMS.types.TYPE_F &&
            type !== ENUMS.types.TYPE_H &&
            type !== ENUMS.types.TYPE_P &&
            type !== ENUMS.types.TYPE_S &&
            type !== ENUMS.types.TYPE_M && (
              <h5 className="Medium PrimaryButtonText">
                {text || t("buttons.save")}
              </h5>
            )}
          {type === ENUMS.types.TYPE_E && (
            <h6 className="SemiBold PrimaryButtonText">
              {text || t("buttons.save")}
            </h6>
          )}
          {type === ENUMS.types.TYPE_M && (
            <h6 className=" Medium PrimaryButtonText">
              {text || t("buttons.save")}
            </h6>
          )}
          {(type === ENUMS.types.TYPE_F ||
            type === ENUMS.types.TYPE_H ||
            type === ENUMS.types.TYPE_P ||
            type === ENUMS.types.TYPE_S) && (
            <h4 className="SemiBold PrimaryButtonText">
              {text || t("buttons.save")}
            </h4>
          )}
        </>
      )}
    </button>
  );
};

PrimaryButton.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * The content of button
   */
  text: PropTypes.string,

  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,

  /**
   * The icon to display next to the button text
   */
  icon: PropTypes.node,
  /**
   * Disable state for the button
   */
  isDisabled: PropTypes.bool,

  /**
   * Custom className for additional styling
   */
  className: PropTypes.string,

  /**
   * Loading state for the button
   */
  isLoading: PropTypes.bool,
};

export default PrimaryButton;
