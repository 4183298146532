import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowDown.svg";
import { STORE_NAMES } from "utils/constants/redux";
import useLanguage from "utils/hooks/useLanguage";
import { formatDateToShortFormat } from "utils/helpers";
import OrderItemModificationList from "components/admin/cards/order-item-modification-list/OrderItemModificationList";

import "./AdminOrderReceipt.scss";

const AdminOrderReceipt = ({ orderReceipt }) => {
  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );

  const { t } = useTranslation();
  const [showPaidOrderDetail, setShowPaidOrderDetail] = useState(false);

  const allCurrencies = useSelector(
    (state) => state[STORE_NAMES.app].currencies
  );
  const currency = allCurrencies.find(
    (curr) => curr.id === orderReceipt.currencyId
  );

  const [orderDetailsHeight, setOrderDetailsHeight] = useState(0);
  const ReceiptDetailRef = useRef(null);
  const { displayDataByLanguage } = useLanguage();

  useEffect(() => {
    if (ReceiptDetailRef.current) {
      const height = ReceiptDetailRef.current.scrollHeight;
      setOrderDetailsHeight(height);
    }
  }, [showPaidOrderDetail]);

  const getTimeDifference = (requestDateTime) => {
    const now = new Date();
    const requestTime = new Date(requestDateTime);
    const differenceInMs = now - requestTime;

    const minutes = Math.floor(differenceInMs / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days === 1
        ? t("reservation.oneDay")
        : `${days} ${t("reservation.day")}`;
    }

    if (hours > 0) {
      const remainingMinutes = minutes % 60;
      return hours === 1
        ? `1${t("reservation.hour")} ${
            remainingMinutes > 0
              ? `${remainingMinutes}${t("reservation.minute")}`
              : ""
          }`
        : `${hours}${t("reservation.hour")} ${
            remainingMinutes > 0
              ? `${remainingMinutes}${t("reservation.minute")}`
              : ""
          }`;
    }

    if (minutes > 0) {
      return minutes === 1
        ? `1 ${t("reservation.minute")}`
        : `${minutes} ${t("reservation.minute")}`;
    }

    return t("reservation.justNow");
  };

  const staticBoxHeight = 56;
  return (
    <div
      className="AdminOrderReceiptWrapper"
      style={{
        height: !showPaidOrderDetail
          ? `${staticBoxHeight}px`
          : `calc(${staticBoxHeight}px + ${orderDetailsHeight}px + 16px)`,
      }}
    >
      <div className="AdminOrderReceipt">
        <div className="AdminOrderReceiptHeaderContainer">
          <div
            className="AdminOrderReceiptHeader"
            onClick={() => {
              setShowPaidOrderDetail(!showPaidOrderDetail);
            }}
          >
            <div className="OrderDetails">
              <div className="AdminOrderReceiptHeaderCreateDate">
                <h5 className="AdminOrderReceiptHeaderTitle SemiBold">
                  {formatDateToShortFormat(orderReceipt.createDate)}
                </h5>
                <span>{getTimeDifference(orderReceipt.createDate)}</span>
              </div>
              <div>
                <h5 className="Medium">{orderReceipt.orderId}</h5>
                <span>{t("modal.orderId")}</span>
              </div>
            </div>

            <div>
              <h5 className="SemiBold">{`${
                currency?.symbol
              } ${orderReceipt?.total.toFixed(2)}`}</h5>
              <ArrowIcon
                className={showPaidOrderDetail ? "RotateArrowIcon" : undefined}
              />
            </div>
          </div>
        </div>
        <div className="AdminOrderReceiptContainer" ref={ReceiptDetailRef}>
          {orderReceipt.orderItems.map((orderItem, index) => {
            return (
              <div
                key={index}
                className="AdminOrderPageHomeInfoBoxOrderDetails"
              >
                <ul className="AdminOrderDetails">
                  <li>
                    <div className="AdminOrderInfoDetails">
                      <p style={{ marginRight: "8px" }}>
                        <span>{orderItem.count}x </span>
                        {displayDataByLanguage(orderItem.item.name)}
                      </p>
                      <div className="AdminOrderInfoPrice">
                        {orderItem.payment.orderItemDiscountPrice !== 0 && (
                          <h6 className="Medium h8 DiscountPrice">
                            {`  ${currency?.symbol}
                            ${orderItem.payment.orderItemSubtotalPrice.toFixed(
                              2
                            )}`}
                          </h6>
                        )}
                        <h6 className=" h7 Medium">
                          {`${currency?.symbol} ${(
                            orderItem.payment.orderItemSubtotalPrice +
                            orderItem.payment.orderItemDiscountPrice
                          ).toFixed(2)} `}
                        </h6>
                      </div>
                    </div>
                    {orderItem.item.modifications.length > 0 && (
                      <ul className="OrderItemDetailsListModifications">
                        {orderItem.item.modifications.map(
                          (modification) =>
                            modification.options.length > 0 && (
                              <OrderItemModificationList
                                modification={modification}
                                className="OrderItemDetailsListModificationList"
                                key={modification.id}
                                hasPriceInfo
                              />
                            )
                        )}
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            );
          })}
          <div className="AdminOrderReceiptBody">
            <div className="AdminOrderReceiptBodySubTotal">
              <h6 className="Medium">{t("basket.order.subtotal")}</h6>
              <h6 className="Medium">
                {`${currency?.symbol} ${orderReceipt.subTotal.toFixed(2)}`}{" "}
              </h6>
            </div>
            <div className="AdminOrderReceiptBodyServiceFee">
              <h6 className="Medium">
                {t("inputs.serviceFee")} ({serviceFee || 0}%)
              </h6>
              <h6 className="Medium">
                {serviceFee > 0 && <span>+</span>}
                {`${currency?.symbol} ${orderReceipt.serviceFee.toFixed(2)} `}
              </h6>
            </div>
            <div className="AdminOrderReceiptBodyDiscount">
              <h6 className="Medium">{t("inputs.discount")}</h6>
              <h6 className="Medium">
                {orderReceipt.discount !== 0 && <span>-</span>}
                {`${currency?.symbol} ${orderReceipt.discount.toFixed(2)} `}
              </h6>
            </div>
            <div className="AdminOrderReceiptBodyTotal">
              <h3 className="SemiBold">{t("basket.order.total")}</h3>
              <h3 className="SemiBold">{orderReceipt.total.toFixed(2)}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
AdminOrderReceipt.propTypes = {
  orderReceipt: PropTypes.object.isRequired,
};

export default AdminOrderReceipt;
