import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import GoogleIcon from "assets/icons/sosial-media/Google.svg";
import AppleIcon from "assets/icons/sosial-media/Apple.svg";
import FaceBookIcon from "assets/icons/sosial-media/Facebook.svg";
import InstagramIcon from "assets/icons/sosial-media/Instagram.svg";

import "./OrSignUpWith.scss";

const OrSignUpWith = () => {
  const { t } = useTranslation();

  // const { execute: executeEnterWithFacebook } = useAsync(enterWithFacebook);

  const socialMediaOptions = [
    {
      icon: GoogleIcon,
      label: "Google Login",
    },
    { icon: AppleIcon, label: "Apple Login" },
    {
      icon: FaceBookIcon,
      label: "Facebook Login",
      // onClick: () => window.open(enterWithFacebook, "_self"),
    },
    {
      icon: InstagramIcon,
      label: "Instagram Login",
    },
  ];

  return (
    <div className="GuestAuthFooter">
      <div className="GuestAuthSignUpWith">
        <div className="LeftLine"></div>
        <div className="FooterText">
          <h6 className="Medium">{t("auth.signUpWith")}</h6>
        </div>
        <div className="RightLine"></div>
      </div>
      <div className="GuestAuthSocials">
        <div className="GuestAuthSocials">
          {socialMediaOptions.map(({ icon, label, onClick }, index) => (
            <div
              className={cx("GuestAuthSocial", { isActive: onClick })}
              key={index}
              onClick={onClick}
            >
              <img src={icon} alt={label} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrSignUpWith;
