import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { STORE_NAMES } from "utils/constants/redux";

import GuestProfileWithIcon from "components/elements/guest-profile-with-icon/GuestProfileWithIcon";
import Confirm from "components/admin/cards/confirm/Confirm";
import PhoneNumberFormatter from "components/elements/formatted-phone-number/FormattedPhoneNumber";

import { ReactComponent as ArrowIcon } from "assets/icons/profile/arrowIcon.svg";
// import { ReactComponent as OrderIcon } from "assets/icons/profile/reserve.svg";
import { ReactComponent as FavoritesIcon } from "assets/icons/profile/Fav.svg";
import { ReactComponent as ChatIcon } from "assets/icons/profile/Message.svg";
import { ReactComponent as GameIcon } from "assets/icons/profile/Game.svg";
// import { ReactComponent as HelpIcon } from "assets/icons/profile/Help center.svg";
// import { ReactComponent as SettingsIcon } from "assets/icons/profile/Settings.svg";

import useGuestLogout from "utils/hooks/useGuestLogout";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";
import { ROUTE_NAME } from "utils/constants/routes";
import { concatFirstNameWithLastName } from "utils/helpers";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";

import "./MyProfile.scss";

const MyProfile = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.myProfile });
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const { table } = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = table?.id;
  const order = orders?.find((order) => order.table.id === tableId);
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logoutGuest } = useGuestLogout();

  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const guest = useSelector((state) => state[STORE_NAMES.guest]);

  const [openConfirm, setOpenConfirm, confirmRef] = useOutsideClick();

  const navigateTo = (path) =>
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${path}`);

  const handleLogOut = () => setOpenConfirm(true);

  const handleConfirmLogOut = async () => {
    await logoutGuest();
    navigate(
      businessId
        ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`
    );
  };
  const handleGoEditProfile = () => {
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`);
  };

  const options = [
    // {
    //   text: t("myProfile.orderHistory"),
    //   icon: <OrderIcon />,
    //   path: `${ROUTE_NAME.funZone}${ROUTE_NAME.games}`,
    // },
    {
      text: t("myProfile.favorites"),
      icon: <FavoritesIcon />,
      path: `${ROUTE_NAME.basket}`,
    },

    order &&
      business?.chat && {
        text: t("myProfile.chat"),
        icon: <ChatIcon />,
        path: `${ROUTE_NAME.chat}`,
      },
    {
      text: t("myProfile.gameHistory"),
      icon: <GameIcon />,
      path: `${ROUTE_NAME.funZone}${ROUTE_NAME.games}`,
    },
  ];

  // const settings = [
  //   {
  //     text: t("myProfile.helpCenter"),
  //     icon: <HelpIcon />,
  //     path: `${ROUTE_NAME.funZone}${ROUTE_NAME.games}`,
  //   },
  //   {
  //     text: t("myProfile.settings"),
  //     icon: <SettingsIcon />,
  //     path: `${ROUTE_NAME.funZone}${ROUTE_NAME.games}`,
  //   },
  // ];
  const handleGoMenu = () => {
    navigate(
      businessId
        ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
        : `${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`
    );
  };

  const ProfileSection = ({ icon, text, path }) => (
    <div className="MyProfileContentSection" onClick={() => navigateTo(path)}>
      {icon}
      <div>
        <h4 className="Medium">{text}</h4>
        <ArrowIcon />
      </div>
    </div>
  );
  ProfileSection.propTypes = {
    icon: PropTypes.element.isRequired,
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  };

  return (
    <div className="MyProfile">
      <div className="MyProfileHeader">
        <BackButton
          onClick={handleGoMenu}
          type={BACK_BUTTON_ENUMS.types.TYPE_D}
        />
        <h2 className="SemiBold">{t("myProfile.title")}</h2>
      </div>

      <div className="MyProfileInfo" onClick={handleGoEditProfile}>
        <GuestProfileWithIcon
          image={guest.profilePic}
          hasImage={!!guest.profilePic}
          className="MyProfileGuestImageContainer"
        />
        <div className="MyProfileInfoDetails">
          <h4 className="SemiBold">{concatFirstNameWithLastName(guest)}</h4>
          {guest.phoneNumber && (
            <PhoneNumberFormatter
              phoneNumber={guest.phoneNumber}
              className="MyProfileGuestPhoneNumber"
            />
          )}
          {guest.email && <h5>{guest.email}</h5>}
        </div>
        <ArrowIcon />
      </div>

      <div className="GuestProfileOptions">
        {/*{[options, settings].map((group, index) => (*/}
        {/*  <div className="MyProfileContent" key={index}>*/}
        {/*    {group.map((option, index) => (*/}
        {/*      <ProfileSection key={index} {...option} />*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*))}*/}

        <div className="MyProfileContent">
          {options.filter(Boolean).map((option, index) => (
            <ProfileSection
              key={index}
              icon={option?.icon}
              path={option?.path}
              text={option?.text}
            />
          ))}
        </div>
        <div className="MyProfileContent">
          <div className="MyProfileContentSection" onClick={handleLogOut}>
            <h4 className="Medium LogOut">{t("buttons.logOut")}</h4>
          </div>
        </div>
      </div>

      <Confirm
        type="TYPE_A"
        title={t("modal.confirmModalTitleLogOut")}
        description={t("modal.confirmModalDescriptionLogOut")}
        mainElementRefConfirm={confirmRef}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={handleConfirmLogOut}
        openSlide={openConfirm}
      />
    </div>
  );
};

export default MyProfile;
