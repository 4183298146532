import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import useWindowSize, { BREAKPOINT_NAMES } from "utils/hooks/useWindowSize";

import "./GuestModal.scss";

export const TYPES = {
  DARK: "DARK",
  BLUR: "BLUR",
};
export const POSITION_TYPES = {
  UP: "up",
  DOWN: "down",
  LEFT: "left",
  RIGHT: "right",
};
export const MODAL_POSITIONS = {
  CENTER: "center",
  BOTTOM: "bottom",
};

const GuestModal = ({
  type = TYPES.DARK,
  header,
  body,
  footer,
  mainElementRef,
  openSlide,
  extraComponentContent,
  className,
  modalClassname,
  containerClassname,
  slideDirection = POSITION_TYPES.DOWN,
  modalPosition = MODAL_POSITIONS.CENTER,
}) => {
  const { isScreenSize } = useWindowSize();
  const isTablet = isScreenSize(BREAKPOINT_NAMES.tablet);
  const [isVisible, setIsVisible] = useState(
    openSlide !== undefined ? openSlide : true
  );

  useEffect(() => {
    let timeout;
    if (openSlide) {
      setIsVisible(true);
      if (isTablet) document.body.classList.add("OverflowHidden");
    } else {
      timeout = setTimeout(() => setIsVisible(false), 500);
    }
    return () => {
      document.body.classList.remove("OverflowHidden");
      clearTimeout(timeout);
    };
  }, [openSlide, isTablet]);

  const getSlideVariables = () => {
    switch (slideDirection) {
      case POSITION_TYPES.DOWN:
        return { "--slide-x": "0", "--slide-y": "100%" };
      case POSITION_TYPES.UP:
        return { "--slide-x": "0", "--slide-y": "-100%" };
      case POSITION_TYPES.RIGHT:
        return { "--slide-x": "100%", "--slide-y": "0" };
      case POSITION_TYPES.LEFT:
        return { "--slide-x": "-100%", "--slide-y": "0" };
      default:
        return { "--slide-x": "0", "--slide-y": "100%" };
    }
  };

  return (
    isVisible && (
      <div
        className={cx("GuestModalBackground", className, {
          isDark: type === TYPES.DARK,
          isBlur: type === TYPES.BLUR,
          isVisible: isVisible && openSlide,
          isHidden: !openSlide,
          isCentered: modalPosition === MODAL_POSITIONS.CENTER,
          isBottom: modalPosition === MODAL_POSITIONS.BOTTOM,
        })}
      >
        <div
          ref={mainElementRef}
          className={cx("GuestModalContainer", containerClassname, {
            slideIn: openSlide,
            slideOut: !openSlide,
            hasExtraComponentContent: extraComponentContent,
          })}
          style={getSlideVariables()}
        >
          {extraComponentContent && extraComponentContent}
          <div
            className={cx("GuestModal", modalClassname, {
              hasOnlyHeader: header && !footer,
              hasOnlyFooter: !header && footer,
              hasNoHeaderAndFooter: !header && !footer,
            })}
          >
            {/*{header && <div className="GuestModalHeader">{header}</div>}*/}
            {/*<div className="GuestModalBody">*/}
            {/*  {body}*/}
            {/*  {footer && <div className="GuestModalFooter">{footer}</div>}*/}
            {/*</div>*/}

            {header && header}
            {body}
            {footer && footer}
          </div>
        </div>
      </div>
    )
  );
};
GuestModal.propTypes = {
  /**
   * The type of the modal (DARK or BLUR)
   */
  type: PropTypes.oneOf([TYPES.BLUR, TYPES.DARK]),

  /**
   * The header content of the modal
   */
  header: PropTypes.node,

  /**
   * The body content of the modal
   */
  body: PropTypes.node,

  /**
   * The footer content of the modal
   */
  footer: PropTypes.node,

  /**
   * The ref for the main element of the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The extra component content to be rendered in the modal
   */
  extraComponentContent: PropTypes.node,

  /**
   * Adding classname for modalBackground component
   */
  className: PropTypes.string,

  /**
   * Adding classname for modal container component
   */
  containerClassname: PropTypes.string,

  /**
   * Adding classname for modal component
   */
  modalClassname: PropTypes.string,

  /**
   * The direction from which the modal will slide (up, down, left, right)
   */
  slideDirection: PropTypes.oneOf(Object.values(POSITION_TYPES)),

  /**
   * The position where the modal will appear (Center or Bottom)
   */
  modalPosition: PropTypes.oneOf(Object.values(MODAL_POSITIONS)),
};

export default GuestModal;
