import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./IconButton.scss";

export const ENUMS = {
  name: "IconButton",
  types: {
    TYPE_SMALL: "TYPE_SMALL",
    TYPE_SM: "TYPE_SM",
    TYPE_MEDIUM: "TYPE_MEDIUM",
  },
};

const IconButton = ({
  type = ENUMS.types.TYPE_SMALL,
  onClick,
  className,
  svgComponent,
}) => {
  return (
    <button
      className={cx("IconButton", className, {
        SmallIconButton: !type || type === ENUMS.types.TYPE_SMALL,
        typeSM: type === ENUMS.types.TYPE_SM,
        MediumIconButton: type === ENUMS.types.TYPE_MEDIUM,
      })}
      onClick={onClick}
      type="button"
    >
      {svgComponent}
    </button>
  );
};

IconButton.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf(Object.keys(ENUMS.types)),

  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,

  /**
   * Custom className for additional styling
   */
  className: PropTypes.string,

  /**
   * The SVG component to be displayed within the button.
   */
  svgComponent: PropTypes.node,
};

export default IconButton;
