import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useAsync from "utils/hooks/useAsync";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { ErrorMessage } from "@hookform/error-message";
import { forgotUserPassword } from "utils/api/services/user";
import InputControl from "components/admin/forms/input-control/InputControl";
import { ROUTE_NAME } from "utils/constants/routes";
import { ENUMS as ENUMS_PASSWORD_INPUT } from "components/forms/input/input-password/PasswordInput";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import EmailOrPhone from "components/elements/email-or-phone/EmailOrPhone";

const SetNewPassword = ({ otpToken, setSignInMethod, signInMethod }) => {
  const { t } = useTranslation();
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const navigate = useNavigate();

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useFormContext();

  const {
    execute: executeForgotUserPassword,
    loading: isForgotPasswordLoading,
  } = useAsync(forgotUserPassword, {
    onError: (error) => {
      const errorData = handleAPIErrorMessage(error.response.data);
      if (errorData) {
        const { field, errorMessage } = errorData;
        setError(field, {
          type: "manual",
          message: errorMessage,
        });
      }
    },
    onSuccess: () => {
      navigate(`${ROUTE_NAME.signIn}`);
    },
  });

  const handleOnSubmit = () => {
    const { password } = getValues();
    executeForgotUserPassword({
      otpToken,
      body: {
        password,
      },
    });
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <EmailOrPhone
        signInMethod={signInMethod}
        setSignInMethod={setSignInMethod}
        readOnly={true}
        hideSignInMethod
        className="ForgotPasswordEmailOrPhone"
      />
      <InputControl
        name="password"
        type="password"
        placeholder={t("inputs.newPassword")}
        hasError={errors.password}
        required
        func={{
          ...register("password"),
        }}
        labelType={ENUMS_PASSWORD_INPUT.types.TYPE_B}
        error={
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="h7 error-message">{message}</p>
            )}
          />
        }
      />
      <PrimaryButton
        onClick={handleSubmit(handleOnSubmit)}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
        text={t("buttons.continue")}
        isLoading={isForgotPasswordLoading}
        className="ForgotPasswordFormButton"
      />
    </form>
  );
};

SetNewPassword.propTypes = {
  otpToken: PropTypes.string.isRequired,
  signInMethod: PropTypes.string,
  setSignInMethod: PropTypes.func,
};

export default SetNewPassword;
