import {
  AXIOS_GUEST,
  AXIOS_GUEST_NO_AUTH,
  AXIOS_USER,
  BACKEND_BASE_URL,
} from "utils/api/axios";
import { API_PATH_PARAMS, ENDPOINTS, QUERY_PARAMS } from "utils/constants/api";

export const createAnonymousGuest = async () =>
  AXIOS_GUEST_NO_AUTH.post(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${API_PATH_PARAMS.anonymous}`
  );

export const verifyGuestOtpCode = async ({ otpCode, otpToken }) =>
  AXIOS_GUEST_NO_AUTH.post(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${API_PATH_PARAMS.otp}/${API_PATH_PARAMS.verify}?${QUERY_PARAMS.code}=${otpCode}&${QUERY_PARAMS.otpToken}=${otpToken}`
  );

export const registerGuest = async ({ guestId, otpToken, guestBody }) =>
  AXIOS_GUEST_NO_AUTH.post(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${guestId}?${QUERY_PARAMS.otpToken}=${otpToken}`,
    guestBody
  );

export const forgotGuestPassword = async ({ otpToken, body }) =>
  AXIOS_GUEST_NO_AUTH.post(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${API_PATH_PARAMS.forgotPassword}?${QUERY_PARAMS.otpToken}=${otpToken}`,
    body
  );

export const loginGuest = async ({ guestBody }) =>
  AXIOS_GUEST_NO_AUTH.post(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${API_PATH_PARAMS.login}`,
    guestBody
  );

export const updateGuestInfo = async ({ guestBody, guestId }) =>
  AXIOS_GUEST.patch(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${guestId}`,
    guestBody
  );

export const getAllGuests = async (pageNumber, searchText) => {
  const hasSearchText = searchText && searchText.length > 0;
  const queryParams = hasSearchText
    ? `&${QUERY_PARAMS.searchValue}=${searchText}`
    : "";

  return AXIOS_GUEST.get(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${QUERY_PARAMS.all}?${QUERY_PARAMS.pagination.number}=${pageNumber}${queryParams}&pageSize=50`
  );
};

export const getGuestDetailsMetadata = async ({ businessId, guestId }) => {
  return AXIOS_GUEST.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.statistics}/${API_PATH_PARAMS.guests}/${guestId}/${API_PATH_PARAMS.metadata}`
  );
};
export const getGuestOrderDetails = async ({ businessId, guestId }) => {
  return AXIOS_GUEST.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.statistics}/${API_PATH_PARAMS.guests}/${guestId}/${API_PATH_PARAMS.orders}`
  );
};

export const getBusinessGuests = async ({
  pageNumber,
  businessId,
  pageSize,
  sortBy,
  sortDir,
  hasSignedUp,
  searchValue,
}) => {
  let url = `/${API_PATH_PARAMS.business}/${businessId}${ENDPOINTS.statistics}/${API_PATH_PARAMS.guests}?`;

  const params = [
    { key: QUERY_PARAMS.pagination.size, value: pageSize },
    { key: QUERY_PARAMS.pagination.number, value: pageNumber },
    { key: QUERY_PARAMS.sort.by, value: sortBy },
    { key: QUERY_PARAMS.sort.dir, value: sortDir },
    { key: QUERY_PARAMS.hasSignedUp, value: hasSignedUp },
    { key: QUERY_PARAMS.searchValue, value: searchValue },
  ];
  const filteredParams = params.filter(
    (param) => param.value !== undefined && param.value !== ""
  );

  url += filteredParams.map((param) => `${param.key}=${param.value}`).join("&");

  return AXIOS_USER.get(url);
};

export const getGuestByToken = async () =>
  AXIOS_GUEST.get(`/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}`);

export const resetGuestPassword = async ({ guestBody, guestId }) =>
  AXIOS_GUEST.post(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${guestId}/${API_PATH_PARAMS.resetPassword}`,
    guestBody
  );

export const enterWithFacebook = `${BACKEND_BASE_URL}/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.facebook}`;
