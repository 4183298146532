import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useLanguage from "utils/hooks/useLanguage";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import ModificationRadioButton from "components/buttons/modification-radio-button/ModificationRadioButton";

import "./MenuItemCard.scss";

const MenuItemCard = ({ menuItem, onSelect, isSelected }) => {
  const discountPrice = menuItem && calculateMenuItemPriceBySchedule(menuItem);
  const menuItemPrice =
    calculateItemPriceWithDefaultModificationPrice(menuItem);
  const { displayDataByLanguage } = useLanguage();

  const handleSelectItem = () => {
    onSelect(menuItem?.id);
  };
  return (
    <div
      className={cx("MenuItemCard", {
        isSelected: isSelected,
      })}
      onClick={handleSelectItem}
    >
      <div className="MenuItemCardImg">
        <div className="MenuItemCardCloseButton"></div>
        <ImageWithPlaceholder
          imageSource={createDOBucketName(menuItem.coverImageSrc)}
          placeholder={IMAGE_ITEM_PLACEHOLDER}
          alt={displayDataByLanguage(menuItem.name)}
        />
      </div>
      <div className="MenuItemCardInfo">
        <h6 className="MenuItemCardName h8 Medium">
          {displayDataByLanguage(menuItem.name)}
        </h6>
        <div className="MenuItemCardInfoBottom">
          <div className="h7 SemiBold MenuItemCardPrice">
            <Price
              value={
                discountPrice !== null
                  ? menuItemPrice + discountPrice
                  : menuItemPrice
              }
              type={ENUMS_PRICE.types.PRICE_XS}
            />
            <ModificationRadioButton count={isSelected} />
          </div>
        </div>
      </div>
    </div>
  );
};

MenuItemCard.propTypes = {
  /**
   * The menu item object with its details.
   */
  menuItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    description: PropTypes.array,
    coverImageSrc: PropTypes.string,
    otherImagesSrc: PropTypes.array,
    priceSell: PropTypes.number.isRequired,
    tags: PropTypes.array,
    modifications: PropTypes.array,
  }).isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default MenuItemCard;
