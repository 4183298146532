import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ICON_EYE_OPEN from "assets/icons/eye/EyeOpen.svg";
import ICON_EYE_CLOSE from "assets/icons/eye/EyeClose.svg";
import ICON_INFO from "assets/icons/other/Info.svg";
import useDisableNumberInputScroll from "utils/hooks/useDisableNumberInputScroll";

import "./PasswordInput.scss";

export const ENUMS = {
  name: "InputPassword",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const PasswordInput = forwardRef(
  (
    {
      value,
      defaultValue,
      onChange,
      required,
      placeholder,
      className,
      name,
      definitionText,
      isBorderless,
      hasError,
      onBlur,
      onFocus,
      noLabelFloating,
      hasLabel,
      infoText,
      func,
      error,
      readOnly,
      labelType,
      maxLength,
      min,
      labelTopClassName,
      requiredSignTopClassName,
      inputClassName,
      labelClassName,
      requiredSignClassName,
      definitionClassName,
      infoTextClassName,
      infoTextTooltipClassName,
      containerClassName,
      autoComplete = "off",
      onKeyDown,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    useDisableNumberInputScroll();

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className={cx("InputPasswordContainer", containerClassName)}>
        <div className={cx("InputPassword", className)}>
          {hasLabel && (
            <h6
              className={cx("Medium InputPasswordLabelTop", labelTopClassName, {
                noFloating: noLabelFloating,
              })}
            >
              {placeholder}
              {required && (
                <span
                  className={cx(
                    "InputPasswordRequiredSign",
                    requiredSignTopClassName
                  )}
                >
                  *
                </span>
              )}
            </h6>
          )}
          <input
            ref={ref}
            value={value}
            min={min}
            defaultValue={defaultValue}
            disabled={readOnly}
            onChange={onChange}
            placeholder={placeholder}
            className={cx("InputPasswordInput", inputClassName, {
              hasExtraPadding: definitionText,
              isBorderless: isBorderless,
              hasError: hasError,
              noFloating: noLabelFloating,
              hasLabel: hasLabel,
              isReadOnly: readOnly,
              infoText: infoText,
            })}
            {...func}
            type={showPassword ? "text" : "password"}
            name={name}
            onBlur={onBlur}
            onFocus={onFocus}
            autoComplete={autoComplete}
            maxLength={maxLength}
            onKeyDown={onKeyDown}
          />
          <h6
            className={cx("Medium InputPasswordLabel", labelClassName, {
              noFloating: noLabelFloating,
              hasError: hasError,
              typeA: labelType === ENUMS.types.TYPE_A,
              typeB: labelType === ENUMS.types.TYPE_B,
            })}
          >
            {placeholder}
            {required && (
              <span
                className={cx(
                  "InputPasswordRequiredSign",
                  requiredSignClassName
                )}
              >
                *
              </span>
            )}
          </h6>
          {definitionText && (
            <h6 className={cx("InputPasswordDefinition", definitionClassName)}>
              {definitionText}
            </h6>
          )}
          {infoText && (
            <div className={cx("InputPasswordInfoText", infoTextClassName)}>
              <img src={ICON_INFO} alt="Info" />
              <div className="InputPasswordInfoTextTooltip">
                <h6>{infoText}</h6>
              </div>
            </div>
          )}
          <div
            className={cx("InputPasswordPasswordEye", infoTextTooltipClassName)}
            onClick={toggleShowPassword}
          >
            {!showPassword ? (
              <img src={ICON_EYE_CLOSE} alt="hide password" />
            ) : (
              <img src={ICON_EYE_OPEN} alt="show password" />
            )}
          </div>
        </div>
        {error}
      </div>
    );
  }
);

PasswordInput.displayName = "InputPassword";

PasswordInput.propTypes = {
  /**
   * The current value of the input
   */
  value: PropTypes.any,

  /**
   * The default value of the input
   */
  defaultValue: PropTypes.any,

  /**
   * Callback function to handle input change events
   */
  onChange: PropTypes.func,

  /**
   * Indicates whether the input is required
   */
  required: PropTypes.bool,

  /**
   * Placeholder text for the input
   */
  placeholder: PropTypes.string,

  /**
   * Additional class name for custom styling
   */
  className: PropTypes.string,

  /**
   * The name attribute for the input element
   */
  name: PropTypes.string,

  /**
   * The text for the definition
   */
  definitionText: PropTypes.string,

  /**
   * Indicates whether the input should have no border
   */
  isBorderless: PropTypes.bool,

  /**
   * Error message to display
   */
  hasError: PropTypes.object,

  /**
   * Callback function to handle input blur events
   */
  onBlur: PropTypes.func,

  /**
   * Callback function to handle input focus events
   */
  onFocus: PropTypes.func,

  /**
   * Indicates whether the label should not float when there is an input value
   */
  noLabelFloating: PropTypes.bool,

  /**
   * Indicates whether the input should have a label
   */
  hasLabel: PropTypes.bool,

  /**
   * Text for the information tooltip
   */
  infoText: PropTypes.string,

  /**
   * Additional function to pass to the input
   * (e.g., onKeyDown, onClick)
   */
  func: PropTypes.object,

  /**
   * Error message to display
   * (e.g., for form validation)
   */
  error: PropTypes.object,

  /**
   * Indicates whether the input is read-only
   */
  readOnly: PropTypes.bool,

  labelType: PropTypes.string,

  maxLength: PropTypes.number,

  min: PropTypes.string,

  /**
   * Additional class for container
   */
  containerClassName: PropTypes.string,

  /**
   * Additional class for label at the top
   */
  labelTopClassName: PropTypes.string,

  /**
   * Additional class for the required sign at the top
   */
  requiredSignTopClassName: PropTypes.string,

  /**
   * Additional class for the input element
   */
  inputClassName: PropTypes.string,

  /**
   * Additional class for the label element
   */
  labelClassName: PropTypes.string,

  /**
   * Additional class for the required sign element
   */
  requiredSignClassName: PropTypes.string,

  /**
   * Additional class for the definition text
   */
  definitionClassName: PropTypes.string,

  /**
   * Additional class for the info text element
   */
  infoTextClassName: PropTypes.string,

  /**
   * Additional class for the info text tooltip
   */
  infoTextTooltipClassName: PropTypes.string,

  /**
   * PropType for autoComplete
   */
  autoComplete: PropTypes.string,
  /**
   * Callback function to handle keydown events
   */
  onKeyDown: PropTypes.func,
};

export default PasswordInput;
