import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as IconSearch } from "assets/icons/form/Search.svg";
import ICON_FILTER from "assets/icons/form/Filter.svg";
import { ReactComponent as Close } from "assets/icons/close/CloseThin.svg";

import "./Search.scss";

export const ENUMS = {
  name: "Search",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
  },
};

const Search = ({
  onClick,
  type,
  value,
  onChange,
  onFilter,
  placeholder = "Search...",
  classname,
}) => {
  return (
    <form
      className={cx(
        "Search",
        {
          typeA: type === ENUMS.types.TYPE_A,
          typeB: type === ENUMS.types.TYPE_B,
          typeC: type === ENUMS.types.TYPE_C,
          typeD: type === ENUMS.types.TYPE_D,
        },
        classname
      )}
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="SearchLeftSide">
        <div className="SearchIconContainer">
          <IconSearch />
        </div>
        <input
          className="SearchInput Medium"
          value={value}
          type="text"
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
      {type === ENUMS.types.TYPE_A && (
        <div className="SearchRightSide">
          <button onClick={onFilter} type="button">
            <img src={ICON_FILTER} alt="filter" />
          </button>
        </div>
      )}
      {type === ENUMS.types.TYPE_C && (
        <div className="SearchRightSideC" onClick={onClick}>
          <button
            className="SearchRightSideCBtn"
            onClick={onFilter}
            type="button"
          >
            <Close />
          </button>
        </div>
      )}
    </form>
  );
};

Search.propTypes = {
  onClick: PropTypes.func,
  /**
   * The type of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * The value of input
   */
  value: PropTypes.string.isRequired,

  /**
   * The change event of input
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The click event of filter button
   */
  onFilter: PropTypes.func,

  /**
   * The input placeholder
   */
  placeholder: PropTypes.string,

  /**
   * The CSS class name for the component
   */
  classname: PropTypes.string,
};

export default Search;
