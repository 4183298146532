import React from "react";
import PropTypes from "prop-types";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import { getBasketModificationsWithDefaultValues } from "utils/helpers";
import AddButton, {
  ENUMS as ENUMS_ADD_BUTTON,
} from "components/admin/buttons/add-button/AddButton";
import useLanguage from "utils/hooks/useLanguage";

import "./OrderMenuItem.scss";

export const ENUMS = {
  name: "OrderMenuItem",
};

const OrderMenuItem = ({ item, onClick, onAdd }) => {
  const discountPrice = calculateMenuItemPriceBySchedule(item);
  const menuItemPrice = calculateItemPriceWithDefaultModificationPrice(item);

  const { displayDataByLanguage } = useLanguage();
  const handleOnAdd = (e) => {
    e.stopPropagation();
    const defaultModifications = getBasketModificationsWithDefaultValues(
      item.modifications
    );
    onAdd({
      item: { ...item, modifications: defaultModifications },
      count: 1,
    });
  };
  return (
    <div className="OrderMenuItem" onClick={() => onClick(item)}>
      <h5 className=" Medium">{displayDataByLanguage(item.name)}</h5>
      <div className="OrderMenuItemBottom">
        <div className="OrderMenuItemPrice">
          {discountPrice !== null && (
            <Price
              type={ENUMS_PRICE.types.PRICE_LINE_THROUGH}
              value={menuItemPrice}
            />
          )}
          <Price
            type={ENUMS_PRICE.types.PRICE_XS}
            value={
              discountPrice !== null
                ? menuItemPrice + discountPrice
                : menuItemPrice
            }
          />
        </div>

        <div className="OrderMenuItemBottomAdd">
          <AddButton
            onClick={handleOnAdd}
            type={ENUMS_ADD_BUTTON.types.TYPE_A}
          />
        </div>
      </div>
    </div>
  );
};

OrderMenuItem.propTypes = {
  /**
   * The item object to display.
   */
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    coverImageSrc: PropTypes.string,
    priceSell: PropTypes.number.isRequired,
    modifications: PropTypes.array.isRequired,
    rate: PropTypes.object,
  }).isRequired,

  /**
   * Click event handler when the component is clicked.
   */
  onClick: PropTypes.func.isRequired,

  /**
   * The onAdd function to be called when adding an item.
   */
  onAdd: PropTypes.func,
};

export default OrderMenuItem;
