import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import cx from "classnames";

import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as IconNewOrder } from "assets/icons/waiter/newOrder.svg";
import { ReactComponent as IconNewChat } from "assets/icons/waiter/chat.svg";
import { ReactComponent as IconCallWaiter } from "assets/icons/waiter/runWaiter.svg";
import { SHAPE_ENUMS } from "utils/constants/data/base";

import "./AdminMapViewTable.scss";

const calculateMessageStatus = (currentTopic, user) => {
  const lastMessageTime = currentTopic?.messages?.slice(-1)[0]?.dateTime;
  const lastReadTime = currentTopic?.usersLastReadTime?.find(
    (item) => item.userId === user.id
  )?.dateTime;
  return lastMessageTime && (!lastReadTime || lastReadTime < lastMessageTime);
};

const AdminMapViewTable = ({ tableData, orderInTable, onClickTable }) => {
  const { id, x, y, width, height, name, shape } = tableData;
  const activeOrder = orderInTable(id);
  const { topics: chat } = useSelector((state) => state[STORE_NAMES.chat]);
  const { user } = useSelector((state) => state[STORE_NAMES.user]);
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const currentTopic = useMemo(
    () => chat?.find((topic) => topic.id === activeOrder?.id),
    [chat, activeOrder]
  );
  const hasNewMessage = useMemo(
    () => business.chat && calculateMessageStatus(currentTopic, user),
    [currentTopic, user]
  );
  const orderGuestCount = activeOrder?.guests?.length || 0;
  const isWaiterCalled = !!(
    (activeOrder?.actionBill && business.isAskForBillEnabled) ||
    (activeOrder?.actionWaiter && business.isCallWaiterEnabled)
  );
  const hasNewOrder = !!activeOrder?.hasNewOrder;

  const animationLevel = [hasNewOrder, hasNewMessage, isWaiterCalled].filter(
    Boolean
  ).length;

  return (
    <div
      className={cx("AdminMapViewTable", {
        orderInTable: !!activeOrder,
        isAnimated: animationLevel > 0,
        isRectangle: shape === SHAPE_ENUMS.RECTANGLE,
        isCircle: shape === SHAPE_ENUMS.CIRCLE,
      })}
      onClick={() => onClickTable(activeOrder?.table || { id, name })}
      style={{
        position: "absolute",
        left: `${x}px`,
        top: `${y}px`,
        width: `${width}px`,
        height: `${height}px`,
        // animationName: "pulse" + animationLevel,
        // animationDuration: (3 / animationLevel) * 0.6 + "s",
      }}
    >
      <h6 className="AdminMapViewTableName Bold">{name}</h6>
      <div className="AdminMapViewTableActions">
        <div
          className={cx("AdminMapViewTableAction", {
            isActive: orderGuestCount > 0,
          })}
        >
          <h6 className="Bold">{orderGuestCount}</h6>
        </div>
        <div
          className={cx("AdminMapViewTableAction", { isActive: hasNewOrder })}
        >
          <IconNewOrder />
        </div>
        {business.chat && (
          <div
            className={cx("AdminMapViewTableAction", {
              isActive: hasNewMessage,
            })}
          >
            <IconNewChat />
          </div>
        )}
        {(business.isCallWaiterEnabled || business.isAskForBillEnabled) && (
          <div
            className={cx("AdminMapViewTableAction", {
              isActive: isWaiterCalled,
            })}
          >
            <IconCallWaiter />
          </div>
        )}
      </div>
    </div>
  );
};

AdminMapViewTable.propTypes = {
  tableData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    shape: PropTypes.number,
  }).isRequired,
  orderInTable: PropTypes.func.isRequired,
  onClickTable: PropTypes.func.isRequired,
};
export default AdminMapViewTable;
