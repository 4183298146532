import Modal from "components/modal/Modal";
import SelectUserBusiness from "./SelectUserBusiness";
import React, { useState } from "react";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import { useTranslation } from "react-i18next";
import { setBusiness } from "redux/slices/businessStore";
import { ROUTE_NAME } from "utils/constants/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { selectBusinessInSignin } from "redux/actions/userAction";
import { getUserRoutesByRole } from "utils/helpers";
import { toast } from "react-toastify";

const SelectUserBusinessModal = ({
  showModal,
  businessLists,
  setSelectedBusiness,
  selectedBusiness,
  mainElementBusinessModal,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSelectedBusinessLoading, setIsSelectedBusinessLoading] =
    useState(false);
  const handleSelectBusiness = async () => {
    setIsSelectedBusinessLoading(true);
    const response = await dispatch(
      selectBusinessInSignin({ businessId: selectedBusiness.id })
    );
    if (response.error) {
      toast.error(response?.payload?.data);
    } else {
      const userRoutesInfo = getUserRoutesByRole(
        response.payload?.user?.roles[0]?.name
      );
      await dispatch(
        setBusiness({
          id: selectedBusiness?.id,
          printers: selectedBusiness?.printers,
          name: selectedBusiness?.name,
        })
      );

      await navigate(
        `${ROUTE_NAME.admin}${userRoutesInfo.firstRouteAfterSignIn}`
      );
    }
    await setIsSelectedBusinessLoading(false);
  };

  //TODO: navigate contact us
  const AdminMapTableModalFooter = selectedBusiness?.isArchived ? (
    <PrimaryButton
      onClick={() => null}
      isDisabled={!selectedBusiness}
      text={t("buttons.contactUs")}
    />
  ) : (
    <PrimaryButton
      onClick={handleSelectBusiness}
      isDisabled={!selectedBusiness}
      text={t("buttons.continue")}
      isLoading={isSelectedBusinessLoading}
    />
  );
  return (
    <Modal
      openSlide={showModal}
      body={
        <SelectUserBusiness
          userBusinesses={businessLists}
          setSelectedBusiness={setSelectedBusiness}
          selectedBusiness={selectedBusiness}
          title={t("business.selectTitle")}
          subtitle={t("business.selectSubTitle")}
        />
      }
      mainElementRef={mainElementBusinessModal}
      footer={AdminMapTableModalFooter}
    />
  );
};

SelectUserBusinessModal.propTypes = {
  showModal: PropTypes.bool,
  businessLists: PropTypes.array,
  setSelectedBusiness: PropTypes.func,
  selectedBusiness: PropTypes.object,
  mainElementBusinessModal: PropTypes.node,
};

export default SelectUserBusinessModal;
