import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ModificationRadioButton from "components/buttons/modification-radio-button/ModificationRadioButton";
import AnimatedPlusMinusButton, {
  ENUMS as PLUS_MINUS_BUTTON_ENUMS,
} from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";
import Price, { ENUMS as PRICE_ENUMS } from "components/elements/price/Price";
import useLanguage from "utils/hooks/useLanguage";

import "./ModificationItem.scss";

export const ENUMS = {
  name: "ModificationItem",
  types: {
    COUNTABLE: "COUNTABLE",
    UNCOUNTABLE: "UNCOUNTABLE",
  },
  themeButton: {
    NEUMORPHISM: "NEUMORPHISM",
    FLAT: "FLAT",
  },
  themeText: {
    LIGHT_M_WHITE: "LIGHT_M_WHITE",
    LIGHT_M_BLACK: "LIGHT_M_BLACK",
  },
};

const ModificationItem = ({
  className,
  onPlus,
  onMinus,
  onClick,
  count,
  maxCount,
  option,
  themeText = ENUMS.themeText.LIGHT_M_BLACK,
  focusedModificationOption,
  isReadOnly,
}) => {
  const { displayDataByLanguage } = useLanguage();
  const doShowPlusMinusButtons = () => {
    return (
      option.id === focusedModificationOption && option.isCountable && count > 0
    );
  };

  const handleOnPlus = (e) => {
    e.stopPropagation();
    onPlus();
  };

  const handleOnMinus = (e) => {
    e.stopPropagation();
    onMinus();
  };

  return (
    <div
      className={cx("ModificationItem", className, {
        lightWhite: themeText === ENUMS.themeText.LIGHT_M_WHITE,
        lightBlack: themeText === ENUMS.themeText.LIGHT_M_BLACK,
        cursorDefault: doShowPlusMinusButtons(),
        isReadOnly: isReadOnly,
      })}
      onClick={onClick}
    >
      <div className="ModificationItemLabelContainer">
        <ModificationRadioButton
          count={count}
          isCountable={option.isCountable}
        />
        <span>{displayDataByLanguage(option.name)}</span>
      </div>
      {doShowPlusMinusButtons() ? (
        <AnimatedPlusMinusButton
          onMinusClick={handleOnMinus}
          onPlusClick={handleOnPlus}
          count={count}
          maxCount={Number(maxCount)}
          doAnimate
          type={PLUS_MINUS_BUTTON_ENUMS.types.MODIFICATION}
        />
      ) : (
        <Price
          value={
            option.priceSell && option.priceSell !== "" ? option.priceSell : 0
          }
          type={PRICE_ENUMS.types.PRICE_MODIFICATION}
        />
      )}
    </div>
  );
};

ModificationItem.propTypes = {
  /**
   *  Dynamic classname given to component
   */
  className: PropTypes.string,

  /**
   * onPlus  function of the component
   */
  onPlus: PropTypes.func,

  /**
   *  onMinus function  of the component
   */
  onMinus: PropTypes.func,

  /**
   *  onClick function of the component
   */
  onClick: PropTypes.func.isRequired,

  /**
   *  count of the orders
   */
  count: PropTypes.number.isRequired,

  /**
   *  max count of the orders
   */
  maxCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   *  showPlusMinusButtons
   */
  showPlusMinusButtons: PropTypes.bool,

  /**
   *  themeText of the component
   */
  themeText: PropTypes.oneOf([
    ENUMS.themeText.LIGHT_M_WHITE,
    ENUMS.themeText.LIGHT_M_BLACK,
  ]),
  option: PropTypes.object.isRequired,
  focusedModificationOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),

  /**
   * A boolean flag indicating whether the component is read-only
   */
  isReadOnly: PropTypes.bool,
};

export default ModificationItem;
