import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";

import "./GuestProfiles.scss";

export const ENUMS = {
  name: "GuestProfiles",
};

const GuestProfileCard = ({ participant, isActive, handleGuestClick }) => {
  const { t } = useTranslation();
  const { name, id, originalId, profilePic, orderAmountsToBePaid } =
    participant;

  return (
    <div
      className={cx("GuestProfile", {
        isActive,
      })}
      onClick={() => handleGuestClick(id)}
    >
      <img alt={name} src={profilePic} />
      <div className="GuestProfileIdentity">
        <div className="GuestProfileName">
          <h6 className="Medium">
            {participant?.isMe
              ? t("dashboard.guest.me")
              : name || t("dashboard.guest.guest")}
          </h6>
          <h6 className="h7 GuestPersonId">{!name && originalId}</h6>
        </div>
        <h6 className="Bold GuestTotalPrice">
          <Price
            value={orderAmountsToBePaid}
            type={ENUMS_PRICE.types.PRICE_S}
          />
        </h6>
      </div>
    </div>
  );
};
GuestProfileCard.propTypes = {
  participant: PropTypes.object,
  isActive: PropTypes.string,
  handleGuestClick: PropTypes.func,
};
const GuestProfiles = ({
  allParticipants,
  handleSelectGuest,
  selectedGuests,
}) => {
  const handleGuestClick = (generatedGuestId) => {
    handleSelectGuest(generatedGuestId);
  };
  return (
    <div className="GuestProfiles">
      {allParticipants.map((participant, index) => {
        const isActive = selectedGuests.find(
          (selectedGuestId) => participant.id === selectedGuestId
        );
        return (
          <GuestProfileCard
            key={index}
            participant={participant}
            isActive={isActive}
            handleGuestClick={handleGuestClick}
          />
        );
      })}
    </div>
  );
};

GuestProfiles.propTypes = {
  allParticipants: PropTypes.array,
  selectedGuests: PropTypes.array.isRequired,
  handleSelectGuest: PropTypes.func,
};

export default GuestProfiles;
