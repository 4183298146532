import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Modal from "components/modal/Modal";
import { ReactComponent as ExclamationIcon } from "assets/icons/other/Exclamation.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";

import "./AccessDeniedModal.scss";

const AccessDeniedModal = ({ openSlide, mainElementRef, onClose }) => {
  const { t } = useTranslation();
  const AccessDeniedModalBody = (
    <div className="AccessDeniedModalBody">
      <div className="AccessDeniedModalBodyIcon">
        <ExclamationIcon />
      </div>
      <div className="AccessDeniedModalBodyContent">
        <h4 className="Medium">{t("modal.accessDenied")}</h4>
        <h6>{t("modal.permissionRequired")}</h6>
      </div>
    </div>
  );
  const AccessDeniedModalFooter = (
    <div className="AccessDeniedModalFooter">
      <PrimaryButton
        type="TYPE_D"
        onClick={onClose}
        text={t("buttons.close")}
        className="onSuccessAiTranslateModalFooter"
      />
    </div>
  );

  return (
    <Modal
      footer={AccessDeniedModalFooter}
      body={AccessDeniedModalBody}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

AccessDeniedModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mainElementRef: PropTypes.object,
};

export default AccessDeniedModal;
