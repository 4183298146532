import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowDown.svg";
import { ReactComponent as BirthdayHat } from "assets/icons/birthday/hat.svg";
import {
  concatFirstNameWithLastName,
  isGuestOrUserHasAnyOrder,
  checkDateEqualCurrentDate,
} from "utils/helpers";
import OrderItemModificationList from "components/admin/cards/order-item-modification-list/OrderItemModificationList";
import AdminOrderGeneralInfo from "../admin-order-general-info/AdminOrderGeneralInfo";
import useLanguage from "utils/hooks/useLanguage";
import {
  calculateTotalDiscountPriceOrderItems,
  calculateTotalPriceOrderItems,
  calculateTotalServiceFeeOrderItems,
  calculateTotalSubtotalOrderItems,
} from "utils/helper-functions/orderHelper";
import PaidInfo from "pages/admin/admin-pages/admin-order/admin-order-info/paid-info/PaidInfo";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import useOutsideClick from "utils/hooks/useOutsideClick";
import AdminOrderGuestInfoModal from "pages/admin/admin-pages/admin-order/admin-order-map/admin-order-guest-info-modal/AdminOrderGuestInfoModal";
import { sortActiveGuest } from "utils/helper-functions/dashboard/activeGuestHelper";

import "./AdminOrderInfo.scss";

const AdminOrderInfo = ({
  guests,
  users,
  // handleOnUpdateOrder,
  formData,
  pendingData,
  setPendingData,
}) => {
  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();
  // const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );

  // const initialData = {
  //   users: [],
  //   rate: {
  //     isFixed: false,
  //     amount: 0,
  //   },
  // };

  // const paymentOptions = Object.values(enums.paymentMethods).map(
  //   (item, index) => {
  //     return {
  //       id: index,
  //       name: item,
  //     };
  //   }
  // );
  // const [orderInfo] = useState(initialData);

  // Discount State
  // const [discountValue, setDiscountValue] = useState({
  //   isFixed: false,
  //   amount: 0,
  // });

  const [showOrderDetail, setShowOrderDetail] = useState(true);
  const [orderDetailsHeight, setOrderDetailsHeight] = useState(0);
  const [paidOrdersDetailsHeight, setPaidOrdersDetailsHeight] = useState(0);
  const [guestId, setGuestId] = useState(null);
  const OrderDetailRef = useRef(null);
  const PaidOrdersRef = useRef(null);

  useLayoutEffect(() => {
    const orderDetailObserver = new ResizeObserver(() => {
      if (OrderDetailRef.current) {
        setOrderDetailsHeight(OrderDetailRef.current.clientHeight);
      }
    });

    const paidOrdersObserver = new ResizeObserver(() => {
      if (PaidOrdersRef.current) {
        setPaidOrdersDetailsHeight(PaidOrdersRef.current.clientHeight);
      }
    });

    // Observe the elements for resizing
    if (OrderDetailRef.current) {
      orderDetailObserver.observe(OrderDetailRef.current);
    }

    if (PaidOrdersRef.current) {
      paidOrdersObserver.observe(PaidOrdersRef.current);
    }

    return () => {
      // Clean up observers on component unmount
      orderDetailObserver.disconnect();
      paidOrdersObserver.disconnect();
    };
  }, [guests, users]);

  const { PaymentStatus } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const paidOrderItems = useMemo(() => {
    if (!formData) return [];
    if (!formData.orderTransactions) return [];
    return formData.orderTransactions
      .filter(
        (transaction) => transaction.paymentStatus === PaymentStatus.success
      )
      .flatMap(({ transactionItems }) => transactionItems)
      .flatMap(({ externalId }) => externalId);
  }, [formData]);

  const allOrderItems = useMemo(() => {
    const allOrderItems = [...formData.guests, ...formData.users].flatMap(
      ({ orderItems }) => orderItems
    );

    return allOrderItems;
  }, [formData.guests, formData.users]);

  const approvedTransactions = formData.orderTransactions.filter(
    (orderTransaction) =>
      orderTransaction.paymentStatus === PaymentStatus.success
  );

  const orderItemsToBePaid = allOrderItems.filter((orderItem) => {
    return orderItem.isConfirmed && !paidOrderItems.includes(orderItem.id);
  });

  const totalAmountToBePaid = calculateTotalPriceOrderItems(orderItemsToBePaid);
  const totalServiceFeeToBePaid =
    calculateTotalServiceFeeOrderItems(orderItemsToBePaid);
  const totalSubtotalToBePaid =
    calculateTotalSubtotalOrderItems(orderItemsToBePaid);
  const totalDiscountToBePaid =
    calculateTotalDiscountPriceOrderItems(orderItemsToBePaid);
  const isOrderItemsAdded = isGuestOrUserHasAnyOrder(guests, users);

  //Discount Functions
  // const handleOnChangeDiscountType = (e) => {
  //   const { value } = e.target;
  //   setDiscountValue({
  //     ...discountValue,
  //     isFixed: value === "true",
  //   });
  // };
  //
  // const handleOnRateConfirm = () => {
  //   // const updatedFormData = {
  //   //   ...formData,
  //   //   rate: {
  //   //     ...discountValue,
  //   //     amount:
  //   //       Number(discountValue.amount) >= 0 ? Number(discountValue.amount) : "",
  //   //   },
  //   // };
  //   //
  //   // setFormData(updatedFormData);
  //   // setOpenSlide(false);
  //   const rate = {
  //     ...discountValue,
  //     amount:
  //       Number(discountValue.amount) >= 0 ? Number(discountValue.amount) : "",
  //   };
  //   handleOnUpdateOrder({ rate });
  //   console.log(rate);
  // };
  //
  // const handleOnRateChange = (e) => {
  //   const { value } = e.target;
  //   setDiscountValue({
  //     ...discountValue,
  //     amount: value && Number(value) >= 0 ? Number(value) : "",
  //   });
  // };

  // Discount Modal Body
  // const DiscountModalBody = (
  //   <form className="AdminOrderPageHomeInfoBoxDiscountModal">
  //     <h5 className="Medium">{t("inputs.discount")}</h5>
  //     <div className="AdminOrderPageHomeInfoBoxDiscount">
  //       <div className="AdminOrderPageHomeInfoBoxDiscountIsFixed">
  //         <RadioSelection
  //           onChange={handleOnChangeDiscountType}
  //           name="isFixed"
  //           isChecked={!discountValue.isFixed}
  //           value={false}
  //           label={t("inputs.notFixed")}
  //         />
  //         <RadioSelection
  //           onChange={handleOnChangeDiscountType}
  //           name="isFixed"
  //           isChecked={discountValue.isFixed}
  //           value={true}
  //           label={t("inputs.fixed")}
  //         />
  //       </div>
  //       <InputControl
  //         type="number"
  //         name="rate"
  //         onChange={handleOnRateChange}
  //         value={discountValue.amount}
  //         placeholder={t("inputs.amount")}
  //         definitionText={formData.rate.isFixed ? undefined : "%"}
  //         className="AdminOrderPageHomeInfoBoxDiscountInput"
  //       />
  //       <HelperText message="The discount cannot be greater than the total price" />
  //     </div>
  //   </form>
  // );

  // Discount Modal Footer
  // const ConfirmFooter = (
  //   <FooterButtons
  //     onConfirm={handleOnRateConfirm}
  //     onCancel={() => setOpenSlide(false)}
  //     cancelButtonText={t("modal.close")}
  //     confirmButtonText={t("modal.apply")}
  //   />
  // );
  // const hasMatchingConfirmedId = allOrderItems.some(
  //   (item) =>
  //     item.isConfirmed === true &&
  //     paidOrderItems.every((paidItem) => paidItem !== item.id)
  // );

  const [
    openSlideGuestInfoModal,
    setOpenSlideGuestInfoModal,
    mainElementRefGuestInfoModal,
  ] = useOutsideClick();

  const handleCloseModal = () => {
    setOpenSlideGuestInfoModal(false);
  };

  const handleOpenModal = (id) => {
    setOpenSlideGuestInfoModal(true);
    setGuestId(id);
  };

  const allGuests = formData.guests;

  const OrderDetails = (
    <div className="AdminOrderPageHomeInfoBoxOrderDetails">
      <div
        className="OrderDetailsButton"
        onClick={() => {
          setShowOrderDetail(!showOrderDetail);
        }}
      >
        <p>{t("dashboard.table.adminOrderHistoryTable.orderDetails")}</p>
        <ArrowIcon
          className={showOrderDetail ? "RotateArrowIcon" : undefined}
        />
      </div>
      <ul className="OrderDetails" ref={OrderDetailRef}>
        {allOrderItems.map((orderItem, index) => {
          if (orderItem.isConfirmed && !paidOrderItems.includes(orderItem.id)) {
            const discountPrice = orderItem.payment.orderItemDiscountPrice;
            const menuItemSubtotalPrice =
              orderItem.payment.orderItemSubtotalPrice;
            const menuItemFinalPrice =
              orderItem.payment.orderItemSubtotalPrice + discountPrice;
            return (
              <li key={index}>
                <div className="AdminOrderInfoDetails">
                  <p style={{ marginRight: "8px" }}>
                    <span>{orderItem.count}x </span>
                    {displayDataByLanguage(orderItem.item.name)}
                  </p>
                  <div className="AdminOrderInfoPrice">
                    {discountPrice !== 0 && (
                      <Price
                        type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                        value={menuItemSubtotalPrice}
                      />
                    )}
                    <Price
                      type={ENUMS_PRICE.types.PRICE_XXS}
                      value={menuItemFinalPrice}
                    />
                  </div>
                </div>

                {orderItem.item.modifications.length > 0 && (
                  <ul className="OrderItemDetailsListModifications">
                    {orderItem.item.modifications.map(
                      (modification) =>
                        modification.options.length > 0 && (
                          <OrderItemModificationList
                            modification={modification}
                            className="OrderItemDetailsListModificationList"
                            key={modification.id}
                            hasPriceInfo
                          />
                        )
                    )}
                  </ul>
                )}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );

  const staticBoxHeight = isOrderItemsAdded ? 215 : 215;
  return (
    <>
      <div
        className="AdminOrderPageHomeInfoBox"
        style={{
          height: !showOrderDetail
            ? `${staticBoxHeight}px`
            : `calc(${
                staticBoxHeight + orderDetailsHeight + paidOrdersDetailsHeight
              }px`,
        }}
      >
        <div className="AdminOrderPageHomeInfoBoxHeader">
          <h3>{t("dashboard.table.adminOrderHistoryTable.receipt")}</h3>
        </div>
        <div className="AdminOrderPageHomeInfoBoxPrice">
          <div className="AdminOrderPageHomeInfoBoxPriceLine">
            <h3 className="SemiBold">{t("basket.order.total")}</h3>
            <Price
              value={totalAmountToBePaid}
              type={ENUMS_PRICE.types.PRICE_L}
            />
          </div>
          <div className="AdminOrderPageHomeInfoBoxPriceLine">
            <h6>{t("basket.order.subtotal")}</h6>
            <Price
              value={totalSubtotalToBePaid}
              type={ENUMS_PRICE.types.PRICE_S_REGULAR}
            />
          </div>
          <div className="AdminOrderPageHomeInfoBoxPriceLine">
            <h6>
              {t("inputs.serviceFee")} ({serviceFee || 0}%)
            </h6>
            <h6>
              {totalServiceFeeToBePaid > 0 && <span>+</span>}

              <Price
                value={totalServiceFeeToBePaid}
                type={ENUMS_PRICE.types.PRICE_S_REGULAR}
              />
            </h6>
          </div>
          <div className="AdminOrderPageHomeInfoBoxPriceLine">
            <h6>{t("inputs.discount")}</h6>
            <h6>
              {totalDiscountToBePaid < 0 && <span>-</span>}
              <Price
                value={Math.abs(totalDiscountToBePaid)}
                type={ENUMS_PRICE.types.PRICE_S_REGULAR}
              />
            </h6>
          </div>
        </div>
        {isOrderItemsAdded && OrderDetails}
        {approvedTransactions.length > 0 && (
          <div
            ref={PaidOrdersRef}
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
          >
            {approvedTransactions.map((orderTransaction) => (
              <PaidInfo
                key={orderTransaction.id}
                orderTransaction={orderTransaction}
                allOrderItems={allOrderItems}
              />
            ))}
          </div>
        )}
      </div>
      <div className="AdminOrderPageHomeInfo">
        <AdminOrderGeneralInfo
          formData={formData}
          pendingData={pendingData}
          setPendingData={setPendingData}
        />
        <div className="AdminOrderPageGuests">
          {sortActiveGuest(allGuests)?.map((guest, index) => {
            const isBirthday = checkDateEqualCurrentDate(guest.dateOfBirth);

            return (
              <div
                key={index}
                className="AdminOrderPageGuest "
                onClick={() => handleOpenModal(guest.person.id)}
              >
                {isBirthday && <BirthdayHat className="BirthdayHat" />}

                <ImageWithPlaceholder
                  imageSource={createDOBucketName(guest.profilePic)}
                  placeholder={IMG_GUEST}
                  alt="logo"
                />
                <h6 className="h7 SemiBold">
                  {concatFirstNameWithLastName({
                    firstName: guest.firstName,
                    lastName: guest.lastName,
                  }) || `${t("dashboard.guest.guest")} ${guest.person.id}`}
                </h6>
              </div>
            );
          })}
        </div>
        {guestId && (
          <AdminOrderGuestInfoModal
            openSlide={openSlideGuestInfoModal}
            mainElementRef={mainElementRefGuestInfoModal}
            handleCloseModal={handleCloseModal}
            guestId={guestId}
          />
        )}
      </div>
    </>
  );
};

AdminOrderInfo.propTypes = {
  guests: PropTypes.array,
  users: PropTypes.array,
  formData: PropTypes.object,
  setPendingData: PropTypes.func,
  pendingData: PropTypes.object,
  handleOnUpdateOrder: PropTypes.func,
  orderCreateDate: PropTypes.string,
};

export default AdminOrderInfo;
