import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./OtpInput.scss";

const OtpInput = ({
  name,
  hasError = false,
  value = "",
  error = null,
  onOtpChange,
  func,
}) => {
  const inputsRef = useRef([]);
  const [otpValues, setOtpValues] = useState(Array(6).fill(""));

  useEffect(() => {
    if (value) {
      const valueArray = value.split("").slice(0, 6);
      const paddedValueArray = [
        ...valueArray,
        ...Array(6 - valueArray.length).fill(""),
      ];
      setOtpValues(paddedValueArray);
    }
  }, [value]);

  const handleChange = (e, index) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "").charAt(0);
    const newOtpValues = [...otpValues];
    newOtpValues[index] = inputValue;

    setOtpValues(newOtpValues);
    onOtpChange(newOtpValues.join(""));

    if (inputValue !== "" && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otpValues[index] === "" && index > 0) {
      inputsRef.current[index - 1].focus();
    }
    if (e.key === "Backspace" && otpValues[index] !== "") {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = "";
      setOtpValues(newOtpValues);
      onOtpChange(newOtpValues.join(""));
    }
  };

  const handleWrapperClick = (index) => {
    inputsRef.current[index].focus();
  };

  return (
    <div className="OtpInputContainer">
      <div className="OtpInputWrapper">
        {[...Array(6)].map((_, index) => (
          <div
            key={index}
            className={cx("InputWrapper", { hasError })}
            onClick={() => handleWrapperClick(index)}
          >
            <input
              ref={(el) => (inputsRef.current[index] = el)}
              maxLength={1}
              type="tel"
              value={otpValues[index]}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className={cx("Input", { hasError })}
            />
          </div>
        ))}
      </div>
      {error && error}
      <input
        className="HiddenInput"
        name={name}
        value={value}
        readOnly
        type="tel"
        {...func}
      />
    </div>
  );
};

OtpInput.propTypes = {
  func: PropTypes.object,
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.node,
  onOtpChange: PropTypes.func.isRequired,
};

export default OtpInput;
