import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stage, Layer, Transformer } from "react-konva";

import AdminMapSidebar from "pages/admin/admin-pages/admin-map/admin-map-sidebar/AdminMapSidebar";
import AdminMapHeader from "pages/admin/admin-pages/admin-map/admin-map-header/AdminMapHeader";
import TableShape from "pages/admin/admin-pages/admin-map/shapes/TableShape";
import { getZonesAsync } from "redux/actions/zoneAction";
import useAsync from "utils/hooks/useAsync";
import { updateTableMapView } from "utils/api/services/table";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { STORE_NAMES } from "utils/constants/redux";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import {
  INITIAL_SIZE_ZONE,
  INITIAL_SIZE_CONTAINER,
  convertMeterToPixel,
} from "./helper";
import EmptyZoneIcon from "assets/icons/qr/Table.svg";
import useOutsideClick from "utils/hooks/useOutsideClick";
import MapItemEditModal from "pages/admin/admin-pages/admin-map/map-item-edit-modal/MapItemEditModal";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import { ROUTE_NAME } from "utils/constants/routes";

import "./AdminMap.scss";

const Map = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const zones = useSelector((state) => state[STORE_NAMES.zones].zones);

  const [hasUnsavedTableChanges, setHasUnsavedChanges] = useState(false);
  const [isUpdateChangesConfirmationOpen, setIsUpdateChangesConfirmationOpen] =
    useState(false);

  const adminMapContainerRef = useRef(null);
  const transformerRef = useRef();
  const stageRef = useRef();

  const [, setSize] = useState(INITIAL_SIZE_CONTAINER);
  const [ratio, setRatio] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [initialMapItems, setInitialMapItems] = useState([]);
  const [mapItems, setMapItems] = useState([]);

  const [selectedZone, setSelectedZone] = useState(zones[0]);

  const [
    openSlideEditTableMap,
    setOpenSlideEditTableMap,
    mainElementRefEditTableMap,
  ] = useOutsideClick();

  const originalSizeOfZone = useMemo(
    () => ({
      width: selectedZone?.width || INITIAL_SIZE_ZONE.width,
      height: selectedZone?.height || INITIAL_SIZE_ZONE.height,
    }),
    [selectedZone]
  );
  const findRatio = useCallback(
    ({ containerSize }) => {
      const { width: originalWidth } = originalSizeOfZone;
      setRatio(containerSize.width / originalWidth);
    },
    [originalSizeOfZone]
  );

  useEffect(() => {
    const handleResize = () => {
      const updatedSizeOfContainer = {
        width: adminMapContainerRef.current?.clientWidth,
        height: adminMapContainerRef.current?.clientHeight,
      };
      findRatio({ containerSize: updatedSizeOfContainer });
      setSize(updatedSizeOfContainer);
    };

    handleResize(); // Initial size setup
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, [findRatio]);

  useEffect(() => {
    if (selectedZone) {
      const existingTablesOnMap = zones
        .find((zone) => zone.id === selectedZone.id)
        .tables.filter((table) =>
          Object.values(table.coordinates).every((value) => value !== null)
        )
        .map((table) => ({
          id: String(table.id),
          name: table.name,
          maxSeat: table.maxSeat,
          shape: table.shape,
          ...table.coordinates,
        }));
      setMapItems(existingTablesOnMap);
      setInitialMapItems(existingTablesOnMap);
    }
  }, [selectedZone, zones]);
  const { execute: executeUpdateTable } = useAsync(updateTableMapView, {
    onError: () => handleOnAsyncError(t(commonAsyncErrorMessage)),
    onSuccess: () => {
      dispatch(getZonesAsync({ businessId }));
      handleOnAsyncSuccess(t("toastMessages.success.updateTable"));
    },
  });

  const handleSaveMap = async () => {
    const editedData = mapItems.map(({ id, x, y, width, height, shape }) => ({
      id: parseInt(id),
      coordinates: { x, y, width, height },
      shape,
    }));
    setInitialMapItems(mapItems);
    await executeUpdateTable(businessId, editedData);
  };
  const handleDeleteMapItem = async () => {
    const deletedTable = {
      id: parseInt(selectedTable.id),
      coordinates: { x: null, y: null, width: null, height: null },
    };
    await executeUpdateTable(businessId, [deletedTable]);
    const updatedMapItems = mapItems.filter(
      (prevMapItem) => prevMapItem.id !== selectedTable.id
    );
    setMapItems(updatedMapItems);
    setInitialMapItems(updatedMapItems);
    setOpenSlideEditTableMap(false);
  };

  useEffect(() => {
    if (selectedId) {
      const selectedNode = stageRef.current.findOne(`#${selectedId}`);
      transformerRef.current.nodes([selectedNode]);
      transformerRef.current.getLayer().batchDraw();
    } else {
      transformerRef.current?.nodes([]);
    }
  }, [selectedId]);

  const displayedData = useMemo(
    () => mapItems.map((item) => convertMeterToPixel({ item, ratio })),
    [mapItems, ratio]
  );

  const onGoBack = () => {
    if (!hasUnsavedTableChanges && !isUpdateChangesConfirmationOpen) {
      navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.adminTable}`);
    } else {
      setIsUpdateChangesConfirmationOpen(true);
    }
  };

  const handleCancelUpdateChanges = () => {
    setIsUpdateChangesConfirmationOpen(false);
  };

  const handleConfirmChanges = () => {
    setIsUpdateChangesConfirmationOpen(false);
    setHasUnsavedChanges(false);
    navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.adminTable}`);
  };

  useEffect(() => {
    mapItems.forEach((item) => {
      delete item.isDragging;
    });
    const isMapItemsEqual = isEqual(
      mapItems.map((m) => {
        return { ...m, x: Number(m.x), y: Number(m.y) };
      }),
      initialMapItems
    );
    setHasUnsavedChanges(!isMapItemsEqual);
  }, [mapItems, initialMapItems]);

  return (
    <div className="AdminMap">
      <AdminMapSidebar
        selectedZone={selectedZone}
        setMapItems={setMapItems}
        mapItems={mapItems}
        onGoBack={onGoBack}
      />
      <div className="AdminMapContainer">
        <Confirm
          type={ENUMS_CONFIRM.types.TYPE_B}
          title={t("modal.warningModalTitleUnsavedChanges")}
          description={t("modal.warningModalDescription")}
          mainElementRefConfirm={mainElementRefEditTableMap}
          openSlide={isUpdateChangesConfirmationOpen}
          onCancel={handleCancelUpdateChanges}
          onConfirm={handleConfirmChanges}
        />
        <AdminMapHeader
          handleSave={handleSaveMap}
          selectedZone={selectedZone}
          setSelectedZone={setSelectedZone}
          hasUnsavedTableChanges={hasUnsavedTableChanges}
        />
        <div className="AdminMapContent" ref={adminMapContainerRef}>
          {selectedZone && displayedData.length > 0 ? (
            <Stage
              width={originalSizeOfZone.width * ratio}
              height={originalSizeOfZone.height * ratio}
              ref={stageRef}
              scaleX={1}
              scaleY={1}
              className="AdminMapStage"
            >
              <Layer>
                {displayedData.map((mapItem) => (
                  <TableShape
                    key={mapItem.id}
                    mapItem={mapItem}
                    setSelectedId={setSelectedId}
                    mapItems={mapItems}
                    setMapItems={setMapItems}
                    ratio={ratio}
                    setOpenSlideEditTableMap={setOpenSlideEditTableMap}
                    setSelectedTable={setSelectedTable}
                    hasUnsavedTableChanges={hasUnsavedTableChanges}
                  />
                ))}
                <Transformer ref={transformerRef} rotateEnabled={false} />
              </Layer>
            </Stage>
          ) : (
            <div
              className="EmptyZoneContainer"
              style={{
                width: originalSizeOfZone.width * ratio,
                height: originalSizeOfZone.height * ratio,
                minWidth: "fit-content",
                minHeight: "fit-content",
              }}
            >
              <div className="EmptyZoneContent">
                <img
                  src={EmptyZoneIcon}
                  alt="Empty Zone"
                  className="EmptyZoneIcon"
                />
                <div className="EmptyZoneTitle">
                  {t("emptyTable.emptyZoneTitle")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <MapItemEditModal
        openSlide={openSlideEditTableMap}
        setOpenSlide={setOpenSlideEditTableMap}
        mainElementRef={mainElementRefEditTableMap}
        onSave={(data) => executeUpdateTable(businessId, data)}
        onDeleteMapItem={handleDeleteMapItem}
        selectedTable={selectedTable}
        mapItems={mapItems}
      />
    </div>
  );
};

export default Map;
