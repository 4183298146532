import React from "react";
import { useTranslation } from "react-i18next";

import PageTitle from "components/elements/page-title/PageTitle";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import UpdateIcon from "assets/icons/other/Update.svg";
import InfraCard from "components/admin/cards/Infra-card/InfraCard";
import useAsync from "utils/hooks/useAsync";
import Spinner from "components/elements/spinner/Spinner";
import "./SuperAdminInfra.scss";

import { getInfra } from "utils/api/services/infra";

const SuperAdminInfra = () => {
  const { t } = useTranslation();

  const {
    result: infraData,
    loading,
    execute: executeGetInfraData,
  } = useAsync(getInfra, {
    immediate: true,
  });

  if (loading || !infraData) {
    return <Spinner />;
  }
  const updateInfraData = async () => {
    await executeGetInfraData();
  };
  const initInfraData = [
    {
      description: "MySQL",
      data: [
        { count: infraData?.business.count, label: "Businesses" },
        { count: infraData?.user.count, label: "Users" },
        { count: infraData?.menu.count, label: "Menus" },
        {
          count: infraData?.guest?.count,
          label: "Guest Count",
        },
        {
          count: infraData?.qrScan?.count,
          label: "Qr Scan Count",
        },
        {
          count: infraData?.activeOrder.count,
          label: "Active Order",
        },
        {
          count: infraData?.activeGuest.count,
          label: "Active Guest",
        },
        {
          count: infraData?.closedOrder.count,
          label: "Closed Order Count",
        },
        {
          count: infraData?.closedOrder.totalSale,
          label: "Closed Order Total Sale",
        },
      ],
    },
    {
      description: "Nest JS",
      data: [
        {
          count: infraData?.detailedAppInfo.cpus.length,
          label: "CPUS",
        },
        {
          count: infraData?.detailedAppInfo.totalMemory,
          label: "Total Memory",
        },
        {
          count: infraData?.detailedAppInfo.freeMemory,
          label: "Free Memory",
        },
        {
          count: infraData?.memoryUsage.rss,
          label: "Memory Usage RSS",
        },

        {
          count: infraData?.memoryUsage.heapTotal,
          label: "Memory Usage Heap Total",
        },
        {
          count: infraData?.memoryUsage.heapUsed,
          label: "Memory Usage Heap Used",
        },
      ],
    },
    {
      description: "Redis Stream",
      data: [
        {
          count: infraData?.streamNames.length,
          label: "Stream Names",
          contentOnHover: infraData?.streamNames,
        },

        {
          count: infraData?.redisStream.used_memory_rss,
          label: "Used Memory RSS",
        },
        {
          count: infraData?.redisStream.used_memory_rss_human,
          label: "Used Memory RSS Human",
        },
        {
          count: infraData?.redisStream.total_system_memory_human,
          label: "Total System Memory Human",
        },
        {
          count: Math.round(
            infraData?.redisStream.total_net_input_bytes / 1024
          ),
          label: "Total Net Inputs",
        },
        {
          count: Math.round(
            infraData?.redisStream.total_net_output_bytes / 1024
          ),
          label: "Total Net Outputs",
        },
        {
          count: infraData?.redisStream.total_reads_processed,
          label: "Total Reads Processed",
        },
        {
          count: infraData?.redisStream.total_writes_processed,
          label: "Total Writes Processed",
        },
        {
          count: infraData?.redisStream.pubsub_channels,
          label: "PubSub Channels",
        },
        {
          count: infraData?.redisStream.keyspace_hits,
          label: "Keyspace Hits",
        },

        {
          count: infraData?.redisStream.pubsub_channels,
          label: "PubSub Channels",
        },
        {
          count: infraData?.redisStream.keyspace_misses,
          label: "Keyspace Misses",
        },
        {
          count: infraData?.redisStream.tcp_port,
          label: "TCP Ports",
        },
        {
          count: infraData?.redisStream.os,
          label: "OS",
          contentOnHover: infraData?.redisStream.os,
        },
        {
          count: Math.round(infraData?.redisStream.uptime_in_seconds / 3600),

          label: "Uptime in hours",
        },
        {
          count: infraData?.redisStream.connected_clients,
          label: "Connected Clients",
        },
        {
          count: infraData?.redisStream.maxclients,
          label: "Max clients",
        },
        {
          count: infraData?.redisStream.used_memory_human,
          label: "Used Memory Human",
        },
      ],
    },
    {
      description: "Redis PubSub",
      data: [
        {
          count: infraData?.redisPubSub.used_memory_rss,
          label: "Used Memory RSS",
        },
        {
          count: infraData?.redisPubSub.used_memory_rss_human,
          label: "Used Memory RSS Human",
        },
        {
          count: infraData?.redisPubSub.total_system_memory_human,
          label: "Total System Memory Human",
        },
        {
          count: Math.round(
            infraData?.redisPubSub.total_net_input_bytes / 1024
          ),
          label: "Total Net Inputs",
        },
        {
          count: Math.round(
            infraData?.redisPubSub.total_net_output_bytes / 1024
          ),
          label: "Total Net Outputs",
        },
        {
          count: infraData?.redisPubSub.total_reads_processed,
          label: "Total Reads Processed",
        },
        {
          count: infraData?.redisPubSub.total_writes_processed,
          label: "Total Writes Processed",
        },
        {
          count: infraData?.redisPubSub.pubsub_channels,
          label: "PubSub Channels",
        },
        {
          count: infraData?.redisPubSub.keyspace_hits,
          label: "Keyspace Hits",
        },

        {
          count: infraData?.redisPubSub.pubsub_channels,
          label: "PubSub Channels",
        },
        {
          count: infraData?.redisPubSub.keyspace_misses,
          label: "Keyspace Misses",
        },
        {
          count: infraData?.redisPubSub.tcp_port,
          label: "TCP Ports",
        },
        {
          count: Math.round(infraData?.redisPubSub.uptime_in_seconds / 3600),
          label: "Uptime in hours",
        },
        {
          count: infraData?.redisPubSub.connected_clients,
          label: "Connected Clients",
        },
        {
          count: infraData?.redisPubSub.maxclients,
          label: "Max clients",
        },
        {
          count: infraData?.redisPubSub.used_memory_human,
          label: "Used Memory Human",
        },
        {
          count: infraData?.redisPubSub.os,
          label: "OS",
          contentOnHover: infraData?.redisPubSub.os,
        },
      ],
    },
    {
      description: "Redis Cache",
      data: [
        {
          count: infraData?.redisCache.used_memory_rss,
          label: "Used Memory RSS",
        },
        {
          count: infraData?.redisCache.used_memory_rss_human,
          label: "Used Memory RSS Human",
        },
        {
          count: infraData?.redisCache.total_system_memory_human,
          label: "Total System Memory Human",
        },
        {
          count: Math.round(infraData?.redisCache.total_net_input_bytes / 1024),
          label: "Total Net Inputs",
        },
        {
          count: Math.round(
            infraData?.redisCache.total_net_output_bytes / 1024
          ),
          label: "Total Net Outputs",
        },
        {
          count: infraData?.redisCache.total_reads_processed,
          label: "Total Reads Processed",
        },
        {
          count: infraData?.redisCache.total_writes_processed,
          label: "Total Writes Processed",
        },
        {
          count: infraData?.redisCache.pubsub_channels,
          label: "PubSub Channels",
        },
        {
          count: infraData?.redisCache.keyspace_hits,
          label: "Keyspace Hits",
        },

        {
          count: infraData?.redisCache.pubsub_channels,
          label: "PubSub Channels",
        },
        {
          count: infraData?.redisCache.keyspace_misses,
          label: "Keyspace Misses",
        },
        {
          count: infraData?.redisCache.tcp_port,
          label: "TCP Ports",
        },
        {
          count: infraData?.redisCache.os,
          label: "OS",
          contentOnHover: infraData?.redisCache.os,
        },
        {
          count: Math.round(infraData?.redisStream.uptime_in_seconds / 3600),
          label: "Uptime in hours",
        },
        {
          count: infraData?.redisCache.connected_clients,
          label: "Connected Clients",
        },
        {
          count: infraData?.redisCache.maxclients,
          label: "Max clients",
        },
        {
          count: infraData?.redisCache.used_memory_human,
          label: "Used Memory Human",
        },
      ],
    },
  ];

  const updatedInfraData = initInfraData.map((section) => {
    const editedData = section?.data.map((data) => {
      if (
        typeof data.count === "string" &&
        data.count.endsWith("MB") &&
        parseFloat(data.count) > 1024
      ) {
        const editedCount = Math.round(parseFloat(data.count) / 1024) + " GB";
        return { ...data, count: editedCount };
      }
      return data;
    });
    return { ...section, data: editedData };
  });

  return (
    <div className="SuperAdminInfra">
      <PageTitle title={t("navbarRoutes.pageTitles.infra")}>
        <div className="SuperAdminInfraCaptionUpdateButton">
          <PrimaryButton
            text={t("buttons.update")}
            icon={UpdateIcon}
            onClick={updateInfraData}
          />
        </div>
      </PageTitle>
      <div className="SuperAdminInfraContainer">
        {updatedInfraData.map((infraData, index) => {
          return (
            <div key={index} className="SuperAdminInfraGroup">
              <h3 className="SuperAdminInfraCardsTitle SemiBold">
                {infraData.description}
              </h3>
              <div className="SuperAdminInfraCards">
                {infraData.data.map(
                  ({ count, label, contentOnHover }, index) => {
                    return (
                      <InfraCard
                        key={index}
                        count={count}
                        label={label}
                        contentOnHover={contentOnHover}
                      />
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SuperAdminInfra;
