import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cx from "classnames";

import AdminOrderGuestInfoModal from "pages/admin/admin-pages/admin-order/admin-order-map/admin-order-guest-info-modal/AdminOrderGuestInfoModal";
import { TableV2 } from "components/table/TableV2";
import Pagination from "components/pagination/Pagination";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import Spinner from "components/elements/spinner/Spinner";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useAsync from "utils/hooks/useAsync";
import { getBusinessGuests } from "utils/api/services/guest";
import { STORE_NAMES } from "utils/constants/redux";
import {
  checkDateEqualCurrentDate,
  concatFirstNameWithLastName,
  formatDateToShortFormat,
} from "utils/helpers";
import { createDOBucketName } from "utils/DO-Spaces";
import EmptyTableIcon from "assets/icons/admin-dashboard/EmptyTable.svg";
import { ReactComponent as SortArrowAsc } from "assets/icons/admin-dashboard/sortArrowAsc.svg";
import { ReactComponent as SortArrowDesc } from "assets/icons/admin-dashboard/sortArrowDesc.svg";
import { ReactComponent as SortArrowDefault } from "assets/icons/admin-dashboard/sortArrowDefault.svg";
import { ReactComponent as ThreeDots } from "assets/icons/dot/ThreeDots.svg";
import { ReactComponent as BirthdayHat } from "assets/icons/birthday/hat.svg";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import useDebounce from "utils/hooks/useDebounce";

import "./AdminBusinessGuests.scss";

const GUESTS_TABLE_KEYS = [
  {
    value: "image",
    valueForTranslation: "",
    isSortable: false,
  },
  {
    value: "name",
    valueForTranslation: "dashboard.table.businessGuestsTable.name",
    isSortable: false,
  },
  {
    value: "gender",
    valueForTranslation: "dashboard.table.businessGuestsTable.gender",
    isSortable: false,
  },
  {
    value: "dateOfBirth",
    valueForTranslation: "dashboard.table.businessGuestsTable.dateOfBirth",
    isSortable: true,
  },
  {
    value: "visitCount",
    valueForTranslation: "dashboard.table.businessGuestsTable.numberOfVisit",
    isSortable: true,
  },
  {
    value: "threeDots",
    valueForTranslation: "",
    isSortable: false,
  },
];

const guestsMeta = {
  breakpoints: [
    {
      BreakPointsRange: {
        max: -1,
        min: 1800,
      },
      cellDisplayAndProportions: [3, 23.5, 23.5, 23.5, 23.5, 3],
    },
    {
      BreakPointsRange: {
        max: 1799,
        min: 1001,
      },
      cellDisplayAndProportions: [5, 22.25, 22.25, 22.25, 22.25, 6],
    },
    {
      BreakPointsRange: {
        max: 1000,
        min: 601,
      },
      cellDisplayAndProportions: [7, 86 / 3, null, 86 / 3, 86 / 3, 7],
    },
    {
      BreakPointsRange: {
        max: 600,
        min: 0,
      },
      cellDisplayAndProportions: [12, 38, null, null, 38, 12],
    },
  ],
};
const guestCount = [
  { id: 10, name: "10" },
  { id: 25, name: "25" },
  { id: 50, name: "50" },
  { id: 75, name: "75" },
  { id: 100, name: "100" },
];

const AdminBusinessGuests = () => {
  const { t } = useTranslation();
  const registerFilterOptions = [
    {
      id: 0,
      name: t("dashboard.all"),
    },
    {
      id: 1,
      name: t("guests.registeredGuest"),
      isRegistered: true,
    },
    {
      id: 2,
      name: t("guests.unregisteredGuest"),
      isRegistered: false,
    },
  ];
  const [currentPage, setActivePage] = useState(1);
  const [guestId, setGuestId] = useState();
  const [pageCount, setPageCount] = useState();
  const [pageSize, setPageSize] = useState(guestCount[1]);
  const [searchValue, setSearchValue] = useState("");
  const [searchDebounceValue, setSearchDebounceValue] = useState(null);
  const [registerData, setRegisterData] = useState(registerFilterOptions[0]);
  const [rows, setRows] = useState([]);
  const [sortType, setSortType] = useState("");
  const [sortBy, setSortBy] = useState("");
  const { id: businessId } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );

  const [
    openSlideGuestInfoModal,
    setOpenSlideGuestInfoModal,
    mainElementRefGuestInfoModal,
  ] = useOutsideClick();
  const { loading, result: fetchedBusinessGuests } = useAsync(
    getBusinessGuests,
    {
      immediate: true,
      params: [
        {
          pageNumber: currentPage,
          businessId,
          pageSize: pageSize.id,
          sortBy: sortBy || "createDate",
          sortDir: sortType || "DESC",
          hasSignedUp: registerData?.isRegistered,
          searchValue,
        },
      ],
    }
  );
  const handleGuestsSort = (header) => {
    if (rows.length <= 1) return;
    if (!header.isSortable) return;
    setActivePage(1);
    if (header.value === sortBy) {
      if (sortType === "DESC") {
        setSortBy("");
        setSortType("");
        return;
      }
      setSortType(sortType === "ASC" ? "DESC" : "ASC");
      return;
    }

    setSortBy(header.value);
    setSortType("ASC");
  };

  const headers = GUESTS_TABLE_KEYS.map((header, index) => {
    return {
      element: (
        <div
          key={index}
          className="headerCell"
          onClick={() => {
            handleGuestsSort(header);
          }}
        >
          <p>{t(header.valueForTranslation)}</p>
          {header.isSortable && (
            <>
              {sortBy !== header.value ? (
                <SortArrowDefault />
              ) : sortType === "ASC" ? (
                <SortArrowAsc />
              ) : sortType === "DESC" ? (
                <SortArrowDesc />
              ) : null}
            </>
          )}
        </div>
      ),
    };
  });
  useEffect(() => {
    if (fetchedBusinessGuests && fetchedBusinessGuests.data) {
      setRows(createRows(fetchedBusinessGuests));
      setPageCount(fetchedBusinessGuests.totalPages);
    } else {
      setRows([]);
    }
  }, [fetchedBusinessGuests, t]);

  useEffect(() => {
    const registerDataMapping = {
      [registerFilterOptions[0]?.id]: {
        ...registerFilterOptions[0],
        name: t("dashboard.all"),
      },
      [registerFilterOptions[1]?.id]: {
        ...registerFilterOptions[1],
        name: t("guests.registeredGuest"),
      },
      [registerFilterOptions[2]?.id]: {
        ...registerFilterOptions[2],
        name: t("guests.unregisteredGuest"),
      },
    };

    setRegisterData(registerDataMapping[registerData?.id]);
  }, [registerData?.id, t]);

  const debouncedRequest = useDebounce(() => {
    setSearchValue(searchDebounceValue);
    setActivePage(1);
  }, 1000);

  const createRows = (guestsData) => {
    return guestsData.data.map((row) => {
      const isBirthday = checkDateEqualCurrentDate(row.dateOfBirth);
      return [
        {
          element: (
            <div>
              {isBirthday && <BirthdayHat className="BirthdayHat" />}
              <ImageWithPlaceholder
                className="AdminBusinessGuestProfileImage"
                imageSource={createDOBucketName(row.profilePic)}
                placeholder={IMG_GUEST}
                alt="logo"
              />
            </div>
          ),
        },
        {
          element: (
            <p className="h7 SemiBold">
              {row.firstName
                ? concatFirstNameWithLastName({
                    firstName: row.firstName,
                    lastName: row.lastName,
                  })
                : `${t("dashboard.guest.guest")} ${row.guestId}`}
            </p>
          ),
        },
        {
          element: (
            <p className="h7 SemiBold">{t(`auth.genders.${row.gender}`)}</p>
          ),
        },
        {
          element: (
            <p className="h7 SemiBold">
              {row.dateOfBirth ? formatDateToShortFormat(row.dateOfBirth) : "-"}
            </p>
          ),
        },
        {
          element: <p className="h7 SemiBold">{row.numberOfVisit}</p>,
        },
        {
          element: (
            <div
              className="ThreeDotsContainer"
              onClick={() => handleOpenModal(row)}
            >
              <ThreeDots />
            </div>
          ),
        },
      ];
    });
  };

  const EmptyTable = () => {
    return (
      <div className={`AdminBusinessGuestTableEmpty`}>
        <img src={EmptyTableIcon} alt="Empty Table" />
        <h4 className="Medium AdminBusinessGuestTableEmptyInfo">
          {t("emptyStates.noTableData")}
        </h4>
      </div>
    );
  };

  const handleGuestCountChange = (guestCount) => {
    setActivePage(1);
    guestCount && setPageSize(guestCount);
  };

  const handleChangeRegisteredFilter = (filter) => {
    filter && setRegisterData(filter);
    setActivePage(1);
    setSearchDebounceValue(null);
    setSearchValue("");
  };

  const handleSearchChange = (value) => {
    setSearchDebounceValue(value.trimStart());
    setActivePage(1);
    debouncedRequest();
  };

  const handleOpenModal = (row) => {
    setGuestId(row.guestId);
    setOpenSlideGuestInfoModal(true);
  };

  const handleCloseModal = () => {
    setOpenSlideGuestInfoModal(false);
  };

  const handleReturnPlaceholder = () => {
    const placeholders = {
      1: `${t("inputs.search")} (${t("common.name")})`,
      2: `${t("inputs.search")} (id)`,
    };

    return placeholders[registerData.id] || `${t("inputs.search")}`;
  };

  return (
    <div className="AdminBusinessGuests">
      <div className="AdminBusinessGuestsHeader">
        <div className="AdminBusinessGuestsTitle">
          <h4 className="SemiBold AdminBusinessGuestsTitleText">
            {t("navbarRoutes.pageTitles.guests")}
          </h4>
          <h6 className="Medium AdminBusinessGuestsCount">
            {t("dashboard.guest.guest")} (
            {fetchedBusinessGuests && fetchedBusinessGuests.totalElems})
          </h6>
        </div>
        <div className="AdminBusinessGuestsFilters">
          <Search
            onChange={handleSearchChange}
            value={searchDebounceValue}
            placeholder={handleReturnPlaceholder()}
            type={SEARCH_ENUMS.types.TYPE_B}
            classname={cx("AdminFiltersSearch", {
              isDisabled: registerData?.id === registerFilterOptions[0]?.id,
            })}
          />
          <Dropdown
            onChange={handleChangeRegisteredFilter}
            value={registerData}
            options={registerFilterOptions}
            placeholder={t("orders.guests")}
            className="RegisteredGuestsFilter"
          />
          <Dropdown
            onChange={handleGuestCountChange}
            value={pageSize}
            options={guestCount}
            placeholder={t("dashboard.table.businessGuestsTable.pageSize")}
            className="PageSizeDropdown"
          />
        </div>
      </div>
      <TableV2
        meta={guestsMeta}
        headers={headers}
        loading={loading}
        rows={rows}
        LoadingComponent={<Spinner />}
        EmptyComponent={<EmptyTable />}
        Footer={
          pageCount > 1 && (
            <Pagination
              pageCount={pageCount}
              pageRange={1}
              setActivePage={setActivePage}
              currentPage={currentPage}
            />
          )
        }
      />
      {guestId && (
        <AdminOrderGuestInfoModal
          guestId={guestId}
          openSlide={openSlideGuestInfoModal}
          mainElementRef={mainElementRefGuestInfoModal}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};
export default AdminBusinessGuests;
