import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./MapSidebarButton.scss";

const MapSidebarButton = ({ icon, title, resetMap, onClick, disabled }) => {
  return (
    <div
      className={cx("MapSidebarButton", { resetMap: resetMap, disabled })}
      onClick={onClick}
    >
      <img className="MapSidebarButtonIcon" src={icon} alt="icon" />
      <h6 className="Medium h7 MapSidebarButtonTitle">{title}</h6>
    </div>
  );
};

export default MapSidebarButton;

MapSidebarButton.propTypes = {
  disabled: PropTypes.bool,

  /**
   * The icon to display next to the button text
   */
  icon: PropTypes.node,
  /**
   * The title to display
   */
  title: PropTypes.string,
  /**
   * A boolean to determine if the resetMap class should be applied
   */
  resetMap: PropTypes.bool,
  onClick: PropTypes.func,
};
