import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import cx from "classnames";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";

import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { stringTimeToDateTimeConverter } from "utils/helpers";
import { generateDurationOptions } from "pages/client/reservation/create-reservation/ClientReservationHelper";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowRight.svg";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";

import "./ReservationDateForm.scss";

const TODAY = new Date().toISOString().split("T")[0];

const ReservationDateForm = ({ handleNextStep, formData, setFormData }) => {
  const { t } = useTranslation();
  const schemas = useValidationSchema(t);
  const { reservationDateAndGuestModalSchema } = schemas;

  useEffect(() => {
    if (!formData.date) {
      setFormData((prev) => {
        return {
          ...prev,
          date: TODAY,
        };
      });
    }
  }, []);

  useEffect(() => {
    const { date, reservationDuration, time } = formData;

    if (time) {
      const convertedReservationDuration = stringTimeToDateTimeConverter(
        reservationDuration,
        t
      );
      const startDateTime = `${date}T${time}:00Z`;
      const convertedDateTime = new Date(startDateTime);
      let formattedEndDateTime;

      if (convertedReservationDuration.trim().length > 0) {
        const endDateTime = new Date(convertedDateTime);
        const [hours, minutes] = convertedReservationDuration
          .split(":")
          .map(Number);

        endDateTime.setUTCHours(endDateTime.getUTCHours() + hours);
        endDateTime.setUTCMinutes(endDateTime.getUTCMinutes() + minutes);

        const year = endDateTime.getUTCFullYear();
        const month = String(endDateTime.getUTCMonth() + 1).padStart(2, "0");
        const day = String(endDateTime.getUTCDate()).padStart(2, "0");
        const hoursFormatted = String(endDateTime.getUTCHours()).padStart(
          2,
          "0"
        );
        const minutesFormatted = String(endDateTime.getUTCMinutes()).padStart(
          2,
          "0"
        );
        const secondsFormatted = String(endDateTime.getUTCSeconds()).padStart(
          2,
          "0"
        );

        formattedEndDateTime = `${year}-${month}-${day}T${hoursFormatted}:${minutesFormatted}:${secondsFormatted}Z`;
      }

      setFormData((prev) => {
        return {
          ...prev,
          startDateTime,
          endDateTime: formattedEndDateTime,
        };
      });
    }
  }, [formData.reservationDuration, formData.time, formData.date]);

  const methods = useForm({
    resolver: zodResolver(reservationDateAndGuestModalSchema),
    criteriaMode: "all",
    defaultValues: {
      date: TODAY,
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = methods;

  const handleReservationSubmit = () => {
    handleNextStep();
  };

  const handleDuration = (duration) => {
    setFormData({
      ...formData,
      reservationDuration: duration.name,
    });
  };

  const handleDate = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      date: value,
    });
  };

  const handleTimeChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      time: value,
    });
  };

  return (
    <div className="GuestInfoForm">
      <div className="GuestInfoFormContact"></div>
      <div className="GuestInfoFormDetails">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="GuestInfoFormContainer"
        >
          <div className="ReservationDateAndTime">
            <InputControl
              type="date"
              placeholder={t("inputs.date")}
              required
              name="date"
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              func={{
                ...register("date", {
                  onChange: handleDate,
                }),
              }}
              hasError={errors.date}
              value={formData.date}
              min={new Date().toISOString().split("T")[0]}
              error={
                <ErrorMessage
                  errors={errors}
                  name="date"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
            {/*<Controller*/}
            {/*  name="time"*/}
            {/*  control={control}*/}
            {/*  defaultValue={formData.time}*/}
            {/*  render={({ field: { value, onChange } }) => (*/}
            {/*    <>*/}
            {/*      <Dropdown*/}
            {/*        required*/}
            {/*        value={TIME_OPTIONS.find((option) => option.name === value)}*/}
            {/*        options={TIME_OPTIONS}*/}
            {/*        placeholder={t("inputs.time")}*/}
            {/*        func={{*/}
            {/*          ...register("time"),*/}
            {/*        }}*/}
            {/*        onChange={(selectedOption) => {*/}
            {/*          onChange(selectedOption.name);*/}
            {/*          handleTimeChange(selectedOption);*/}
            {/*        }}*/}
            {/*        name="time"*/}
            {/*        hasError={errors.time}*/}
            {/*        error={*/}
            {/*          <ErrorMessage*/}
            {/*            errors={errors}*/}
            {/*            name="time"*/}
            {/*            render={({ message }) => (*/}
            {/*              <p className="h7 error-message">{message}</p>*/}
            {/*            )}*/}
            {/*          />*/}
            {/*        }*/}
            {/*      />*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*/>*/}
            <InputControl
              type="time"
              placeholder={t("inputs.time")}
              required
              name="time"
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              func={{
                ...register("time", {
                  onChange: handleTimeChange,
                }),
              }}
              value={formData.time}
              hasError={errors.time}
              error={
                <ErrorMessage
                  errors={errors}
                  name="time"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
            />
          </div>

          <div className="ReservationSection">
            <div className="ReservationSectionTitle">
              <h5>{t("reservation.duration")}</h5>
              {/*<div className="ReservationSectionTitleContent">*/}
              {/*  <img src={InformationIcon} alt="information" />*/}
              {/*  <div className="ReservationSectionTextToolTip">*/}
              {/*    {t("reservation.reservationDurationHelpText")}*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="ReservationNumberOfGuestAndDuration">
              {generateDurationOptions(t).map((option) => (
                <div
                  key={option.id}
                  onClick={() => handleDuration(option)}
                  className={cx("ReservationSelectionDuration", {
                    isActive: formData.reservationDuration === option.name,
                  })}
                >
                  {option.name}
                </div>
              ))}
            </div>
          </div>
        </form>
      </div>
      <CTAButton
        onClick={handleSubmit(handleReservationSubmit)}
        name={t("buttons.continue")}
        type={ENUMS_CTA_BUTTON.types.TYPE_R}
        icon={<ArrowIcon />}
        className="ReservationGuestFormSubmitButton"
      />
    </div>
  );
};

ReservationDateForm.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  setFormData: PropTypes.func,
  selectedBusiness: PropTypes.object,
  formData: PropTypes.object,
  type: PropTypes.string,
};

export default ReservationDateForm;
