import React, { useEffect } from "react";
import PropTypes from "prop-types";
import OtpInput from "components/forms/input/input-otp/OtpInput";
import { ErrorMessage } from "@hookform/error-message";
import cx from "classnames";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import useAsync from "utils/hooks/useAsync";
import { createOtpRequestForUserSignup } from "utils/api/services/otp";
import { SIGN_IN_METHOD as SIGN_UP_METHOD } from "pages/common/login/sign-in/SignIn";
import { verifyUserOtpCode } from "utils/api/services/user";

import "./ConfirmOtpForSignup.scss";

const ConfirmOtpForSignup = ({
  handleNext,
  signInMethod,
  otpData,
  setOtpData,
  setCounter,
  counter,
  setIsUserExist,
  setUserDetailFormValue,
}) => {
  const { t } = useTranslation();
  const { guestSignUpOtp } = useValidationSchema(t);
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(guestSignUpOtp),
    criteriaMode: "all",
  });

  useEffect(() => {
    Object.keys(errors).length > 0 && trigger();
  }, [t]);

  const handleOtpChange = (value) => {
    setOtpData((prev) => {
      return {
        ...prev,
        otpCode: value,
      };
    });
    setValue("otpCode", value);
    if (errors.otpCode) {
      trigger("otpCode");
    }
  };

  const { execute: executeCreateOtpRequest } = useAsync(
    createOtpRequestForUserSignup,
    {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: ({ data }) => {
        setOtpData((prev) => ({
          ...prev,
          otpToken: data.otpToken,
          otpSendCount: data.otpSendCount,
        }));
        setCounter(60);
      },
    }
  );

  const { execute: executeVerifyOtp, loading: isOtpVerifyLoading } = useAsync(
    verifyUserOtpCode,
    {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: ({ data }) => {
        setOtpData((prev) => ({
          ...prev,
          otpToken: data.otpToken,
          userId: data?.user?.id,
        }));
        if (data?.user) {
          setUserDetailFormValue("firstName", data.user?.firstName);
          setUserDetailFormValue("lastName", data.user?.lastName);
        }
        handleNext();
        setCounter(0);
        setIsUserExist(!!data?.user);
      },
    }
  );

  const handleResendCode = () => {
    let otpBody;
    if (signInMethod === SIGN_UP_METHOD.email) {
      otpBody = { email: otpData.email };
    } else if (signInMethod === SIGN_UP_METHOD.phoneNumber) {
      otpBody = { phoneNumber: otpData.phoneNumber.replace(/\+/g, "") };
    }
    executeCreateOtpRequest(otpBody);
  };

  const handleOnSubmit = async () => {
    await executeVerifyOtp({
      otpCode: otpData.otpCode,
      otpToken: otpData.otpToken,
    });
  };

  const otpSendingAvailable = counter === 0;

  useEffect(() => {
    if (otpData.otpCode && otpData.otpCode.length === 6) {
      handleSubmit(handleOnSubmit)();
    }
  }, [otpData.otpCode]);

  return (
    <div className="ConfirmOtpForSignup">
      <div className="ConfirmOtpHeader">
        <h1 className="SemiBold title">{t("auth.verifyOtp")}</h1>
        <div className="ConfirmOtpHeaderPhone">
          <h6 className="Medium">{`${t("auth.otpSentTo")}`} </h6>{" "}
          <h6 className="Bold">
            {signInMethod === "email" ? otpData.email : otpData.phoneNumber}
          </h6>
        </div>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <OtpInput
          name={"otpCode"}
          value={otpData.otpCode}
          hasError={!!errors.otpCode}
          onOtpChange={handleOtpChange}
          func={{
            ...register("otpCode"),
          }}
          error={
            <ErrorMessage
              errors={errors}
              name="otpCode"
              render={({ message }) => (
                <p className="h7 error-message">{t(message)}</p>
              )}
            />
          }
        />
        <div className="ConfirmOtpVerifyOtpResend">
          <h6
            className={cx("Medium", {
              isDisabledText: otpData.otpSendCount === 0,
            })}
          >
            {otpData.otpSendCount === 0
              ? t("errorMessages.input.pinCode.TryAgainLater")
              : otpSendingAvailable
              ? t("auth.resendingIsAvailable")
              : t("auth.resendingCodeWillBeAvailableIn") +
                ` ${Math.floor(counter / 60)}:${(counter % 60)
                  .toString()
                  .padStart(2, "0")}`}
          </h6>
          <button
            className={cx("ConfirmOtpResendButton", {
              isDisabled: !otpSendingAvailable || otpData.otpSendCount === 0,
            })}
            disabled={!otpSendingAvailable || otpData.otpSendCount === 0}
            onClick={handleResendCode}
          >
            {t("buttons.resend")}
          </button>
        </div>

        <PrimaryButton
          onClick={handleSubmit(handleOnSubmit)}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
          text={t("buttons.verify")}
          isLoading={isOtpVerifyLoading}
          className="ConfirmOtpButton"
        />
      </form>
    </div>
  );
};

ConfirmOtpForSignup.propTypes = {
  handleNext: PropTypes.func,
  signInMethod: PropTypes.string,
  otpData: PropTypes.object,
  setOtpData: PropTypes.func,
  setCounter: PropTypes.func,
  setIsUserExist: PropTypes.func,
  counter: PropTypes.number,
  setUserDetailFormValue: PropTypes.func,
};

export default ConfirmOtpForSignup;
