import React from "react";
import {
  getBrandFooterLogo,
  getBrandMainLogoLight,
  getBrandName,
} from "utils/branding-helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STORE_NAMES } from "utils/constants/redux";
import { setLanguage } from "redux/slices/userStore";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import FooterSocial from "components/elements/footer-social/FooterSocial";
import InstagramIcon from "assets/icons/sosial-media/FooterInstagram.svg";
import WhatsappIcon from "assets/icons/sosial-media/FooterWhatsapp.svg";
import MailIcon from "assets/icons/sosial-media/FooterMail.svg";
import { landingPageImageUrl } from "utils/constants/global";
import { Trans, useTranslation } from "react-i18next";
import { ROUTE_NAME } from "utils/constants/routes";
import FormattedPhoneNumber from "components/elements/formatted-phone-number/FormattedPhoneNumber";

import "./LandingPage.scss";

const initFooterData = {
  contact: {
    phoneNumber: "+99450 600 60 11",
    address: "Koroglu Rahimov Street 61 Baku, Azerbaijan",
    googleLink: "   https://maps.app.goo.gl/JHzNeAj7RsnHN4M37",
  },
  socials: {
    instagram: "https://www.instagram.com/iloyal.app/",
    email: "nizami.zamanov@gmail.com",
  },
};

const LandingPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const MainLogoLight = getBrandMainLogoLight();
  const brandName = getBrandName();
  const FooterLogo = getBrandFooterLogo();
  const allLanguages = useSelector((state) => state[STORE_NAMES.app].languages);
  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );
  const languageOptions = allLanguages.map((language) => {
    return {
      ...language,
      name: language.code,
      imgSrc: language.imgSrc,
    };
  });

  const handleOnLanguageClick = async (language) => {
    if (
      !selectedLanguage ||
      (selectedLanguage && selectedLanguage.id !== language.id)
    ) {
      dispatch(setLanguage(language));
    }
  };

  const handleSignInSubmit = () => {
    navigate(ROUTE_NAME.signIn);
  };
  const currentYear = new Date().getFullYear();

  return (
    <div className="LandingPage">
      <div className="LandingPageHeader">
        <div className="LandingPageHeaderTitle">
          <MainLogoLight />
          <h4 className="SemiBold">{brandName}</h4>
        </div>

        <div className="LandingPageLanguageDropDownAndSignIn">
          <div className="AdminSidebarLanguageDropdown">
            {allLanguages?.length > 0 && selectedLanguage && (
              <Dropdown
                value={selectedLanguage}
                dropdownWithIcon
                hideDescription={true}
                options={languageOptions}
                onChange={(language) => {
                  if (language) handleOnLanguageClick(language);
                }}
                showCodeColumnInTitle
              />
            )}
          </div>

          <PrimaryButton
            onClick={() => handleSignInSubmit()}
            text={t("login.signIn.title")}
            type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
            className="LandingPageSignInButton"
          />
        </div>
      </div>
      <div className="LandingPageImage">
        <img src={landingPageImageUrl} alt={"image"} />
      </div>
      <div className="FooterContainer">
        <div className="FooterLogoContainer">
          <div className="FooterLogoFront">
            <MainLogoLight />
          </div>
          <h4 className="Bold">{brandName}</h4>
        </div>
        <div className="FooterLinksContainer">
          <div className="FooterLinksContact">
            <h5 className="SemiBold">{t("common.contact")}</h5>
            <h6
              onClick={() =>
                window.open(
                  `tel:${initFooterData.contact.phoneNumber}`,
                  "_self"
                )
              }
            >
              <FormattedPhoneNumber
                phoneNumber={initFooterData.contact.phoneNumber}
                className="textWhite h6"
              />
            </h6>
            <h6
              onClick={() =>
                window.open(
                  `tel:${initFooterData.contact.phoneNumber}`,
                  "_self"
                )
              }
            >
              <FormattedPhoneNumber
                phoneNumber={initFooterData.contact.phoneNumber}
                className="textWhite h6"
              />
            </h6>
            <h6
              onClick={() =>
                window.open(initFooterData.contact.googleLink, "_self")
              }
            >
              {initFooterData.contact.address}
            </h6>
          </div>
        </div>
        <div className="FooterSocialsContainer">
          <FooterSocial
            SocialIcon={InstagramIcon}
            onClick={() =>
              window.open(initFooterData.socials.instagram, "_self")
            }
          />
          <FooterSocial
            SocialIcon={WhatsappIcon}
            onClick={() =>
              window.open(
                `https://wa.me/${initFooterData.contact.phoneNumber.replace(
                  /\s+/g,
                  ""
                )}`,
                "_self"
              )
            }
          />
          <FooterSocial
            SocialIcon={MailIcon}
            onClick={() =>
              window.open(`mailto:${initFooterData.socials.email}`, "_self")
            }
          />
        </div>
        <div className="FooterPrivacyAndTerms">
          <div className="CompanyName">
            <h6>
              <Trans
                i18nKey="general.madeBy"
                components={{
                  strong: (
                    <a
                      href={URL.address}
                      target="_self"
                      rel="noopener noreferrer"
                      className={"CompanyNameLink"}
                    >
                      {brandName}
                    </a>
                  ),
                }}
              />
            </h6>
          </div>
          <div className="FooterCopyRightContainer">
            <h6>{t("general.allRightReserved")}</h6>
            <div className="FooterCopyRight">
              <FooterLogo />
              <h6>
                {t("general.copyRight", {
                  value: brandName,
                  year: currentYear,
                })}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
