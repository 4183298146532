import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as AddIcon } from "assets/icons/math-operators/add/PlusAdmin.svg";

import "./AddTagButton.scss";

export const ENUMS = {
  name: "AddTagButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
  },
};

const AddTagButton = ({
  type = ENUMS.types.TYPE_A,
  onClick,
  text,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={cx("AddTagButton", className, {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
        typeC: type === ENUMS.types.TYPE_C,
        typeD: type === ENUMS.types.TYPE_D,
      })}
      type="button"
    >
      <AddIcon className="AddTagButtonIcon" />
      {type !== ENUMS.types.TYPE_D && text && (
        <h5 className="Medium AddTagButtonText">{text}</h5>
      )}
      {type === ENUMS.types.TYPE_D && text && (
        <h6 className="Medium AddTagButtonText">{text}</h6>
      )}
    </button>
  );
};

AddTagButton.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf([
    ENUMS.types.TYPE_A,
    ENUMS.types.TYPE_B,
    ENUMS.types.TYPE_C,
    ENUMS.types.TYPE_D,
  ]),

  /**
   *  The text to display
   * */
  text: PropTypes.string,

  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,

  /**
   * The class name of the component
   */
  className: PropTypes.string,
};

export default AddTagButton;
