import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LogoLight } from "assets/icons/logo/iLoyal/logo-light.svg";
import { resetAllReduxStores } from "utils/general";
import { useMixpanel } from "utils/context-api/MixpanelContext";
import { MP_EVENTS } from "utils/constants/mixpanel";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";

import "./errorBoundary.scss";
import { useTranslation } from "react-i18next";

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const { trackMixpanel } = useMixpanel();

  useEffect(() => {
    trackMixpanel(`${MP_EVENTS.error}`, { message: error.message });
  }, []);

  return (
    <div className="error-container">
      <LogoLight className="iloyal-logo" />
      <div className="error-card">
        <h2 className="error-title">Oops! Something went wrong.</h2>
        <p className="error-message">{error.message}</p>
        <button
          className="retry-button"
          onClick={async () => {
            await resetAllReduxStores(dispatch);
            resetErrorBoundary();
            navigate(
              `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
            );
          }}
        >
          {t("funZone.memoryCardGame.restart")}
        </button>
      </div>
    </div>
  );
};
ErrorBoundaryFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  resetErrorBoundary: PropTypes.func,
};
export default ErrorBoundaryFallback;
