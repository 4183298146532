import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "redux/slices/userStore";
import { STORE_NAMES } from "../constants/redux";
import { LANGUAGES } from "../constants/language";
import useIsAdminRoute from "./useIsAdminRoute";

const useSetUserLanguage = () => {
  const dispatch = useDispatch();
  const isAdminRoute = useIsAdminRoute();
  const menuLanguage = useSelector(
    (state) => state[STORE_NAMES.menu].data?.language
  );
  const availableLanguages = useSelector(
    (state) => state[STORE_NAMES.menu].data?.availableLanguages
  );
  const { userLanguage } = useSelector((state) => state[STORE_NAMES.user]);
  const allLanguages = useSelector((state) => state[STORE_NAMES.app].languages);

  useEffect(() => {
    const availableLanguagesCodes =
      availableLanguages?.map((language) => language.code) || [];
    const userLangCode = userLanguage?.code;
    if (
      userLangCode &&
      !availableLanguagesCodes.includes(userLangCode) &&
      !isAdminRoute
    ) {
      dispatch(setLanguage(menuLanguage));
    } else if (!userLanguage) {
      if (menuLanguage) {
        dispatch(setLanguage(menuLanguage));
      } else {
        dispatch(
          setLanguage(
            allLanguages.find((language) => language.code === LANGUAGES.en.code)
          )
        );
      }
    }
  }, [availableLanguages]);
};

export default useSetUserLanguage;
