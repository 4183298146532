import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ReactComponent as ICON_SETTING } from "assets/icons/settings/Setting.svg";
import Switch from "components/forms/switch/Switch";
import cx from "classnames";

import "./IntegrationCard.scss";

const IntegrationSwitch = ({ isChecked, onChange }) => {
  const { t } = useTranslation();
  return (
    <div className="IntegrationCardAction">
      <Switch isChecked={isChecked} onChange={onChange} />
      <h6 className="Medium h7">
        {isChecked ? t("common.enable") : t("common.disable")}
      </h6>
    </div>
  );
};

IntegrationSwitch.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

const IntegrationCard = ({
  title,
  description,
  icon,
  onClick,
  isConnected,
  integrationStatus,
  className,
}) => {
  const { t } = useTranslation();
  const [isIntegrationEnabled] = useState(true);

  // const handleChangeIntegrationStatus = () => {
  //   setOpenSlideIntegrationModal(true);
  //   setIsIntegrationEnabled(!isIntegrationEnabled);
  // };

  return (
    <div className={cx("IntegrationCardItem", className)}>
      <div className="IntegrationCardItemInfo">
        <div className="IntegrationCardItemLogoAndIcon">
          <div className="IntegrationCardItemLogo">
            <img src={icon} alt="iiko image" />
          </div>
          <div className="IntegrationCardItemIcon" onClick={onClick}>
            <ICON_SETTING />
          </div>
        </div>
        <h3 className="SemiBold IntegrationCardItemTitle">{title}</h3>
        {description ? (
          <h6 className="Medium IntegrationCardItemOrganizationId">
            {description}
          </h6>
        ) : (
          <></>
        )}
        <div
          className={cx("Medium IntegrationCardItemIsActive", {
            isActive: isConnected,
          })}
        >
          <p>
            {t("common.connection")}: <span>{integrationStatus}</span>
          </p>
        </div>
        {isConnected && (
          <IntegrationSwitch
            isChecked={isIntegrationEnabled}
            // onChange={handleChangeIntegrationStatus}
          />
        )}
      </div>
    </div>
  );
};

IntegrationCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isConnected: PropTypes.bool,
  integrationStatus: PropTypes.string,
  className: PropTypes.string,
};

export default IntegrationCard;
