import IMAGE_RU from "assets/images/languages/Russian.svg";
import IMAGE_TR from "assets/images/languages/TR.png";
import IMAGE_EN from "assets/images/languages/English.svg";
import {
  calculateItemPriceWithDefaultModificationPrice,
  findItemByIdForBasket,
} from "utils/general";

export const LANGUAGES = {
  RUSSIAN: {
    id: 1,
    name: "RU",
    imgSrc: IMAGE_RU,
    code: "ru-RU",
  },
  TURKISH: {
    id: 2,
    name: "TR",
    imgSrc: IMAGE_TR,
    code: "tr-TR",
  },
  ENGLISH: {
    id: 3,
    name: "EN",
    imgSrc: IMAGE_EN,
    code: "en-US",
  },
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
};

export const formatMenuForAI = (menu) => {
  const preferredLanguageCode = menu.language.code;
  return menu.categories
    .map((category) => {
      if (!category.isArchived && category.isPublished) {
        const menuItems = category.menuItems
          .map((menuItem) => {
            if (!menuItem.isArchived && menuItem.isPublished) {
              return {
                i: menuItem.id,
                n:
                  menuItem.name.find(
                    (name) => name.languageCode === preferredLanguageCode
                  )?.value || menuItem.name[0].value,
                p: calculateItemPriceWithDefaultModificationPrice(menuItem),
              };
            }
          })
          .filter(Boolean);

        if (menuItems.length === 0) {
          return;
        }

        return {
          n:
            category.name.find(
              (name) => name.languageCode === preferredLanguageCode
            )?.value || category.name[0].value,
          s: menuItems,
        };
      }
    })
    .filter(Boolean);
};
export const formatResult = (results, menu) => {
  return results
    .map((result) => {
      const itemsWithDetails = result.items
        .map((id) => findItemByIdForBasket(id, menu))
        .filter(Boolean);
      return {
        ...result,
        items: itemsWithDetails,
      };
    })
    .filter(Boolean);
};
