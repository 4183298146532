import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import Confirm from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { ReactComponent as IconDelete } from "assets/icons/trash/Trash.svg";
import AccessDeniedModal from "pages/admin/admin-pages/admin-order/admin-order-actions/access-denied-modal/AccessDeniedModal";

import "./DeleteButton.scss";

export const ENUMS = {
  name: "DeleteButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
  },
};

const DeleteButton = ({
  type = ENUMS.types.TYPE_A,
  label,
  showLabel = false,
  showAccessDeniedModal,
  onClick,
  setOpenSlide,
  svgComponent = <IconDelete className="DeleteButtonIcon" />,
  className,
  showModalDescription = true,
}) => {
  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick();
  const { t } = useTranslation();

  const handleOnClick = (e) => {
    e.stopPropagation();
    setOpenSlideConfirm(true);
    setOpenSlideDeniedModal(true);
  };

  const [
    openSlideDeniedModal,
    setOpenSlideDeniedModal,
    mainElementRefDeniedModal,
  ] = useOutsideClick();

  const handleOnConfirm = (e) => {
    e.stopPropagation();
    setOpenSlideConfirm(false);
    if (setOpenSlide) setOpenSlide(false);
    onClick();
  };

  const handleOnCancel = (e) => {
    e.stopPropagation();
    setOpenSlideConfirm(false);
  };
  const handleCloseModal = (e) => {
    e.stopPropagation();

    setOpenSlideDeniedModal(false);
  };
  return (
    <div
      onClick={(e) => handleOnClick(e)}
      className={cx(
        "DeleteButton",
        className,
        {
          typeA: type === ENUMS.types.TYPE_A,
        },
        {
          typeB: type === ENUMS.types.TYPE_B,
        },
        {
          typeC: type === ENUMS.types.TYPE_C,
        },

        {
          showLabel: showLabel,
        }
      )}
    >
      {showLabel && label ? (
        <div className="h7 DeleteButtonLabel">{label}</div>
      ) : (
        svgComponent
      )}
      {showAccessDeniedModal ? (
        <AccessDeniedModal
          onClose={(e) => handleCloseModal(e)}
          openSlide={openSlideDeniedModal}
          mainElementRef={mainElementRefDeniedModal}
        />
      ) : (
        <Confirm
          title={t("modal.deleteModalTitle")}
          description={
            showModalDescription && t("modal.deleteModalDescription")
          }
          mainElementRefConfirm={mainElementRefConfirm}
          openSlide={openSlideConfirm}
          onCancel={(e) => handleOnCancel(e)}
          onConfirm={(e) => handleOnConfirm(e)}
        />
      )}
    </div>
  );
};

DeleteButton.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf([
    ENUMS.types.TYPE_A,
    ENUMS.types.TYPE_B,
    ENUMS.types.TYPE_C,
  ]),

  /**
   * Show the label in place of the icon
   */
  showLabel: PropTypes.bool,

  /**
   * Dynamic text to be displayed
   */
  label: PropTypes.string,

  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,

  /**
   * Function to open or close confirm modal
   */
  setOpenSlide: PropTypes.func,

  /**
   * The SVG component to be displayed as the button icon
   */
  svgComponent: PropTypes.element,

  /**
   * State show confirmation modal description
   */
  showModalDescription: PropTypes.bool,

  /**
   * Additional class name
   */
  className: PropTypes.string,
  showAccessDeniedModal: PropTypes.bool,
};

export default DeleteButton;
