import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useLogoutGuest from "utils/hooks/useGuestLogout";

import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import SignInForm from "pages/client/auth/sign-in/sign-in-form/SignInForm";
import { ROUTE_NAME } from "utils/constants/routes";
import { MP_PAGE_NAMES } from "utils/constants/mixpanel";
import useMixpanelPageView from "utils/hooks/useMixpanelPageView";

import "./SignIn.scss";

const GuestSignIn = () => {
  useMixpanelPageView({ eventName: MP_PAGE_NAMES.signIn });
  const { logoutGuest } = useLogoutGuest();

  useEffect(() => {
    logoutGuest();
  }, []);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    phoneNumber: "",
    password: "",
    email: "",
  });
  const handleGoBack = () => {
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.auth}`);
  };

  const GuestAuthSignInHeader = (
    <div className="GuestAuthSignInHeader">
      <BackButton
        onClick={handleGoBack}
        type={BACK_BUTTON_ENUMS.types.TYPE_D}
      />
      <h2 className="SemiBold">{t("buttons.login")}</h2>
    </div>
  );

  return (
    <div className="SignUpStepper">
      {GuestAuthSignInHeader}
      <SignInForm setFormData={setFormData} formData={formData} />
    </div>
  );
};

export default GuestSignIn;
