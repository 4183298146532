import { useEffect } from "react";
import { useMixpanel } from "utils/context-api/MixpanelContext";

const useMixpanelPageView = ({ eventName, eventProperty }) => {
  const { trackMixpanel } = useMixpanel();

  useEffect(() => {
    trackMixpanel(eventName, eventProperty);
  }, []);
};
export default useMixpanelPageView;
