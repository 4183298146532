export const MENU_FIELDS = {
  categories: "categories",
  tags: "tags",
  coverImageSrc: "coverImageSrc",
  otherImagesSrc: "otherImagesSrc",
  modifications: "modifications",
  primaryColor: "primaryColor",
  availableLanguages: "availableLanguages",
  orderMessage: "orderMessage",
  transactionItems: "transactionItems",
};

export const CURRENCY = {
  symbol: "$",
  code: "AZN",
};

export const WAITER = {
  id: "WaiterId",
};

export const ORDER_ACTIONS_TYPE = {
  bill: "BILL",
  waiter: "WAITER",
};

export const ORDER_ACTION_STATE = {
  actionBill: "actionBill",
  actionWaiter: "actionWaiter",
};

const DISCOUNT = {
  isFixed: true,
  rate: 5.0,
};

const PROMO = {
  code: "promo-code-123",
  discount: DISCOUNT,
};

// const MENU_ITEM_PRICE = {
//   amount: 10.0,
//   discount: DISCOUNT,
// };
//
// const MENU_ITEM_TAG = {
//   id: 1,
//   name: "Vegetarian",
// };
//
// const MENU_ITEM_MODIFICATION = {
//   id: 1,
//   name: "Size selection",
//   isMultiSelect: false,
//   isRequired: true,
//   options: [
//     {
//       id: 1,
//       defaultValue: true,
//       name: "Small",
//       price: -1.5,
//       isCountable: false,
//       count: 1, // EXCEPTION ! ! only used in Basket store, not Menu Store !!
//     },
//     {
//       id: 2,
//       name: "Medium",
//       price: -1.5,
//       isCountable: false,
//       count: 1, // EXCEPTION ! ! only used in Basket store, not Menu Store !!
//     },
//   ],
// };

//FOR SINGLE MENU
// const MENU_ITEM = {
//   id: 1,
//   name: "Strawberry Cheesecake",
//   description: "Strawberry Cheesecake with Chocolate Syrup",
//   coverImageSrc: IMAGE_DESSERT1,
//   otherImagesSrc: [IMAGE_DESSERT1],
//   price: MENU_ITEM_PRICE,
//   tags: [MENU_ITEM_TAG],
//   modifications: [MENU_ITEM_MODIFICATION], // In Basket store will be empty if no modification applied
// };

export const EXTRA_FEES = {
  tax: 15, //percent
  serviceFee: 10, //percent
};

export const ORDER_STATUS_ENUM = {
  ordered: {
    name: "ORDERED",
    sequence: 1,
  },
  confirmed: {
    name: "CONFIRMED",
    sequence: 2,
  },
  served: {
    name: "SERVED",
    sequence: 3,
  },
  paid: {
    name: "PAID",
    sequence: 4,
  },
};

export const ORDER_SUMMARY = {
  paymentStatus: null, //NULL initially
  promo: PROMO,
};

// TBD later
// const COMBO_MENU = {
//   id: 1,
//   name: "Deluxe Combo",
//   tags: ["Combo", "Italian", "Popular"],
//   description: "A luxurious combo offering the best of Italian cuisine.",
//   amount: 0,
//   coverImg: "chat-background3.png",
//   price: "$20.00",
//   menuList: [MENU_ITEM],
// };
