import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import useOutsideClick from "utils/hooks/useOutsideClick";
import AddItemModal from "components/admin/cards/add-item-modal/AddItemModal";
import {
  createZoneAsync,
  deleteZoneAsync,
  getZonesAsync,
  updateZoneAsync,
} from "redux/actions/zoneAction";
import { STORE_NAMES } from "utils/constants/redux";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import AddTagButton from "components/admin/buttons/add-tag-button/AddTagButton";
import QrZoneItem from "components/admin/elements/qr-zone-item/QrZoneItem";
import { createDOBucketName } from "utils/DO-Spaces";
import useMouseDragHorizontal from "utils/hooks/useMouseDragHorizontal";

import "./AdminZone.scss";

const AdminZone = ({ activeZone, setActiveZone }) => {
  const { sliderRef, handleMouseDownHandler } = useMouseDragHorizontal();
  const { t } = useTranslation();
  const businessId = useSelector(
      (state) => state[STORE_NAMES.business]?.business?.id
  );
  const { zones: zonesWithoutAWSBucketName } = useSelector(
    (state) => state[STORE_NAMES.zones]
  );
  const isLoadingCreateZone = useSelector(
    (state) => state[STORE_NAMES.zones].thunkAPIStates?.createZone
  );
  const isLoadingUpdateZone = useSelector(
    (state) => state[STORE_NAMES.zones].thunkAPIStates?.updateZone
  );

  const zones = zonesWithoutAWSBucketName?.map((zone) => ({
    ...zone,
    tables: zone.tables?.map((table) => ({
      ...table,
      qrLinks: table.qrLinks.map((qrLink) => ({
        ...qrLink,
        image: createDOBucketName(qrLink.image),
      })),
    })),
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getZonesAsync({ businessId }));
  }, []);

  const handleOnAsyncSuccessForZone = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlideZone(false);
      //TODO remove reFetch after web sockets
      dispatch(getZonesAsync({ businessId }));
    });
  };
  const handleOnAsyncErrorForZone = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };

  const [, setOpenSlide] = useOutsideClick();

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  const [
    openSlideZone,
    setOpenSlideZone,
    mainElementRefZone,
    ,
    ,
    setOutsideClickAction,
  ] = useOutsideClick(() => {
    if (isDropdownDisabled) {
      setOpenSlide(true);
    }
  });

  const [selectedZone, setSelectedZone] = useState(null);

  // useEffect(() => {
  //   if (zones.length === 0) {
  //     setActiveZone(null);
  //   }
  //   if (!activeZone) {
  //     setActiveZone(zones?.[0]);
  //   }
  // }, [zones]);

  const handleOnAddZone = () => {
    setOpenSlideZone(true);
    setSelectedZone(null);
    setIsDropdownDisabled(false);
  };

  const handleOnEditZone = (zone) => {
    setOpenSlideZone(true);
    setSelectedZone(zone);
    setIsDropdownDisabled(false);
  };

  const handleOnSaveZone = async (data, id) => {
    const zone = { name: data.name };

    if (id) {
      const response = await dispatch(
        updateZoneAsync({ businessId, zone, id })
      );
      if (response.error) {
        handleOnAsyncErrorForZone();
      } else {
        handleOnAsyncSuccessForZone(t("toastMessages.success.updateZone"));
      }
    } else {
      const response = await dispatch(createZoneAsync({ businessId, zone }));
      if (response.error) {
        handleOnAsyncErrorForZone();
      } else {
        handleOnAsyncSuccessForZone(t("toastMessages.success.createZone"));
      }
    }
  };

  const handleOnDeleteZone = async (id) => {
    if (zones.find((z) => z.id === id).tables.length > 0) {
      toast.error(t("toastMessages.error.zones"));
    } else {
      const response = await dispatch(deleteZoneAsync({ businessId, id }));
      if (response.error) {
        handleOnAsyncErrorForZone();
      } else {
        // if (activeZone.id === id) setActiveZone(null);

        setActiveZone(null);
        handleOnAsyncSuccessForZone(t("toastMessages.success.deleteZone"));
      }
    }
  };

  return (
    <div className="AdminZoneSection">
      <div className="AdminZoneCaption">
        <h4 className="SemiBold AdminZoneCaptionTitleText">
          {t("navbarRoutes.pageTitles.zones")}{" "}
          <span className="AdminZoneCount">({zones.length})</span>
          {zones.some((zone) => zone.tables && zone.tables.length > 0) && (
            <>
              {" & "}
              {t("navbarRoutes.pageTitles.tables")}{" "}
              <span className="AdminZoneCount">
                ({zones.flatMap((zone) => zone.tables).length})
              </span>
            </>
          )}
        </h4>
      </div>
      <div className="AdminZoneContainer">
        <div className="AdminZoneAdd">
          <AddTagButton onClick={handleOnAddZone} />
        </div>
        <div
          className="AdminZoneItems"
          ref={sliderRef}
          onMouseDown={handleMouseDownHandler}
        >
          {zones?.map((zone) => {
            return (
              <div
                className={`AdminZoneItemBox ${
                  zone.id === activeZone?.id ? "isActive" : " "
                }`}
                key={zone.id}
              >
                <QrZoneItem
                  key={zone.id}
                  title={zone.name}
                  zone={zone}
                  onClick={() => setActiveZone(zone)}
                  onEdit={() => {
                    handleOnEditZone(zone);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <AddItemModal
        mainElementRef={mainElementRefZone}
        setOpenSlide={setOpenSlideZone}
        title={t("zone.zone")}
        placeholder={t("zone.name")}
        item={selectedZone}
        openSlide={openSlideZone}
        onSave={handleOnSaveZone}
        onDelete={handleOnDeleteZone}
        setOutsideClickAction={setOutsideClickAction}
        isLoading={isLoadingCreateZone || isLoadingUpdateZone}
      />
    </div>
  );
};
AdminZone.propTypes = {
  activeZone: PropTypes.object,
  setActiveZone: PropTypes.func,
};

export default AdminZone;
