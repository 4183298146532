import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { REDUX_STORE } from "redux/store";
import AppRouter from "pages/AppRouter";
import useWakeLock from "utils/hooks/useWakeLock";
import { ElectronProvider } from "electron/ElectronProvider";

function App() {
  useWakeLock();
  return (
    <div>
      <Provider store={REDUX_STORE}>
        <ElectronProvider>
          <AppRouter />
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </ElectronProvider>
      </Provider>
    </div>
  );
}

export default App;
