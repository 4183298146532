import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSelector } from "react-redux";

import { createDOBucketName } from "utils/DO-Spaces";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import FavoriteCard, {
  ENUMS,
} from "components/cards/favorite-card/FavoriteCard";
import HamburgerButton from "components/buttons/hamburger-button/HamburgerButton";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import {
  MP_EVENTS,
  MP_PROP_NAMES,
  MP_SOURCE_NAME,
} from "utils/constants/mixpanel";
import { useMixpanel } from "utils/context-api/MixpanelContext";

import "./BasketHeroSection.scss";

const BasketHeroSection = ({ favoriteItems, onClose, onAdd }) => {
  const { t } = useTranslation();
  const { trackMixpanel } = useMixpanel();
  const navigate = useNavigate();
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const businessId = qrScanStore.businessId;
  let [searchParams, setSearchParams] = useSearchParams();

  const onGoBack = () => {
    if (searchParams.get(QUERY_PARAMS.from)) {
      return navigate(searchParams.get(QUERY_PARAMS.from));
    }
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  const favoriteItemsWithAWSBucketName = favoriteItems.map((item) => ({
    ...item,
    coverImageSrc: createDOBucketName(item.coverImageSrc),
    otherImagesSrc: item.otherImagesSrc?.map((image) =>
      createDOBucketName(image)
    ),
  }));

  const hamburgerClickHandler = () => {
    setSearchParams({
      ...searchParams,
      [QUERY_PARAMS.showHamburgerMenu]: true,
    });
    trackMixpanel(`${MP_EVENTS.pageView.guest.hamburgerMenu}`, {
      [MP_PROP_NAMES.source]: MP_SOURCE_NAME.basket,
    });
  };

  return (
    <div
      className={cx("BasketHeroSection", {
        isFavoritesEmpty: favoriteItems.length === 0,
      })}
    >
      <div className="BasketHeader">
        <BackButton type={BACK_BUTTON_ENUMS.types.TYPE_C} onClick={onGoBack} />
        <h2 className="BasketHeaderTitle SemiBold">{t("basket.basket")}</h2>
        <HamburgerButton onClick={hamburgerClickHandler} hasBackground />
      </div>

      {favoriteItemsWithAWSBucketName &&
      favoriteItemsWithAWSBucketName.length === 0 ? (
        <div className="BasketFavoriteEmpty">
          <h5>{t("basket.emptyFavorite")}</h5>
        </div>
      ) : (
        <>
          <div className="BasketFavoriteItemsContainer">
            <div className="BasketFavoriteItemsLikes">
              <h5>{t("common.yourLikes")}</h5>
              {/*<Link to="/" className="BasketFavoriteItemsSeeAll">*/}
              {/*  {t("buttons.seeAll")}*/}
              {/*</Link>*/}
            </div>
            <div className="BasketFavoriteItemsWrapper">
              {favoriteItemsWithAWSBucketName &&
                favoriteItemsWithAWSBucketName
                  .filter((item) => item.isPublished && !item.isArchived)
                  .map((favItem) => (
                    <FavoriteCard
                      key={favItem.id}
                      menuItem={favItem}
                      onClose={onClose}
                      onAdd={onAdd}
                      type={ENUMS.types.TYPE_B}
                    />
                  ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

BasketHeroSection.propTypes = {
  /**
   * An array of favorite items
   */
  favoriteItems: PropTypes.array,

  /**
   * Function to close an item
   */
  onClose: PropTypes.func,

  /**
   * Function to add an item
   */
  onAdd: PropTypes.func,
};
export default BasketHeroSection;
