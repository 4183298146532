import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as CheckIcon } from "assets/icons/check/Check.svg";

import "./Checkbox.scss";
import { Link } from "react-router-dom";

export const ENUMS = {
  name: "Checkbox",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
  },
};

const Checkbox = ({
  type = ENUMS.types.TYPE_A,
  isChecked,
  onChange,
  label,
  labelLink,
  labelLinkHref,
  onClick,
  labelModal,
}) => {
  return (
    <div
      className={cx(
        "Checkbox",
        {
          isChecked: isChecked,
        },
        {
          typeA: type === ENUMS.types.TYPE_A,
        },
        {
          typeB: type === ENUMS.types.TYPE_B,
        },
        {
          typeC: type === ENUMS.types.TYPE_C,
        }
      )}
    >
      <div className="CheckboxIcon" onClick={onChange}>
        <CheckIcon />
      </div>

      {label && (
        <h6 className="h7 Medium" onClick={onChange}>
          {label} {labelLink && <Link to={labelLinkHref}>{labelLink}</Link>}
          {labelModal && (
            <span className="CheckboxModalLabel" onClick={onClick}>
              {labelModal}
            </span>
          )}
        </h6>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  /**
   * The type of the checkbox
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * Indicates whether the checkbox is checked or not
   */
  isChecked: PropTypes.bool.isRequired,

  /**
   * Callback function called when the checkbox is clicked
   */
  onChange: PropTypes.func,

  /**
   * The label to display alongside the checkbox
   */
  label: PropTypes.string,

  /**
   * The optional link text to be displayed next to the label
   */
  labelLink: PropTypes.string,

  /**
   * The URL or path for the optional link when labelLink is present
   */
  labelLinkHref: PropTypes.string,

  /**
   * The optional label to be displayed as a modal
   */
  labelModal: PropTypes.string,

  /**
   * Callback function called when the modal label is clicked
   */
  onClick: PropTypes.func,
};

export default Checkbox;
