import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import ICON_DOWNLOAD from "assets/icons/other/Download.svg";
// import ICON_WHATSAPP from "assets/icons/sosial-media/Whatsapp.svg";
// import ICON_GMAIL from "assets/icons/sosial-media/Gmail.svg";
import { formatDateToLongFormat } from "utils/helpers";
import { QR_TYPES } from "utils/constants/data/base";
import { ReactComponent as CopyIcon } from "assets/icons/copy/copy.svg";
import useTimeout from "utils/hooks/useTimeout";

import "./QR.scss";

export const ENUMS = {
  name: "QR",
};

const QR = ({ zone, QR, table, onUnlinkQr }) => {
  const { t } = useTranslation();
  const [copySuccessId, setCopySuccessId] = useState(null);

  if (!QR || Object.values(QR).filter(Boolean).length === 0) {
    return (
      <div className="QR">
        <div className="QREmpty">
          <h6 className="Medium">{t("qr.qrIsNotAdded")}</h6>
        </div>
      </div>
    );
  }
  const { image, createDate } = QR;
  const { day, month, year } = formatDateToLongFormat(createDate);
  const createDateWithLongFormat = `${day} ${t(month)} ${year}`;
  const isLinked = !!table;

  const handleCopyQRLink = useCallback(async (qrLink, qrId) => {
    try {
      await navigator.clipboard.writeText(qrLink);
      setCopySuccessId(qrId);
    } catch (error) {
      console.error("Error copying QR link:", error);
    }
  }, []);

  useTimeout({
    callback: () => {
      if (copySuccessId) {
        setCopySuccessId(null);
      }
    },
    delay: copySuccessId ? 5000 : null,
    clear: () => setCopySuccessId(null),
  });
  const handleDownloadQR = async (image) => {
    try {
      const response = await fetch(image);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = QR?.name;

      if (QR.type === "WiFi") {
        fileName = QR.name;
      } else if (QR.type === "URL") {
        fileName =
          (zone?.name && table?.name && `${zone?.name} (${table?.name})`) ||
          fileName;
      }

      link.download = `${fileName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the QR code image:", error);
    }
  };
  return (
    <div className="QR">
      <div className="QRInfo">
        <div className="QRInfoLeft">
          <img src={image} alt="QR" className="QRImg" />
        </div>
        <div className="QRInfoRight">
          <div className="QRInfoRightTop">
            <h6 className="h7 QRCreatedDate">
              <span className="QRCreatedDateName">
                {t("common.name")}: {QR.name}
              </span>
            </h6>

            {QR?.type === "URL" && (
              <h6 className="h7">
                <span className="SemiBold">{t("qr.status")}:</span>
                {isLinked ? (
                  <span className="Medium QRStatusLinked">
                    {t("qr.connected")} {table.name}
                  </span>
                ) : (
                  <span className="Medium QRStatusUnlinked">
                    {t("qr.unlinked")}
                  </span>
                )}
              </h6>
            )}

            <h6 className="h7 QRCreatedDate">
              {t("common.date.createdAt")} {createDateWithLongFormat}
            </h6>
          </div>
          <div className="QRInfoRightBottom">
            <button
              onClick={() => onUnlinkQr(QR.id)}
              className={cx("QRlinkButton", { isUnLinked: !isLinked })}
              type="button"
            >
              <h6 className="Medium">{t("buttons.unlink")}</h6>
            </button>
            <div className="QRInfoRightBottomActions">
              <div className="AdminTableModalBodyQRLink">
                <button
                  className={cx("QRLinkCopyButton", { isUnLinked: !isLinked })}
                  onClick={() => handleCopyQRLink(QR.value.url, QR.id)}
                >
                  <CopyIcon />
                </button>
                {copySuccessId === QR.id && (
                  <h5 className="Medium QRLinkModalCopiedText">
                    {t("qr.QRLinkCopied")}
                  </h5>
                )}
              </div>
              <button
                className="QRLinkButtons"
                type="button"
                onClick={() => handleDownloadQR(image)}
              >
                <img src={ICON_DOWNLOAD} alt="download" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
QR.propTypes = {
  zone: PropTypes.object,

  /**
   * QR information object
   */
  QR: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    /**
     * Source of the QR image
     */
    image: PropTypes.string,

    /**
     * Created date of the QR
     */
    createDate: PropTypes.string,

    /**
     * Value of the QR
     */
    value: PropTypes.shape({
      url: PropTypes.string,
    }),

    /**
     * Type of the QR code
     */
    type: PropTypes.oneOf(Object.values(QR_TYPES)),
  }),

  /**
   * The table connected to the QR code
   */
  table: PropTypes.shape({
    name: PropTypes.string,
  }),

  /**
   * Function to unlink the QR code
   */
  onUnlinkQr: PropTypes.func,
};

export default QR;
