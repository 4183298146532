import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import ConfettiIcon from "assets/icons/fun-zone/memory-card-game/confetti.svg";

import "./TicTacToeModal.scss";

const TicTacToeModal = ({
  openSlide,
  onCancel,
  xPlaying,
  scores,
  gameOver,
  winningBoxes,
}) => {
  const { oScore, xScore } = scores;

  const { t } = useTranslation();
  const TicTacToeModalHeader = (
    <div className="TicTacToeModalHeader">
      <div className="TicTacToeModalHeaderMessage">
        <img src={ConfettiIcon} alt="emoji" />
        <h1 className="Medium">
          {gameOver
            ? winningBoxes.length > 0
              ? `${xPlaying ? "O" : "X"} ${t("funZone.ticTackToe.win")}!`
              : t("funZone.ticTackToe.draw")
            : ""}
        </h1>
      </div>
      <div className="CloseButtonContainer">
        <CloseButton
          onClick={onCancel}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_M}
        />
      </div>
    </div>
  );
  const TicTacToeModalBody = (
    <div className="TicTacToeModalBody">
      <div
        className={cx("TicTacToeGameModalScoreBoard", {
          isActive: !xPlaying && winningBoxes.length > 0,
        })}
      >
        <div>
          <h2 className="Bold">X</h2>
          <h5 className="Bold">{t("funZone.ticTackToe.player")}</h5>
        </div>
        <div>
          <h2 className="SemiBold ">{xScore}</h2>
          <h5 className="SemiBold">{t("funZone.ticTackToe.points")}</h5>
        </div>
      </div>
      <div
        className={cx("TicTacToeGameModalScoreBoard", {
          isActive: xPlaying && winningBoxes.length > 0,
        })}
      >
        <div>
          <h2 className="Bold">O</h2>
          <h5 className="Bold">{t("funZone.ticTackToe.player")}</h5>
        </div>
        <div>
          <h2 className="SemiBold">{oScore}</h2>

          <h5 className="SemiBold">{t("funZone.ticTackToe.points")}</h5>
        </div>
      </div>
    </div>
  );

  const TicTacToeModalFooter = (
    <div className="TicTacToeModalFooter">
      <PrimaryButton
        onClick={onCancel}
        text={t("funZone.memoryCardGame.startNewGame")}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_F}
      />
    </div>
  );

  return (
    <GuestModal
      header={TicTacToeModalHeader}
      body={TicTacToeModalBody}
      footer={TicTacToeModalFooter}
      openSlide={openSlide}
      containerClassname={"ContainerClassname"}
      modalClassname={"ModalClassname"}
      slideDirection={POSITION_TYPES.DOWN}
      modalPosition={MODAL_POSITIONS.BOTTOM}
    />
  );
};
TicTacToeModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  xPlaying: PropTypes.bool.isRequired,
  scores: PropTypes.object.isRequired,
  gameOver: PropTypes.bool,
  winningBoxes: PropTypes.array,
};

export default TicTacToeModal;
