import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ICON_EYE_OPEN from "assets/icons/eye/EyeOpen.svg";
import ICON_EYE_CLOSE from "assets/icons/eye/EyeClose.svg";
import ICON_INFO from "assets/icons/other/Info.svg";
import { ReactComponent as LINK } from "assets/icons/qr/QrNameIcon.svg";
import useDisableNumberInputScroll from "utils/hooks/useDisableNumberInputScroll";

import "./InputControl.scss";

export const ENUMS = {
  name: "InputControl",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

export const INPUT_NAME = {
  reservationVRLink: "reservationVRLink",
  vrLink: "vrLink",
};

const InputControl = forwardRef(
  (
    {
      value,
      defaultValue,
      onChange,
      required,
      placeholder,
      type = "text",
      className,
      name,
      textarea,
      definitionText,
      isBorderless,
      hasError,
      onBlur,
      onFocus,
      noLabelFloating,
      hasLabel,
      infoText,
      func,
      error,
      readOnly,
      labelType,
      maxLength,
      min,
      autoComplete = "off",
      autoFocus,
      onKeyDown,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPinCode, setShowPinCode] = useState(false);
    useDisableNumberInputScroll();

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const toggleShowPinCode = () => {
      setShowPinCode(!showPinCode);
    };

    return (
      <div className="InputControlContainer">
        <div className={cx("InputControl", className)}>
          {textarea ? (
            <textarea
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              // disabled={readOnly}
              readOnly={readOnly}
              {...func}
              required={required}
              placeholder={placeholder}
              className={cx("InputControlInput InputControlTextarea", {
                hasError: hasError,
                noFloating: noLabelFloating,
              })}
              name={name}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          ) : (
            <>
              {hasLabel && (
                <h6
                  className={cx("Medium InputControlLabelTop", {
                    noFloating: noLabelFloating,
                  })}
                >
                  {placeholder}
                  {required && (
                    <span className="InputControlRequiredSign">*</span>
                  )}
                </h6>
              )}
              <input
                ref={ref}
                value={value}
                min={min}
                defaultValue={defaultValue}
                disabled={readOnly}
                onChange={onChange}
                placeholder={placeholder}
                className={cx("InputControlInput", {
                  hasExtraPadding: definitionText || type === "password",
                  isBorderless: isBorderless,
                  hasError: hasError,
                  noFloating: noLabelFloating,
                  hasLabel: hasLabel,
                  isReadOnly: readOnly,
                  infoText: infoText,
                  hasExtraRightPadding:
                    name === INPUT_NAME.reservationVRLink ||
                    name === INPUT_NAME.vrLink,
                })}
                {...func}
                type={showPassword || showPinCode ? "text" : type}
                name={name}
                onBlur={onBlur}
                onFocus={onFocus}
                autoComplete={autoComplete}
                maxLength={maxLength}
                autoFocus={autoFocus}
                onKeyDown={onKeyDown}
              />
            </>
          )}
          <h6
            className={cx("Medium InputControlLabel", {
              noFloating: noLabelFloating,
              hasError: hasError,
              typeA: labelType === ENUMS.types.TYPE_A,
              typeB: labelType === ENUMS.types.TYPE_B,
            })}
          >
            {placeholder}
            {required && <span className="InputControlRequiredSign">*</span>}
          </h6>
          {definitionText && (
            <h6 className="InputControlDefinition">{definitionText}</h6>
          )}
          {(name === INPUT_NAME.reservationVRLink ||
            name === INPUT_NAME.vrLink) && (
            <div className={cx("InputControlVRLink", { isActive: value })}>
              <LINK />
            </div>
          )}
          {infoText && (
            <div className="InputControlInfoText">
              <img src={ICON_INFO} alt="Info" />
              <div className="InputControlInfoTextTooltip">
                <h6>{infoText}</h6>
              </div>
            </div>
          )}
          {type === "password" && name !== "pinCode" && (
            <div
              className="InputControlPasswordEye"
              onClick={toggleShowPassword}
            >
              {!showPassword ? (
                <img src={ICON_EYE_CLOSE} alt="hide password" />
              ) : (
                <img src={ICON_EYE_OPEN} alt="show password" />
              )}
            </div>
          )}
          {name === "pinCode" && (
            <div
              className="InputControlPasswordEye"
              onClick={toggleShowPinCode}
            >
              {!showPinCode ? (
                <img src={ICON_EYE_CLOSE} alt="hide pin code" />
              ) : (
                <img src={ICON_EYE_OPEN} alt="show pin code" />
              )}
            </div>
          )}
        </div>
        {error}
      </div>
    );
  }
);

InputControl.displayName = "InputControl";

InputControl.propTypes = {
  /**
   * The current value of the input
   */
  value: PropTypes.any,

  /**
   * The default value of the input
   */
  defaultValue: PropTypes.any,

  /**
   * Callback function to handle input change events
   */
  onChange: PropTypes.func,

  /**
   * Indicates whether the input is required
   */
  required: PropTypes.bool,

  /**
   * Placeholder text for the input
   */
  placeholder: PropTypes.string,

  /**
   * The type of input (e.g., text, number)
   */
  type: PropTypes.string,

  /**
   * Additional class name for custom styling
   */
  className: PropTypes.string,

  /**
   * Text for the add button in dropdown
   */
  addButtonText: PropTypes.string,

  /**
   * The name attribute for the input element
   */
  name: PropTypes.string,

  /**
   * Indicates whether the input should render as a textarea
   */
  textarea: PropTypes.bool,

  /**
   * The text for the definition
   */
  definitionText: PropTypes.string,

  /**
   * Indicates whether the input should have no border
   */
  isBorderless: PropTypes.bool,

  /**
   * Error message to display
   */
  errorMessage: PropTypes.string,

  /**
   * Indicates whether the input has an error
   */
  hasError: PropTypes.bool,

  /**
   * Callback function to handle input blur events
   */
  onBlur: PropTypes.func,

  /**
   * Callback function to handle input focus events
   */
  onFocus: PropTypes.func,

  /**
   * Indicates whether the label should not float when there is an input value
   */
  noLabelFloating: PropTypes.bool,

  /**
   * Indicates whether the input should have a label
   */
  hasLabel: PropTypes.bool,

  /**
   * Text for the information tooltip
   */
  infoText: PropTypes.string,

  /**
   * Additional function to pass to the input
   * (e.g., onKeyDown, onClick)
   */
  func: PropTypes.object,

  /**
   * Error message to display
   * (e.g., for form validation)
   */
  error: PropTypes.object,

  /**
   * Indicates whether the input is read-only
   */
  readOnly: PropTypes.bool,

  labelType: PropTypes.string,

  maxLength: PropTypes.number,

  min: PropTypes.string,
  /**
   * PropType for autoComplete
   */
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

export default InputControl;
