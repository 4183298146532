import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as IconCallWaiter } from "assets/icons/waiter/CallWaiterBill.svg";
import EmptyZoneIcon from "assets/icons/qr/Table.svg";
import NoOrderIcon from "assets/icons/other/NoOrder.svg";
import EmptyState from "components/admin/empty-state/EmptyState";
import IconAskBill from "components/elements/icon-ask-bill/IconAskBill";
import { ReactComponent as IconNewChat } from "assets/icons/waiter/chat.svg";
import { ReactComponent as IconNewOrder } from "assets/icons/waiter/newOrder.svg";
import { ReactComponent as IconMap } from "assets/icons/map/map.svg";
import { ReactComponent as IconGuest } from "assets/icons/profile/user.svg";
import { ORDER_ACTION_STATE } from "utils/constants/data/menu-model";
import {
  checkDateEqualCurrentDate,
  concatFirstNameWithLastName,
  findZoneOfTable,
} from "utils/helpers";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import StatsPieChartWithNeedleWrapper from "components/admin/charts/chart-wrappers/stats-pieChartWithNeedle-wrapper/StatsPieChartWithNeedleWrapper";
import { ReactComponent as ChatIcon } from "assets/icons/chat/ChatMessage.svg";
import { ReactComponent as BirthdayHat } from "assets/icons/birthday/hat.svg";
import { ReactComponent as HappyBirthday } from "assets/icons/birthday/happybirthday.svg";
import { QUERY_PARAMS } from "utils/constants/routes";
import AdminMapView from "pages/admin/admin-pages/admin-map/admin-map-view/AdminMapView";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import GuestIcon from "assets/icons/profile/Users.svg";
import useOutsideClick from "utils/hooks/useOutsideClick";
import AdminOrderGuestInfoModal from "pages/admin/admin-pages/admin-order/admin-order-map/admin-order-guest-info-modal/AdminOrderGuestInfoModal";
import { sortActiveGuest } from "utils/helper-functions/dashboard/activeGuestHelper";

import "./AdminOrderMap.scss";

const ADMIN_ORDER_VIEW_MODE = {
  standard: "STANDARD",
  map: "MAP",
};

const ADMIN_ORDER_VIEW_MODE_WITH_GUESTS = {
  standard: "STANDARD",
  guests: "Guests",
};

const AdminOrderMap = ({ onClickTable }) => {
  const [viewMode, setViewMode] = useState(ADMIN_ORDER_VIEW_MODE.standard);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedAction, setSelectedAction] = useState(null);
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const { orders } = useSelector((state) => state[STORE_NAMES.orders]);
  const chat = useSelector((state) => state[STORE_NAMES.chat].topics);
  const user = useSelector((state) => state[STORE_NAMES.user].user);
  const [guestId, setGuestId] = useState(null);
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const isTableFull = (tableID) => {
    return orders.find((order) => order.table.id === tableID);
  };
  const [orderViewWithGuests, setOrderViewWithGuests] = useState(
    ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.standard
  );
  let initialZone = {
    name: t("buttons.fullTables"),
    id: 0,
  };
  const [
    openSlideGuestInfoModal,
    setOpenSlideGuestInfoModal,
    mainElementRefGuestInfoModal,
  ] = useOutsideClick();
  const [activeZone, setActiveZone] = useState(initialZone);
  const selectFullTables = () => {
    const allTables = [];
    zones.forEach((zone) => {
      zone.tables.forEach((table) => {
        isTableFull(table.id) && allTables.push(table);
      });
    });
    return {
      ...initialZone,
      tables: allTables,
    };
  };
  const allGuests = orders.flatMap((order) => order.guests);

  const activeZoneTables =
    zones?.find((zone) => {
      return zone.id === activeZone?.id;
    }) || selectFullTables();

  const activeTables = activeZoneTables.tables
    .filter((table) => {
      if (selectedAction) {
        return orders.some(
          (order) => order.table.id === table.id && order[selectedAction.id]
        );
      }
      return true;
    })
    .map((el) => {
      const selectedOrder = orders.find((o) => o.table.id === el.id);
      const currentTopic = chat?.find(
        (topic) => topic.id === selectedOrder?.id
      );
      const lastMessage =
        currentTopic?.messages[currentTopic.messages.length - 1];
      const lastReadTime = currentTopic?.usersLastReadTime.find(
        (item) => item.userId === user.id
      )?.dateTime;
      let hasNewMessage;
      if (lastMessage?.author?.id === user.id) {
        hasNewMessage = false;
      } else if (lastMessage?.dateTime && lastReadTime) {
        hasNewMessage = lastReadTime < lastMessage?.dateTime;
      } else if (lastMessage?.dateTime && !lastReadTime) {
        hasNewMessage = true;
      }
      const hasNewOrder = selectedOrder?.hasNewOrder;
      const askForBill = selectedOrder?.actionBill;
      const callWaiter = selectedOrder?.actionWaiter;
      return { ...el, askForBill, callWaiter, hasNewMessage, hasNewOrder };
    });

  useEffect(() => {
    if (
      activeZone?.id === initialZone?.id &&
      viewMode === ADMIN_ORDER_VIEW_MODE.standard
    ) {
      setActiveZone({
        ...initialZone,
        name: `${t("buttons.fullTables")} (${activeZoneTables.tables.length})`,
        tables: activeZoneTables,
      });
    }
  }, [activeZone?.id, activeZoneTables?.tables?.length, t, viewMode]);

  const handleOnFilterOrdersByAction = (action) => {
    setSelectedAction((prevSelectedAction) =>
      prevSelectedAction?.id === action.id ? null : action
    );
  };
  const handleZoneChange = (zone) => {
    zone && setActiveZone(zone);
  };

  const getOrdersLengthByAction = (name) => {
    const filteredOrders =
      orders
        ?.filter((order) =>
          activeZoneTables?.tables?.some((table) => table.id === order.table.id)
        )
        .filter((order) => order[name]) || [];
    return filteredOrders.length;
  };
  const toggleViewMode = () => {
    switch (viewMode) {
      case ADMIN_ORDER_VIEW_MODE.standard:
        if (activeZone?.id === initialZone.id) {
          setActiveZone(zones[0]);
        }
        setViewMode(ADMIN_ORDER_VIEW_MODE.map);
        break;
      case ADMIN_ORDER_VIEW_MODE.map:
        setViewMode(ADMIN_ORDER_VIEW_MODE.standard);
        break;
    }
  };

  const orderFilterOptions = useMemo(() => {
    return [
      {
        id: ORDER_ACTION_STATE.actionBill,
        name: t("waiter.bill"),
        isEnabled: business?.isAskForBillEnabled,
      },
      {
        id: ORDER_ACTION_STATE.actionWaiter,
        name: t("waiter.call"),
        isEnabled: business?.isCallWaiterEnabled,
      },
    ].filter((orderOption) => orderOption.isEnabled);
  }, [business?.isAskForBillEnabled, business?.isCallWaiterEnabled, t]);

  const askForBillCount = getOrdersLengthByAction(
    ORDER_ACTION_STATE.actionBill
  );
  const callWaiterCount = getOrdersLengthByAction(
    ORDER_ACTION_STATE.actionWaiter
  );

  const allTablesLength = zones.flatMap((zone) => zone.tables).length;

  const allTablesOfActiveZone =
    activeZone?.id === initialZone.id
      ? allTablesLength
      : activeZoneTables.tables.length;

  const fullTablesOfActiveZone = activeZoneTables.tables.filter(({ id }) =>
    isTableFull(id)
  );

  const fullTablesIdByActiveZone = fullTablesOfActiveZone.flatMap(
    (table) => table.id
  );
  const totalGuestsOfOrdersByActiveZone = orders
    .filter((order) => fullTablesIdByActiveZone.includes(order.table.id))
    .reduce((acc, order) => {
      return acc + (order?.guests?.length || 0);
    }, 0);

  const messageCountOfOrdersByActiveZone = activeTables.filter(
    (table) => table.hasNewMessage
  ).length;

  const showOrderWithGuests = () => {
    switch (orderViewWithGuests) {
      case ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.standard:
        setOrderViewWithGuests(ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests);
        break;
      case ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests:
        setOrderViewWithGuests(ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.standard);
    }
  };

  const allGuestsWithDetails = allGuests.map((guest) => {
    const order = orders.find((order) =>
      order.guests.some((g) => g.id === guest.id)
    );
    if (order) {
      const table = order.table;
      const zone = zones.find((zone) =>
        zone.tables.some((t) => t.id === table.id)
      );
      return {
        ...guest,
        tableName: table.name,
        zoneName: zone ? zone.name : "",
      };
    }
    return guest;
  });

  const handleCloseModal = () => {
    setOpenSlideGuestInfoModal(false);
  };
  const handleOpenModal = (id) => {
    setOpenSlideGuestInfoModal(true);
    setGuestId(id);
  };
  return (
    <div className="AdminOrderMapSection">
      <div className="AdminOrderMapTitle">
        <div className="AdminOrderMapTitleInfoAndActionButtonsWrapper">
          <div className="AdminOrderMapTitleInfo">
            <div className="AdminOrderFullTables">
              <Dropdown
                onChange={handleZoneChange}
                name="zones"
                value={activeZone}
                options={
                  viewMode === ADMIN_ORDER_VIEW_MODE.standard
                    ? [initialZone, ...zones]
                    : zones
                }
              />
            </div>
          </div>
          <div className="AdminOrderMapTitleActionButtons">
            <div className="AdminOrderAskCall">
              {orderFilterOptions.map((option) => {
                {
                  return (
                    <div
                      key={option.id}
                      className={`AdminOrderOption ${
                        selectedAction?.id === option.id ? "isActive" : ""
                      }`}
                      onClick={() => handleOnFilterOrdersByAction(option)}
                    >
                      {option.id === ORDER_ACTION_STATE.actionBill && (
                        <IconAskBill />
                      )}
                      {option.id === ORDER_ACTION_STATE.actionWaiter && (
                        <IconCallWaiter />
                      )}
                      {/*<h6 className="h7 Medium">{option.name}</h6>*/}
                      {askForBillCount > 0 &&
                        option.id === ORDER_ACTION_STATE.actionBill &&
                        selectedAction?.id !== option.id && (
                          <div className="AdminOrderButtonNotifications">
                            <h5 className="SemiBold">{askForBillCount}</h5>
                          </div>
                        )}
                      {callWaiterCount > 0 &&
                        option.id === ORDER_ACTION_STATE.actionWaiter &&
                        selectedAction?.id !== option.id && (
                          <div className="AdminOrderButtonNotifications">
                            <h5 className="SemiBold">{callWaiterCount}</h5>
                          </div>
                        )}
                    </div>
                  );
                }
              })}

              {business?.chat && (
                <div
                  className="AdminOrderChatButton"
                  onClick={() => {
                    setSearchParams({
                      ...searchParams,
                      [QUERY_PARAMS.showDetailedChat]: true,
                    });
                  }}
                >
                  <ChatIcon />
                  <h6 className="h7 Medium">{t("chat.chat")}</h6>
                  {messageCountOfOrdersByActiveZone > 0 && (
                    <div className="AdminOrderButtonNotifications">
                      <h5 className="SemiBold">
                        {messageCountOfOrdersByActiveZone}
                      </h5>
                    </div>
                  )}
                </div>
              )}
              <div
                onClick={toggleViewMode}
                className={`AdminOrderOption ${
                  viewMode === ADMIN_ORDER_VIEW_MODE.map ? "isActive" : ""
                }`}
              >
                <IconMap />
                <h6 className="h7 Medium">{t("navbarRoutes.map")}</h6>
              </div>
              <div
                onClick={showOrderWithGuests}
                className={`AdminOrderOption ${
                  orderViewWithGuests ===
                  ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests
                    ? "isActive"
                    : ""
                }`}
              >
                <IconGuest />
                <h6 className="h7 Medium">{t("navbarRoutes.guests")}</h6>
              </div>
            </div>
          </div>
        </div>

        <div>
          <StatsPieChartWithNeedleWrapper
            totalGuestsOfOrdersByActiveZone={totalGuestsOfOrdersByActiveZone}
            fullTablesOfActiveZone={fullTablesOfActiveZone}
            allTablesOfActiveZone={allTablesOfActiveZone}
          />
        </div>
      </div>

      <div className="AdminOrderMapSections">
        {viewMode === ADMIN_ORDER_VIEW_MODE.standard && (
          <div className="AdminOrderMapSectionTables">
            {activeTables.map((table) => {
              if (isTableFull(table.id)) {
                return (
                  <div
                    key={table.id}
                    className={`AdminOrderMapSectionTableItem hasOrder${
                      (table.askForBill && business.isAskForBillEnabled) ||
                      (table.callWaiter && business.isCallWaiterEnabled) ||
                      (table.hasNewMessage && business.chat) ||
                      table.hasNewOrder
                        ? " newNotification"
                        : ""
                    }`}
                    onClick={() => onClickTable(table)}
                  >
                    <div>
                      <h3 className="TableText Bold AdminOrderMapSectionTableItemTitle">
                        {table.name}
                      </h3>
                      {activeZone?.id === initialZone?.id && (
                        <h5 className="ZoneText LetterSpacingS Medium">
                          {`${findZoneOfTable(zones, table.id).name}`}
                        </h5>
                      )}
                    </div>
                    <div
                      className={"AdminOrderMapSectionTableItemActionsWrapper"}
                    >
                      <div className="AdminOrderMapSectionTableItemActions">
                        {business.isAskForBillEnabled && (
                          <IconAskBill isActive={table.askForBill} />
                        )}

                        {business?.chat && (
                          <IconNewChat
                            className={`${table.hasNewMessage && "isActive"}`}
                          />
                        )}
                      </div>
                      <div className="AdminOrderMapSectionTableItemActions">
                        {business.isCallWaiterEnabled && (
                          <IconCallWaiter
                            className={`${table.callWaiter && "isActive"}`}
                          />
                        )}
                        <IconNewOrder
                          className={`${table.hasNewOrder && "isActive"}`}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            {activeTables.map((table) => {
              if (!isTableFull(table.id)) {
                return (
                  <div
                    key={table.id}
                    className={`AdminOrderMapSectionTableItem`}
                    onClick={() => onClickTable(table)}
                  >
                    <h3 className="Bold AdminOrderMapSectionTableItemTitle">
                      {table.name}
                      {!activeZone &&
                        ` / ${findZoneOfTable(zones, table.id).name}`}
                    </h3>
                  </div>
                );
              }
            })}
            {allTablesOfActiveZone === 0 && (
              <EmptyState
                icon={EmptyZoneIcon}
                description={t("emptyTable.emptyZoneDescription")}
                isAdmin={true}
              />
            )}
            {allTablesOfActiveZone !== 0 && activeTables.length === 0 && (
              <EmptyState
                icon={NoOrderIcon}
                description={t("emptyStates.noOrders")}
                isAdmin={true}
              />
            )}
          </div>
        )}
        {viewMode === ADMIN_ORDER_VIEW_MODE.map && (
          <AdminMapView
            onClickTable={onClickTable}
            activeTablesId={activeTables.flatMap((table) => table.id)}
            selectedZone={activeZone}
          />
        )}

        {orderViewWithGuests === ADMIN_ORDER_VIEW_MODE_WITH_GUESTS.guests && (
          <div className="AdminOrderMapSectionGuests">
            <h6 className="h7 SemiBold">{`${t("orders.guests")} (${
              allGuestsWithDetails.length
            })`}</h6>
            {allGuestsWithDetails.length > 0 ? (
              <div className="AdminOrderMapSectionGuestsContainer">
                {sortActiveGuest(allGuestsWithDetails)?.map((guest, index) => {
                  const isBirthday = checkDateEqualCurrentDate(
                    guest.dateOfBirth
                  );
                  return (
                    <div className="AdminOrderMapSectionGuest" key={index}>
                      <div
                        key={index}
                        className="AdminOrderMapSectionGuestInfo"
                        onClick={() => handleOpenModal(guest.person.id)}
                      >
                        <div className="GuestInfoProfilePicContainer">
                          {isBirthday && (
                            <BirthdayHat className="BirthdayHat" />
                          )}
                          <ImageWithPlaceholder
                            imageSource={createDOBucketName(guest?.profilePic)}
                            placeholder={IMG_GUEST}
                            alt="logo"
                          />
                        </div>
                        <div>
                          <h6 className="h7 SemiBold">
                            {concatFirstNameWithLastName({
                              firstName: guest?.firstName,
                              lastName: guest?.lastName,
                            }) ||
                              `${t("dashboard.guest.guest")} ${
                                guest?.person.id
                              }`}
                          </h6>
                          <span>{`${guest?.tableName} (${guest?.zoneName})`}</span>
                        </div>
                      </div>
                      {isBirthday && (
                        <div className="HappyBirthdayContainer">
                          <HappyBirthday className="HappyBirthday" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <EmptyState
                icon={GuestIcon}
                description={t("emptyStates.noGuests")}
                isAdmin={true}
              />
            )}
          </div>
        )}
      </div>
      {guestId && (
        <AdminOrderGuestInfoModal
          openSlide={openSlideGuestInfoModal}
          mainElementRef={mainElementRefGuestInfoModal}
          handleCloseModal={handleCloseModal}
          guestId={guestId}
        />
      )}
    </div>
  );
};
AdminOrderMap.propTypes = {
  /**
   * Function to handle click table
   */
  onClickTable: PropTypes.func,
  /**
   * Array for newMessage
   */
  newMessage: PropTypes.array,
};

export default AdminOrderMap;
