import React, { createContext, useContext, useState, useCallback } from "react";
import PropTypes from "prop-types";

const TowerGameContext = createContext();

export const TowerGameProvider = ({ children }) => {
  const [phase, setPhase] = useState("start");
  const [score, setScore] = useState(0);

  const addScore = useCallback(() => {
    setScore((prevScore) => prevScore + 1);
  }, []);

  const resetScore = useCallback(() => {
    setScore(0);
  }, []);

  const stop = useCallback(() => {
    setPhase((prevPhase) => (prevPhase === "start" ? "stop" : prevPhase));
  }, []);

  const reset = useCallback(() => {
    setPhase((prevPhase) => (prevPhase === "stop" ? "start" : prevPhase));
  }, []);

  return (
    <TowerGameContext.Provider
      value={{ phase, score, addScore, resetScore, stop, reset, setPhase }}
    >
      {children}
    </TowerGameContext.Provider>
  );
};

TowerGameProvider.propTypes = {
  children: PropTypes.node,
};

export const useTowerGameContext = () => {
  const context = useContext(TowerGameContext);

  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
