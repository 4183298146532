import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";

import GuestModal, {
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { ReactComponent as ExclamationIcon } from "assets/icons/other/Exclamation.svg";
import { ReactComponent as ICON_WARNING } from "assets/icons/other/TriagleIcon.svg";
import Checkbox from "components/admin/forms/checkbox/Checkbox";
import { findMenuItemByIdAndPublished } from "utils/helpers";
import {
  calculateMenuItemPriceBySchedule,
  calculateModificationsPrice,
} from "utils/general";
import useLanguage from "utils/hooks/useLanguage";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";

import "./OrderConfirm.scss";

export const ENUMS = {
  name: "OrderConfirm",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
  },
};

const OrderConfirm = ({
  orderItems,
  title,
  checkboxText,
  guestId,
  menuCategories,
  type = ENUMS.types.TYPE_A,
  mainElementRefConfirm,
  openSlide,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const { displayDataByLanguage } = useLanguage();
  const [itemsInBasket, setItemsInBasket] = useState([]);

  useEffect(() => {
    if (orderItems && orderItems.length > 0) {
      const fetchedMenuItems = orderItems.map((item) => {
        const menuItem = findMenuItemByIdAndPublished(
          menuCategories,
          item.item,
          dispatch,
          guestId
        );

        return {
          menuItem,
          count: item.count,
        };
      });

      setItemsInBasket(fetchedMenuItems);
    }
  }, [orderItems, menuCategories, dispatch, guestId]);

  const OrderConfirmHeader = (
    <div className="OrderConfirmHeader">
      <div className="CloseButtonContainer" onClick={onCancel}>
        <IconClose />
      </div>
      <div className="GuestModalBodyIconWrapper">
        <div className="GuestModalBodyIcon">
          <ICON_WARNING className="GuestModalBodyIconTriangle" />
          <ExclamationIcon className="GuestModalBodyExclamationIcon" />
        </div>
      </div>
    </div>
  );
  const OrderConfirmBody = (
    <div className="OrderConfirmModalBody">
      <h4 className="Medium GuestModalBodyContentTitle">{title}</h4>
      <div className="OrderConfirmModalBodyContentDetails">
        {itemsInBasket &&
          itemsInBasket.map((itemInBasket, index) => {
            const discountPrice = calculateMenuItemPriceBySchedule(
              itemInBasket.menuItem
            );
            const menuItemFinalPrice =
              itemInBasket.menuItem.priceSell +
              calculateModificationsPrice(itemInBasket.menuItem.modifications);
            return (
              <div key={index} className="OrderConfirmModalBodyContentDetail">
                <div className="OrderConfirmModalBodyContentDetailContent">
                  <p className="h7 Medium OrderConfirmModalBodyContentDetailContentTitle">
                    {itemInBasket.count}x{" "}
                    {displayDataByLanguage(itemInBasket.menuItem.name)}
                  </p>
                  <div className="OrderConfirmModalBodyContentDetailContentTotalPriceWrapper">
                    <div className="h7 Medium OrderConfirmModalBodyContentTotalPrice">
                      {discountPrice !== null && (
                        <Price
                          type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                          value={menuItemFinalPrice * itemInBasket.count}
                        />
                      )}
                    </div>
                    <div className="h7 Medium OrderConfirmModalBodyContentTotalPrice">
                      <Price
                        type={ENUMS_PRICE.types.PRICE_XS}
                        value={
                          discountPrice !== null
                            ? itemInBasket.count * menuItemFinalPrice +
                              discountPrice * itemInBasket.count
                            : menuItemFinalPrice * itemInBasket.count
                        }
                      />
                    </div>
                  </div>
                </div>
                {itemInBasket.menuItem.modifications &&
                itemInBasket.menuItem.modifications.length > 0 ? (
                  <div className="OrderConfirmModalBodyContentDetailContentModifications">
                    {itemInBasket.menuItem.modifications.map(
                      (modification, index) => (
                        <div
                          className="OrderConfirmModalBodyContentDetailContentModificationWrapper"
                          key={index}
                        >
                          <p className="h8 Regular OrderConfirmModalBodyContentDetailContentModification">
                            {displayDataByLanguage(modification.name)}:
                          </p>
                          <div className="h8 Regular OrderConfirmModalBodyContentDetailContentModification">
                            {modification.options
                              .map((option, i) => (
                                <React.Fragment key={i}>
                                  {`${option.count}x ${displayDataByLanguage(
                                    option.name
                                  )}`}
                                  <Price
                                    type={ENUMS_PRICE.types.PRICE_XS}
                                    value={option.count * option.priceSell}
                                  />
                                </React.Fragment>
                              ))
                              .reduce((prev, curr) => [prev, ", ", curr])}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : null}
              </div>
            );
          })}
      </div>
    </div>
  );

  const OrderConfirmFooter = (
    <div className="OrderConfirmModalFooter">
      <div
        className="OrderConfirmModalFooterCheckbox"
        onClick={() => setIsChecked(!isChecked)}
      >
        <Checkbox isChecked={isChecked} type={ENUMS.types.TYPE_B} />
        <h6 className="Regular OrderConfirmModalFooterCheckboxTitle">
          {checkboxText}
        </h6>
      </div>

      <CTAButton
        onClick={onConfirm}
        className={cx({
          GuestModalFooterButton: isChecked,
          DisabledGuestModalFooterButton: !isChecked,
        })}
        name={t("buttons.continue")}
        type={CTA_ENUMS.types.TYPE_B}
        disabled={!isChecked}
      />
    </div>
  );

  return (
    <div
      className={cx("OrderConfirm", {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B || type === ENUMS.types.TYPE_C,
      })}
    >
      <GuestModal
        modalClassname="ModalClassname"
        header={OrderConfirmHeader}
        body={OrderConfirmBody}
        footer={OrderConfirmFooter}
        mainElementRef={mainElementRefConfirm}
        openSlide={openSlide}
        slideDirection={POSITION_TYPES.DOWN}
      />
    </div>
  );
};

OrderConfirm.propTypes = {
  /**
   * The items in the order
   */
  orderItems: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.object.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,

  /**
   * The guest ID associated with the order
   */
  guestId: PropTypes.number.isRequired,

  title: PropTypes.string.isRequired,

  checkboxText: PropTypes.string.isRequired,

  /**
   * The categories of the menu
   */
  menuCategories: PropTypes.array.isRequired,

  /**
   * The type of the component (A, B, or C)
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * The ref for the modal
   */
  mainElementRefConfirm: PropTypes.object,

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool.isRequired,

  /**
   * Function called when the confirm action is performed
   */
  onConfirm: PropTypes.func.isRequired,

  /**
   * Function called when the cancel action is performed
   */
  onCancel: PropTypes.func.isRequired,
};

export default OrderConfirm;
